<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title">Create Project</h5>
    <button type="button" class="close" aria-label="Close" data-dismiss="modal" (click)="onCancel()">
      <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <form [formGroup]="ClaimBatchCreateProjectGroup">
          <div class="row">
              <div class="col-md-6">                    
                  <label class="form-label">Claim Count : {{ClaimCount}}</label> 
              </div>
              <div class="col-md-6">                    
                  <label class="form-label">Total Balance : {{Balance | currency: "USD":true:"2.0"}}</label>
              </div>
              <div class="col-md-6">
                  <div class="form-group">
                      <label class="form-label">Project Name</label>
                      <kendo-textbox
                          [disabled]="disabled"
                          formControlName="fcProjectName"
                          [(ngModel)]="ProjectNamedisplay"
                          type="text"
                          id="ProjectName"
                          class="form-control form-control-sm flex-fill"
                          title="Enter Project Name"
                          placeholder="Enter Project Name"                
                      ></kendo-textbox>  
                      <div *ngIf="submitted || (ProjectName.invalid && ProjectName.touched)">
                          <small *ngIf="ProjectName.errors?.required" class="text-danger">Project Name is required</small>                            
                      </div>
                  </div>
              </div>
              <div class="col-md-6">
                  <label class="form-label">Expected Completion Date</label>
                  <kendo-datepicker class="w-100" [(value)]="EnddateValue" formControlName="fcExpectedCompletionDate" [min]="min">
                  </kendo-datepicker>
                  <div *ngIf="submitted || (ExpectedCompletionDate.touched && ExpectedCompletionDate.invalid)">
                      <small *ngIf="ExpectedCompletionDate.errors?.required" class="text-danger">
                          Expected Completion Date is required</small>
                  </div>
              </div> 
              <div class="col-md-6">
                  <!-- [disabled] = "disableStatus" -->
                  <div class="form-group">
                      <!-- {{Selected_Status|json}} -->
                      <label class="form-label">Status</label>                        
                      <kendo-dropdownlist
                        [disabled] = "disableStatus"
                        formControlName="fcStatus"
                        class="w-100"
                        id="ddStatus"
                        [(ngModel)]="Selected_Status"
                        [textField]="'text'"
                        [valueField]="'value'"
                        [data]="Status_List"
                        [valuePrimitive]="true"
                      ></kendo-dropdownlist> 
                  </div>
              </div>                              
              <div class="col-md-6">
                
                  <div class="form-group">
                      <label class="form-label">Priority</label>
                      <kendo-dropdownlist
                        formControlName="fcPriority"
                        class="w-100"
                        id="ddPrority"
                        [(ngModel)]="Selected_Priority"
                        [textField]="'text'"
                        [valueField]="'value'"
                        [data]="Priority_List"
                        [valuePrimitive]="true"
                      ></kendo-dropdownlist>
                  </div>
              </div>
              <div class="col-md-6">
                  <div class="form-group">
                      <label class="form-label">Owner</label>
                      
                    <kendo-combobox 
                    formControlName="fcTo"
                    [data]="Userlist" 
                    [textField]="'displayname'" 
                    [valueField]="'email'" 
                    [(ngModel)]="SelectedSendToUser"
                    (valueChange)="SelectSendToUserEmailSingle($event)" 
                    [placeholder]="'Search Owner'" class="w-100">
                    </kendo-combobox>

                    
                    <div *ngIf="submitted || (AssignedTo.invalid && AssignedTo.touched)" class="text-danger">
                      <small *ngIf="AssignedTo.errors?.required">Owner is required</small>
                    </div>
                  </div>
              </div>
              <div class="col-md-6" [hidden]="true">
                  <div class="form-group">
                      <label class="form-label" [hidden]="true">Send To</label>
                      <input
                          [hidden]="true"
                          formControlName="fcSendTo"
                          type="text"
                          class="w-100"
                          [(ngModel)]="SelectedSendToUserEmailtxt"
                          kendoTextBox
                      />
                  </div>
              </div>
              <div class="col-md-6">
                  <div class="form-group">
                      <label class="form-label">Follower</label>
                      <kendo-multiselect
                      formControlName="fcCC"
                      [data]="Userlist"                       
                      [placeholder]="'Select Follower'"
                      [textField]="'displayname'"
                      [valueField]="'email'"
                      [(ngModel)]="SelectedCCUser"
                      (valueChange)="SelectCCUserEmail($event)"
                    >
                    </kendo-multiselect>
                   
                  </div>
              </div>
              <div class="col-md-6" [hidden]="true">
                  <div class="form-group">
                      <label class="form-label" [hidden]="true">CC</label>
                      <input
                          [hidden]="true"
                          formControlName="fcCCSendTo"
                          type="text"
                          class="w-100"
                          [(ngModel)]="SelectedCCUserEmailtxt"
                          kendoTextBox
                      />
                  </div>
              </div> 
                                       
              <div class="col-md-12">                    
                <div class="form-group">
                  <label class="form-label">Description</label>
                  <textarea kendoTextArea [(ngModel)]="Descriptiontext" formControlName="fcDescription" id="Description" class="w-100"></textarea>
                </div>
              </div>  
          </div>
      </form>        
  </div>
  <div class="modal-footer py-2">      
      <button type="button" class="btn btn-success btn-sm mr-2" *ngIf="bIsshowEditButton"  (click)="onEdit()">Update</button>        
      <button type="button" class="btn btn-success btn-sm mr-2" *ngIf="!bIsshowEditButton" (click)="onSave()">Save</button>
      <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal" (click)="onCancel()" >Cancel</button>
      <button type="button" id = 'hidemodal' class="btn btn-secondary d-none" data-dismiss="modal"  (click)="onCloseModal()" >CloseModal</button>
  </div>
</div>

<!-- <div class="black-box" *ngIf="bIsLoader">
  <div class="k-i-loading"></div>
</div> -->

<div class="loader-block" *ngIf="bIsLoader">
  <div class="loader-content text-center">
      <div class="loader-data">
          <img src="assets/img/Q-progressbar-blue.gif" alt="" class="mb-4" width="50" />
          <p>{{ LoaderLabel }}</p>
      </div>
  </div>
</div>