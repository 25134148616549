import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Utility } from 'src/app/ModelFinancialMetrics/CommonService/utility';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.css']
})
export class DropdownComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() Text: any;
  @Input() listItems: any;
  @Input() check: any;
  @Output() ModelChange = new EventEmitter<any>();
  @Output() apptTypeSelect = new EventEmitter<any>();
  @Output() apptTypeDeSelect = new EventEmitter<any>();
  @Output() apptTypeSelectAll = new EventEmitter<any>();
  @Output() apptTypeDeSelectAll = new EventEmitter<any>();

  common = {};
  listItemsData: any;
  textName: any;

  selectedapptTypes_Display: any = [];
  public SelectedapptTypes_Badges: any = "";
  public apptTypeSelectedValues: any[] = [];

  private clsUtility: Utility;


  constructor(private toaster: ToastrService,) {
    this.clsUtility = new Utility(toaster);
  }

  ngOnInit(): void {
    this.multidropsettings();
  }

  ngOnChanges() {
    this.apptTypeSelectedValues = [];

    this.textName = this.Text;
    this.listItemsData = this.listItems;
  }

  ngAfterViewInit() {
  }

  multidropsettings() {
    this.common = {
      singleSelection: false,
      text: "Select Data",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      enableSearchFilter: true,
      classes: "myclass custom-class-example",
      noDataLabel: "No DATA is active",
      position: "bottom",
      lazyLoading: true,
      badgeShowLimit: 2,
      disabled: false,
    };
  }

  ngmodel(event: any, check: any) {
    // debugger
    this.ModelChange.emit({ event, check });
  }

  OnapptTypeSelect(event: any, check: any) {
    // debugger
    this.apptTypeSelect.emit({ event, check });
  }

  OnapptTypeDeSelect(event: any, check: any) {
    // debugger
    this.apptTypeDeSelect.emit({ event, check });
  }

  OnapptTypeSelectAll(event: any, check: any) {
    // debugger
    this.apptTypeSelectAll.emit({ event, check });
  }

  OnapptTypeDeSelectAll(event: any, check: any) {
    // debugger
    this.apptTypeDeSelectAll.emit({ event, check });
  }

}

