import { CallserviceService } from "../CallServices/callservice.service";
import { Component, OnInit } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { AuditLogService } from "../CallServices/audit-log.service";
import { Router, NavigationEnd, RoutesRecognized, } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { CommonService } from "src/app/services/common.service";
import { AuthService } from "../AuthGuard/auth.service";
import { environment } from "./../../environments/environment";
import { Permission, ServiceConfigPermission } from "../CallServices/Permission";
import { GoogleAnGoogleAnalyticsServiceService } from "../CallServices/google-an-google-analytics-service.service";
import { filter, pairwise } from "rxjs/operators";
import { Http, } from "@angular/http";
import { TokenEncryptionService } from "../CallServices/token-encryption.service";
import { FilterDataService } from "../ModelFinancialMetrics/CommonService/filter-data.service";
import { FirebaseService } from "../CallServices/firebase.service";
import "rxjs/add/operator/share";
import "rxjs/add/operator/map";

declare var ga: Function;
@Component({
  selector: "app-mqlogin",
  templateUrl: "./mqlogin.component.html",
  styleUrls: ["./mqlogin.component.css"],
})
export class MqloginComponent implements OnInit {
  practiceLengh: any;
  roles: any;
  pathwaysDataset: string;
  exception: boolean;
  Warning: string;
  LoginRes: any;
  BanqMainMenu: boolean = false;
  valueMainMenu: boolean;
  MIUPracticeList: any;
  maintenanceMode: any;
  allgroupsavailable: boolean = false;
  intervalId: any;

  Practiceparam: { environmenttype: string; currentdataset: string } = { environmenttype: "", currentdataset: "", };
  loadsidebar: any;
  token: any;
  UID: any;
  AID: any;
  groupArray = new Array();
  grparray = new Array();
  tinarray = new Array();
  ccnarray = new Array();
  AllDivisionlist = new Array();
  BnanQclientlist = new Array();
  GroupList = new Array();
  practicelist = new Array();
  loginLoader: any;
  clientlistparam: { bankEnv: string } = { bankEnv: "" };
  divisionlistparam: { bankEnv: string; userid: string; userType: string } = { bankEnv: "", userid: "", userType: "", };
  User_type: any;
  fullname: any;
  AssigneGroupcode = new Array();
  AssigneGroupId = new Array();
  bankEnvkey: any;
  georesult: any;
  npi: any;
  UserIPDetails: { userid: string; visitorip: string; visitorbrowser: string; continent: string; country: string; countryCode: string; city: string; region: string; userFullName: string; loginuser: string; } = { userid: "", visitorip: "", visitorbrowser: "", continent: "", country: "", countryCode: "", city: "", region: "", userFullName: "", loginuser: "", };
  ParentGrpList = new Array();
  MIUParentGrpList = new Array();
  ParentPracticelist = new Array();
  currDataset: any;
  listwithAllPractice = new Array();
  BQpracticelist: any;
  miulist: any;
  UserLastSelection = new Array();
  currentENV: any;

  constructor(public comservice: CommonService, private toastr: ToastrService, public _Router: Router, private cookieService: CookieService, private api: CallserviceService, private tokenEncryptDecrypt: TokenEncryptionService, private authS: AuthService, public permission: Permission, public http: Http, private filterdata: FilterDataService, public googleAnalyticsService: GoogleAnGoogleAnalyticsServiceService, public logapi: AuditLogService, public serviceConfigPermission: ServiceConfigPermission, private firebaseService: FirebaseService,) {
    this._Router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        ga("set", "page", event.urlAfterRedirects);
        ga("send", "pageview");
      }
    });

    this._Router.events.pipe(
      filter((evt: any) => evt instanceof RoutesRecognized),
      pairwise()
    );
  }

  ngOnInit() {
    if (sessionStorage.getItem('issidebar')) {
      sessionStorage.removeItem('issidebar');
    }

    this.UserLastSelection = [];
    this.loginLoader = true;

    if (this.cookieService.check("AID") && this.tokenEncryptDecrypt.decryptToken(this.cookieService.get("AID")) != undefined) {
      if (environment.currentEnvironment == "Production") {
        this.currDataset = "QinsightProductionData";
        this.pathwaysDataset = "qinsights.Qpathways";
      } else if (environment.currentEnvironment == "Development") {
        this.currDataset = "QinsightDEVData";
        this.pathwaysDataset = "Dev_feature";
      } else if (environment.currentEnvironment == "QA") {
        this.currDataset = "QinsightQAData";
        this.pathwaysDataset = "QpathwaysQA";
      } else if (environment.currentEnvironment == "Staging") {
        this.currDataset = "QinsightStagingData";
        this.pathwaysDataset = "QpathwaysStaging";
      } else if (environment.currentEnvironment == "Local") {
        this.currDataset = "QinsightDEVData";
        this.pathwaysDataset = "Dev_feature";
      }

      sessionStorage.setItem('currentDataset', this.tokenEncryptDecrypt.encryptToken(this.currDataset));
      sessionStorage.setItem('pathwaysDataset', this.tokenEncryptDecrypt.encryptToken(this.pathwaysDataset));

      if (environment.currentEnvironment == "Local") {
        sessionStorage.setItem('currentEnvironment', this.tokenEncryptDecrypt.encryptToken("Development"));
        this.currentENV = "Development";
      } else {
        this.currentENV = environment.currentEnvironment;
        sessionStorage.setItem('currentEnvironment', this.tokenEncryptDecrypt.encryptToken(environment.currentEnvironment));
      }

      this.Practiceparam.environmenttype = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("currentEnvironment")).toString();
      this.Practiceparam.currentdataset = this.currDataset;
      sessionStorage.setItem('UID', this.tokenEncryptDecrypt.encryptToken(this.cookieService.get("UID")));
      sessionStorage.setItem('usertoken', this.cookieService.get("AID"));
      this.token = "Bearer " + this.tokenEncryptDecrypt.decryptToken(this.cookieService.get("AID"))
      sessionStorage.setItem('token', this.tokenEncryptDecrypt.encryptToken(this.token));

      const seq = this.api.MQLogin(this.token).share();
      seq.map((res) => res.json()).subscribe(
        (res) => {
          this.ParentGrpList = [];
          this.MIUParentGrpList = [];
          this.loadsidebar = true;
          this.LoginRes = res;
          this.authS.sendToken("true");
          const userID = res["userid"];
          sessionStorage.setItem('userid', this.tokenEncryptDecrypt.encryptToken(res["userid"]));
          sessionStorage.setItem('username', this.tokenEncryptDecrypt.encryptToken(res["username"]));
          sessionStorage.setItem('sNPI', this.tokenEncryptDecrypt.encryptToken(res["snpi"]));
          sessionStorage.setItem('providerid', this.tokenEncryptDecrypt.encryptToken(res["providerid"]));
          this.fullname = res["firstname"] + " " + res["lastname"];
          sessionStorage.setItem('usertype', this.tokenEncryptDecrypt.encryptToken(res["usertype"]));
          sessionStorage.setItem('fname', this.tokenEncryptDecrypt.encryptToken(this.fullname));
          this.User_type = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("usertype"));

          const metricsdata = this.api.javaget().share();
          metricsdata.map((res) => res.json()).subscribe((res) => {
            const Permissionarray = new Array();
            if (res.length > 0) {
              for (let i = 0; i < res.length; i++) {
                Permissionarray[i] = res[i]["permissiondescription"];
              }
              if (Permissionarray.length != 0) {
                for (let i = 0; i < Permissionarray.length; i++) {
                  if (Permissionarray[i] == "MRI Details") {
                    this.permission.MRIDetails = true;
                  }
                  if (Permissionarray[i] === "Practice Configuration") {
                    this.permission.QAdminPracticeConfiguration = true;
                  }
                  if (Permissionarray[i] === "Model Configuration") {
                    this.permission.ModelConfiguration = true;
                  }
                  if (Permissionarray[i] === "Billing Configuration") {
                    this.permission.QAdminBillingConfiguration = true;
                  }
                  if (Permissionarray[i] === "Practice Expected Value") {
                    this.permission.ExpectedValueConfig = true;
                  }
                  if (Permissionarray[i] === "Provider Configuration") {
                    this.permission.ProviderConfiguration = true;
                  }
                  if (Permissionarray[i] === "Provider Config by CPT, Facility") {
                    this.permission.ProviderConfigurationbyPOS = true;
                  }
                  if (Permissionarray[i] === "Provider Comp by CPT, Facility") {
                    this.permission.ProviderCompensationbyPOS = true;
                  }
                  if (Permissionarray[i] === "Customized Dashboard") {
                    this.permission.CustomizedDashboard = true;
                  }
                  if (Permissionarray[i] === "Day Wise Payer Payment") {
                    this.permission.QAdminDayWisePayerPayment = true;
                  }
                  if (Permissionarray[i] == "HUB Day Wise Payer Payment") {
                    this.permission.DayWisePayerPayment = true;
                  }
                  if (Permissionarray[i] === "Charge vs Payment") {
                    this.permission.ChargesVsPayment = true;
                  }
                  if (Permissionarray[i] == "Financial Cash Flow") {
                    this.permission.RCMWaterfall = true;
                  }
                  if (Permissionarray[i] == "Denial Analysis") {
                    this.permission.DenialAnalysis = true;
                  }
                  if (Permissionarray[i] == "Denial History") {
                    this.permission.DenialsHistory = true;
                  }
                  if (Permissionarray[i] == "Denial Management") {
                    this.permission.DenialManagement = true;
                  }
                  if (Permissionarray[i] == "Denial Configuration") {
                    this.permission.DenialConfig = true;
                  }
                  if (Permissionarray[i] == "Denial Configuration") {
                    this.permission.AddDenialConfig = true;
                  }
                  if (Permissionarray[i] == "User Configuration") {
                    this.permission.RCMUserConfig = true;
                  }
                  if (Permissionarray[i] == "TFL/DFL Configuration") {
                    this.permission.TFLUserConfig = true;
                  }
                  if (Permissionarray[i] == "Automail Configuration") {
                    this.permission.automailconfig = true;
                  }
                  if (Permissionarray[i] == "Missing Charges") {
                    this.permission.MissingCharges = true;
                  }
                  if (Permissionarray[i] == "Monthly Charges") {
                    this.permission.MonthlyCharges = true;
                  }
                  if (Permissionarray[i] == "Missed Opportunities") {
                    this.permission.MissedOpportunities = true;
                  }
                  if (Permissionarray[i] == "Monthly Close") {
                    this.permission.MonthlyClose = true;
                  }
                  if (Permissionarray[i] == "MTD YTD Report") {
                    this.permission.MtdYtdReport = true;
                  }
                  if (Permissionarray[i] == "Daily Charges") {
                    this.permission.DailyCharges = true;
                  }
                  if (Permissionarray[i] == "Daily Close") {
                    this.permission.DailyClose = true;
                  }
                  if (Permissionarray[i] == "Monthly Payment") {
                    this.permission.MonthlyPayment = true;
                  }
                  if (Permissionarray[i] == "Panel Payer Distribution") {
                    this.permission.PanelPayerDistribution = true;
                  }
                  if (Permissionarray[i] == "Panel Distribution") {
                    this.permission.paneldistribution = true;
                  }
                  if (Permissionarray[i] == "Location Wise Financial Payment") {
                    this.permission.LocationFinancialPayment = true;
                  }
                  if (Permissionarray[i] == "Practice Compensation") {
                    this.permission.PracticeCompensation = true;
                  }
                  if (Permissionarray[i] == "Project Insight") {
                    this.permission.ProjectInsight = true;
                  }
                  if (Permissionarray[i] == "My Projects") {
                    this.permission.MyProjects = true;
                  }
                  if (Permissionarray[i] == "Provider Compensation") {
                    this.permission.ProviderCompensation = true;
                  }
                  if (Permissionarray[i] == "Daily Charge Details") {
                    this.permission.DailyChargeDetails = true;
                  }
                  if (Permissionarray[i] == "Timely Filing") {
                    this.permission.TimelyFiling = true;
                  }
                  if (Permissionarray[i] == "CPT Analysis") {
                    this.permission.CPTAnalysis = true;
                  }
                  if (Permissionarray[i] == "Charge Lag And Batch Lag") {
                    this.permission.chargebatchLag = true;
                  }
                  if (Permissionarray[i] == "Metrics") {
                    this.permission.Metric = true;
                  }
                  if (Permissionarray[i] == "Aged Payments") {
                    this.permission.AgedPayments = true;
                  }
                  if (Permissionarray[i] == "Charge Lag And Exam Finished Lag") {
                    this.permission.ExamFinishLag = true;
                  }
                  if (Permissionarray[i] == "Claim Not Worked Upon") {
                    this.permission.ClaimNotWorkedUpon = true;
                  }
                  if (Permissionarray[i] == "RCM Day Wise Payer Payment") {
                    this.permission.RCMDayWisePayerPayment = true;
                  }
                  if (Permissionarray[i] == "Revenue Cycle") {
                    this.permission.RevenueCycle = true;
                  }
                  if (Permissionarray[i] == "Expected Collection") {
                    this.permission.ExpectedCollection = true;
                  }
                  if (Permissionarray[i] == "CPT-Expected Collection") {
                    this.permission.CPTExpectedCollection = true;
                  }
                  if (Permissionarray[i] == "Post Utilization") {
                    this.permission.PostUtilization = true;
                  }
                  if (Permissionarray[i] == "Reconciliation Utilzation") {
                    this.permission.ReconcilationUtilization = true;
                  }
                  if (Permissionarray[i] == "CPT Allowed vs ASP Pricing") {
                    this.permission.CPTAllowedvsASPPricing = true;
                  }
                  if (Permissionarray[i] == "ERA Client Distribution") {
                    this.permission.ERAClientDistribution = true;
                  }
                  if (Permissionarray[i] == "ERA Daywise Distribution") {
                    this.permission.ERADaywiseDistribution = true;
                  }
                  if (Permissionarray[i] == "ERA Division Distribution") {
                    this.permission.ERADivisionDistribution = true;
                  }
                  if (Permissionarray[i] == "Check Distribution") {
                    this.permission.checkdistribution = true;
                  }
                  if (Permissionarray[i] == "File Distribution") {
                    this.permission.filedistribution = true;
                  }
                  if (Permissionarray[i] == "Honest Medicare Reconciliation") {
                    this.permission.honestreport = true;
                  }
                  if (Permissionarray[i] == "New File Distribution") {
                    this.permission.newfiledistribution = true;
                  }
                  if (Permissionarray[i] == "MHP QSuite-HUB Payment Reconciliation") {
                    this.permission.mhphubreport = true;
                  }
                  if (Permissionarray[i] == "EDI HUB Summary") {
                    this.permission.practicesummary = true;
                  }
                  if (Permissionarray[i] == "Patient Payment") {
                    this.permission.PatientPayment = true;
                  }
                  if (Permissionarray[i] == "Summary") {
                    this.permission.orthoLowerSummary = true;
                  }
                  if (Permissionarray[i] == "Account Receivable") {
                    this.permission.Acountrecivable = true;
                  }
                  if (Permissionarray[i] == "Account Receivable Dash") {
                    this.permission.AcountrecivableinDash = true;
                  }
                  if (Permissionarray[i] == "On Hold Claims") {
                    this.permission.QPMholddashboard = true;
                  }
                  if (Permissionarray[i] == "Key Metrics") {
                    this.permission.keyMetrics = true;
                  }
                  if (Permissionarray[i] == "Home") {
                    this.permission.home = true;
                  }
                  if (Permissionarray[i] == "Charges Analysis") {
                    this.permission.ChargesAnalysis = true;
                  }
                  if (Permissionarray[i] == "Payment Analysis") {
                    this.permission.paymentAnalysis = true;
                  }
                  if (Permissionarray[i] == "Refund Analysis") {
                    this.permission.RefundAnalysis = true;
                  }
                  if (Permissionarray[i] == "Takeback Analysis") {
                    this.permission.TakebackAnalysis = true;
                  }
                  if (Permissionarray[i] == "Daily Tracker") {
                    this.permission.DailyTracker = true;
                  }
                  if (Permissionarray[i] == "Payment Break UP") {
                    this.permission.PaymentBreakUP = true;
                  }
                  if (Permissionarray[i] == "Payment Waterfall") {
                    this.permission.Paymentwaterfall = true;
                  }
                  if (Permissionarray[i] == "Overall AR") {
                    this.permission.OverallARAndbreakup = true;
                  }
                  if (Permissionarray[i] == "AR Aging by Payer Type") {
                    this.permission.InsuranceDebitARAging = true;
                  }
                  if (Permissionarray[i] == "Chemo Non Chemo by Provider") {
                    this.permission.ChemononChemo = true;
                  }
                  if (Permissionarray[i] == "Provider YTD") {
                    this.permission.ProviderYTD = true;
                  }
                  if (Permissionarray[i] == "Rendering Provider YTD") {
                    this.permission.RenderingProviderYTD = true;
                  }
                  if (Permissionarray[i] == "Provider Charges") {
                    this.permission.ProviderCharges = true;
                  }
                  if (Permissionarray[i] == "Provider Payments") {
                    this.permission.ProviderPayments = true;
                  }
                  if (Permissionarray[i] == "Insurance AR Comparision") {
                    this.permission.InsuranceARComparision = true;
                  }
                  if (Permissionarray[i] == "Batch - TRIZETTO") {
                    this.permission.Trizetto = true;
                  }
                  // if (Permissionarray[i] == "Biotech Day Wise Payer Payment") {
                  //   this.permission.BiotechDayWisePayerPayment = true;
                  // }
                  if (Permissionarray[i] == "Rejection Rate") {
                    this.permission.RejectionRate = true;
                  }
                  if (Permissionarray[i] == "Biotech Productivity") {
                    this.permission.Biotechproductivity = true;
                  }
                  if (Permissionarray[i] == "RCM Productivity") {
                    this.permission.allrcmProductivity = true;
                  }
                  if (Permissionarray[i] == "Ins Bal AR") {
                    this.permission.dellclaimexport = true;
                  }
                  if (Permissionarray[i] == "Rcm Analytics") {
                    this.permission.rcmanalytics = true;
                  }
                  if (Permissionarray[i] == "Charge Liquidation") {
                    this.permission.chargeliquidation = true;
                  }
                  if (Permissionarray[i] == "Available Reserves") {
                    this.permission.availablereserve = true;
                  }
                  if (Permissionarray[i] == "Appointment Census Report") {
                    this.permission.appointcensusreport = true;
                  }
                  if (Permissionarray[i] == "ERA Ready To Post") {
                    this.permission.erapaymentposting = true;
                  }
                  if (Permissionarray[i] == "Insurance Pending Checks") {
                    this.permission.Insurancependingchecks = true;
                  }
                  if (Permissionarray[i] == "RCM Productivity Beta") {
                    this.permission.rcmProductivity = true;
                  }
                  if (Permissionarray[i] == "Financial Productivity") {
                    this.permission.Financialproductivity = true;
                  }
                  if (Permissionarray[i] == "Provider Productivity") {
                    this.permission.ProviderProductivity = true;
                  }
                  // if (Permissionarray[i] == "Account Receivable in Financial") {
                  //   this.permission.FinancialAR = true;
                  // }

                  /*       public BatchDistribution:boolean;
                  public BiotechConfiguration:boolean; */
                  if (Permissionarray[i] == "Batch Distribution") {
                    this.permission.BatchDistribution = true;
                  }
                  if (Permissionarray[i] == "Biotech Configuration") {
                    this.permission.BiotechConfiguration = true;
                  }
                  if (Permissionarray[i] == "Incomplete Order") {
                    this.permission.IncompleteOrder = true;
                  }
                  if (Permissionarray[i] == "Insurance follow-up") {
                    this.permission.Insurancefollowup = true;
                  }
                  //---------------------------- value dashboards ------------------
                  if (Permissionarray[i] == "Ortho lower summary") {
                    this.permission.Ortholowersummary = true;
                  }
                  if (Permissionarray[i] == "Ortho lower IP-Risk-Stratified") {
                    this.permission.OrtholowerIPRiskStratified = true;
                  }
                  if (Permissionarray[i] == "Ortho lower Fractures") {
                    this.permission.OrtholowerFractures = true;
                  }
                  if (Permissionarray[i] == "Ortho lower Readmissions") {
                    this.permission.OrtholowerReadmissions = true;
                  }
                  if (Permissionarray[i] == "Ortho upper summary") {
                    this.permission.Orthouppersummary = true;
                  }
                  if (Permissionarray[i] == "Ortho upper IP-Risk-Stratified") {
                    this.permission.OrthoupperIPRiskStratified = true;
                  }
                  if (Permissionarray[i] == "Practice Summary Analytics") {
                    this.permission.practicesummaryanalytics = true;
                  }
                  if (Permissionarray[i] == "Facility Analytics") {
                    this.permission.facilityanalytics = true;
                  }
                  if (Permissionarray[i] == "HHA Analytics") {
                    this.permission.hhaanalytics = true;
                  }
                  if (Permissionarray[i] == "SNF Analytics") {
                    this.permission.snfanalytics = true;
                  }
                  if (Permissionarray[i] == "PT Analytics") {
                    this.permission.ptanalytics = true;
                  }
                  if (Permissionarray[i] == "Order Analytics") {
                    this.permission.orderanalytics = true;
                  }
                  if (Permissionarray[i] == "Alliance Summary Analytics") {
                    this.permission.alliancesummaryanalytics = true;
                  }
                  if (Permissionarray[i] == "Maintenance") {
                    sessionStorage.setItem('maintenance', this.tokenEncryptDecrypt.encryptToken("true"));  //Encryption
                  }

                  /*    if (Permissionarray[i] == "Ortho upper Readmissions") {
                       this.permission.OrthoupperReadmissions = true;
                     } */
                  if (Permissionarray[i] == "Utilization Comparison") {
                    this.permission.UtilizationComparison = true;
                  }
                  if (Permissionarray[i] == "Reconciliation Performance") {
                    this.permission.ReconciliationPerformance = true;
                  }
                  if (Permissionarray[i] == "Actual Reconciliation") {
                    this.permission.ActualReconciliation = true;
                  }

                  if (Permissionarray[i] == "Covid-19") {
                    this.permission.Covid = true;
                  }
                  if (Permissionarray[i] == "Quality Dashboard 2021 Patient Summary") {
                    this.permission.QualityDashboard2021PatientSummary = true;
                  }
                  if (Permissionarray[i] == "Patient Status Tracking") {
                    this.permission.patienttracking = true;
                  }
                  if (Permissionarray[i] == "Surgical Order Tracking") {
                    this.permission.surgicalordertracking = true;
                  }
                  if (Permissionarray[i] == "Quality Dashboard 2021") {
                    this.permission.QualityDashboard2021 = true;
                  }
                  if (Permissionarray[i] == "QBots Dashboard") {
                    this.permission.qbotdashboard = true;
                  }
                  if (Permissionarray[i] == "Dashboard Loading Time") {
                    this.permission.dashboardloadingtime = true;
                  }
                  if (Permissionarray[i] == "Practice Updated Time") {
                    this.permission.dashboardupdatedtime = true;
                  }


                  // MIU Dashboards
                  if (Permissionarray[i] == "MIU Key Metrics") {
                    this.permission.keyMetricsMIU = true;
                  }
                  if (Permissionarray[i] == "MIU Charges Analysis") {
                    this.permission.chargesAnalysisMIU = true;
                  }
                  if (Permissionarray[i] == "MIU Payment Analysis") {
                    this.permission.paymentAnalysisMIU = true;
                  }
                  if (Permissionarray[i] == "MIU Productivity") {
                    this.permission.MIUproductivity = true;
                  }
                  if (Permissionarray[i] == "MIU Daily Tracker") {
                    this.permission.MIUdailyTracker = true;
                  }
                  if (Permissionarray[i] == "Expected Configuration MIU") {
                    this.permission.ExpectedConfigMIU = true;
                  }
                  if (Permissionarray[i] == "MIU Account Receivable") {
                    this.permission.ARMIUanalysis = true;
                  }
                  if (Permissionarray[i] == "MIU Financial Productivity") {
                    this.permission.miufinancialprod = true;
                  }
                  if (Permissionarray[i] == "MIU Denial Management") {
                    this.permission.MiuDenialManagement = true;
                  }
                  if (Permissionarray[i] == "Data Services") {
                    this.permission.Services = true;
                  }
                  if (Permissionarray[i] == "Product Usage Analytics") {
                    this.permission.productusage = true;
                  }
                  if (Permissionarray[i] == "Practice Configuration : Add new practice") {
                    this.permission.serviceconfigAdd = true;
                  }
                  if (Permissionarray[i] == "Practice Configuration : Delete practice") {
                    this.permission.serviceconfigDelete = true;
                  }
                  if (Permissionarray[i] == "Practice Configuration : Update practice details") {
                    this.permission.PracticeUpdate = true;
                  }
                  if (Permissionarray[i] == "Practice Configuration : Update Service details") {
                    this.permission.serviceconfigUpdate = true;
                  }
                  if (Permissionarray[i] == "Denial Prediction") {
                    this.permission.denialepayment = true;
                  }
                  if (Permissionarray[i] == "  Forecast Allowed Amount") {
                    this.permission.forecastalowedpayment = true;
                  }
                }
                //Encryption
                const Permissions = JSON.stringify(this.permission);
                localStorage.setItem('Permissions', this.tokenEncryptDecrypt.encryptToken(Permissions));

                this.firebaseService.getMaintenanceMode().subscribe(data => {
                  this.maintenanceMode = data.maintenanceMode;
                  if (this.maintenanceMode == true) {
                    if (this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("maintenance")) != "true") {
                      this._Router.navigate(['/financialmetrics/maintenance']);
                      return false;
                    }
                  }
                  else {
                    return true;
                  }
                });
              }

              const groupswithchild = this.api.getGroupWithMappedPractice().share();
              groupswithchild.map((res) => res.json()).subscribe((res) => {
                if (res.length > 0) {
                  this.filterdata.getUserLastSelection();
                  sessionStorage.setItem('GroupWithMappedPractice', this.tokenEncryptDecrypt.encryptToken(JSON.stringify(res)));
                  this.api.getFunctionName("practiceList");

                  const practiceftr = this.api.BindDataRCM(this.Practiceparam).share();
                  practiceftr.map((practice) => practice.json()).subscribe((practice) => {
                    this.BQpracticelist = practice;
                    sessionStorage.setItem('BQPractices', this.tokenEncryptDecrypt.encryptToken(JSON.stringify(this.BQpracticelist)));

                    const groups = this.api.getGroup().share();
                    groups.map((res) => res.json()).subscribe((res) => {
                      this.miulist = res;
                      sessionStorage.setItem('MIUPracticeList', this.tokenEncryptDecrypt.encryptToken(JSON.stringify(this.miulist)));
                      if (!isNullOrUndefined(sessionStorage.getItem("MIUPracticeList"))) {
                        this.MIUPracticeList = JSON.parse(this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("MIUPracticeList")));
                        let inew = [];
                        this.MIUPracticeList.forEach(group => {
                          if (group.groupcode === 'Qinsight_MIU_UC' || group.groupcode === 'Qinsight_MIU' || group.groupcode === 'Qinsight_UC') {
                            inew.push(group);
                          }
                        });
                        // console.log('saving GroupMIUPractice in session', inew);
                        sessionStorage.setItem('MIUGroupPractice', this.tokenEncryptDecrypt.encryptToken(JSON.stringify(inew)));  //Encryption   // sessionStorage.setItem("GroupPractice",JSON.stringify(res));
                      }
                      else { }
                      res = res.filter(item => !['Qinsight_MIU', 'Qinsight_UC', 'Qinsight_MIU_UC'].includes(item.groupcode));
                      sessionStorage.setItem('GroupPractice', this.tokenEncryptDecrypt.encryptToken(JSON.stringify(res)));
                    });

                    const practiceftr = this.api.getParentGroup().share();
                    practiceftr.map((res) => res.json()).subscribe((res) => {
                      if (res.length != 0) {
                        for (let i = 0; i < res.length; i++) {
                          if (res[i]["isparent"] == true && res[i].groupcode != "Qinsight_MIU_UC") {
                            this.ParentGrpList.push({
                              groupcode: res[i]["groupcode"],
                              groupid: res[i]["groupid"],
                              groupname: res[i]["groupname"],
                            });
                          }
                          else {
                            if (this.BQpracticelist.length != 0) {
                              for (let j = 0; j < this.BQpracticelist.length; j++) {
                                //for data masking on staging we are adding below if code
                                if (environment.currentEnvironment == "Staging") {
                                  if (this.BQpracticelist[j].clientkey.toUpperCase() == res[i].groupcode.toUpperCase()) {
                                    this.practicelist.push({
                                      parentgroupid: res[i]["groupid"],
                                      groupcode: res[i]["groupcode"],
                                      // groupname: res[i]["groupname"],
                                      groupname: this.BQpracticelist[j]["clinicname"],
                                    });
                                    this.listwithAllPractice.push({
                                      parentgroupid: res[i]["groupid"],
                                      groupcode: res[i]["groupcode"],
                                      // groupname: res[i]["groupname"],
                                      groupname: this.BQpracticelist[j]["clinicname"],
                                    });
                                  }
                                } else {
                                  if (this.BQpracticelist[j].clientkey.toUpperCase() == res[i].groupcode.toUpperCase()) {
                                    this.practicelist.push({
                                      parentgroupid: res[i]["groupid"],
                                      groupcode: res[i]["groupcode"],
                                      groupname: res[i]["groupname"],
                                      // groupname: this.BQpracticelist[j]["clinicname"],
                                    });
                                    this.listwithAllPractice.push({
                                      parentgroupid: res[i]["groupid"],
                                      groupcode: res[i]["groupcode"],
                                      groupname: res[i]["groupname"],
                                      // groupname: this.BQpracticelist[j]["clinicname"],
                                    });
                                  }
                                }
                              }
                            }
                          }
                        }

                        if (this.ParentGrpList.length != 0) {
                          sessionStorage.setItem('showgroupDDL', this.tokenEncryptDecrypt.encryptToken("true"));
                          sessionStorage.setItem('ParenGroupList', this.tokenEncryptDecrypt.encryptToken(JSON.stringify(this.ParentGrpList)));
                          var length1 = this.ParentGrpList.length
                          var length2 = 0
                          this.ParentGrpList.forEach((item, foreachindex) => {
                            const grpid = item.groupid;
                            const practiceftr = this.api.getChieldGroup(grpid).share();
                            practiceftr.map((res) => res.json()).subscribe((res) => {
                              length2++
                              for (let j = 0; j < res.length; j++) {
                                const index = this.practicelist.findIndex((item) => item.groupcode == res[j].groupcode);
                                if (this.BQpracticelist.length != 0) {
                                  for (let i = 0; i < this.BQpracticelist.length; i++) {
                                    //for data masking on staging we are adding below if code
                                    if (environment.currentEnvironment == "Staging") {
                                      if (this.BQpracticelist[i].clientkey.toUpperCase() == res[j]["groupcode"].toUpperCase()) {
                                        if (index == -1) {
                                          this.practicelist.push({
                                            parentgroupid: grpid,
                                            groupcode: res[j]["groupcode"],
                                            // groupname: res[j]["groupname"],
                                            groupname: this.BQpracticelist[i]["clinicname"],
                                          });
                                        }
                                        this.listwithAllPractice.push({
                                          parentgroupid: grpid,
                                          groupcode: res[j]["groupcode"],
                                          // groupname: res[j]["groupname"],
                                          groupname: this.BQpracticelist[i]["clinicname"],
                                        });
                                      }
                                    } else {
                                      if (this.BQpracticelist[i].clientkey.toUpperCase() == res[j]["groupcode"].toUpperCase()) {
                                        if (index == -1) {
                                          this.practicelist.push({
                                            parentgroupid: grpid,
                                            groupcode: res[j]["groupcode"],
                                            groupname: res[j]["groupname"],
                                            // groupname: this.BQpracticelist[i]["clinicname"],
                                          });
                                        }
                                        this.listwithAllPractice.push({
                                          parentgroupid: grpid,
                                          groupcode: res[j]["groupcode"],
                                          groupname: res[j]["groupname"],
                                          // groupname: this.BQpracticelist[i]["clinicname"],
                                        });
                                      }
                                    }
                                  }
                                }
                                if (length1 == length2) {
                                  if (this.practicelist.length != 0) {
                                    this.allgroupsavailable = true;
                                    this.practicelist.forEach((item, index) => { sessionStorage.setItem(`Practice_${index}`, JSON.stringify(item)) });
                                    sessionStorage.setItem('isPractice', this.tokenEncryptDecrypt.encryptToken("true"));
                                    sessionStorage.setItem('ListofPractice', this.tokenEncryptDecrypt.encryptToken(JSON.stringify(this.practicelist)));
                                    sessionStorage.setItem('listwithAllPractice', this.tokenEncryptDecrypt.encryptToken(JSON.stringify(this.listwithAllPractice)));
                                  } else {
                                    sessionStorage.setItem('isPractice', this.tokenEncryptDecrypt.encryptToken("false"));
                                    this.allgroupsavailable = true;
                                  }
                                }
                              }
                            });
                          });
                        }
                        else {
                          sessionStorage.setItem('showgroupDDL', this.tokenEncryptDecrypt.encryptToken("false"));
                          if (this.practicelist.length != 0) {
                            this.allgroupsavailable = true;
                            sessionStorage.setItem('isPractice', this.tokenEncryptDecrypt.encryptToken("true"));
                            sessionStorage.setItem('ListofPractice', this.tokenEncryptDecrypt.encryptToken(JSON.stringify(this.practicelist)));
                            sessionStorage.setItem('listwithAllPractice', this.tokenEncryptDecrypt.encryptToken(JSON.stringify(this.listwithAllPractice)));
                          } else {
                            sessionStorage.setItem('isPractice', this.tokenEncryptDecrypt.encryptToken("false"));
                            this.allgroupsavailable = true;
                          }
                        }
                      }
                    });
                  });

                  this.UserIPDetails.userid = userID;
                  this.UserIPDetails.loginuser = this.User_type.toString();
                  this.UserIPDetails.userFullName = this.fullname;
                  this.UserIPDetails.visitorbrowser = this.getbroweser();
                  this.http.get("https://extreme-ip-lookup.com/json/").map((res3) => res3.json()).subscribe(
                    (res) => {
                      this.georesult = res;
                      if (this.georesult == undefined) {
                        this.UserIPDetails.visitorip = "";
                        this.UserIPDetails.continent = "";
                        this.UserIPDetails.country = "";
                        this.UserIPDetails.countryCode = "";
                        this.UserIPDetails.city = "";
                        this.UserIPDetails.region = "";
                        //localStorage.setItem("currentUser",JSON.stringify(this.UserIPDetails));
                        localStorage.setItem('currentUser', this.tokenEncryptDecrypt.encryptToken(JSON.stringify(this.UserIPDetails)));
                        this.WriteAuditLog();
                      } else {
                        if (this.georesult.query == undefined) {
                          this.UserIPDetails.visitorip = "";
                          this.UserIPDetails.continent = "";
                          this.UserIPDetails.country = "";
                          this.UserIPDetails.countryCode = "";
                          this.UserIPDetails.city = "";
                          this.UserIPDetails.region = "";
                        } else {
                          this.UserIPDetails.visitorip = this.georesult.query;
                          this.UserIPDetails.continent = this.georesult.continent;
                          this.UserIPDetails.country = this.georesult.country;
                          this.UserIPDetails.countryCode = this.georesult.countryCode;
                          this.UserIPDetails.city = this.georesult.city;
                          this.UserIPDetails.region = this.georesult.region;
                        }
                        localStorage.setItem('currentUser', this.tokenEncryptDecrypt.encryptToken(JSON.stringify(this.UserIPDetails)));
                        this.WriteAuditLog();
                      }
                    },
                    (err) => {
                      localStorage.setItem('currentUser', this.tokenEncryptDecrypt.encryptToken(JSON.stringify(this.UserIPDetails))); //localStorage.setItem("currentUser",JSON.stringify(this.UserIPDetails));
                      this.toastr.error(err);
                      this.WriteAuditLog();
                    }
                  );

                  this.Practiceparam.environmenttype = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("currentEnvironment")).toString();
                  this.api.getFunctionName("BANQEnv");
                  const baqenv = this.api.BindDataHub(this.Practiceparam).share();
                  baqenv.map((res) => res.json()).subscribe((res) => {
                    if (res.length != 0) {
                      this.bankEnvkey = res[0]["clientkey"];
                      sessionStorage.setItem('bankEnvkey', this.tokenEncryptDecrypt.encryptToken(this.bankEnvkey));
                      this.clientlistparam.bankEnv = this.bankEnvkey.toString();

                      this.api.getFunctionName("BANQClientList");
                      const divclientmap = this.api.BindDataHub(this.clientlistparam).share();
                      divclientmap.map((res) => res.json()).subscribe((res) => {
                        this.BnanQclientlist = res;
                      });

                      this.bankEnvkey = res[0]["clientkey"];
                      this.divisionlistparam.bankEnv = this.bankEnvkey.toString();
                      this.divisionlistparam.userid = userID.toString();
                      this.divisionlistparam.userType = this.User_type.toString();

                      this.api.getFunctionName("BANQGroupList");
                      const grpcall = this.api.BindDataHub(this.divisionlistparam).share();
                      grpcall.map((res) => res.json()).subscribe((res) => {
                        this.GroupList = res;
                        if (this.GroupList.length != 0) {
                          sessionStorage.setItem('ListofGroup', this.tokenEncryptDecrypt.encryptToken(JSON.stringify(this.GroupList)));
                        }
                      });

                      this.api.getFunctionName("BANQDDivisionList");
                      const divmap = this.api.BindDataHub(this.divisionlistparam).share();
                      divmap.map((res) => res.json()).subscribe(
                        (res) => {
                          this.AllDivisionlist = res;
                          this.intervalId = setInterval(() => { this.userPermission(this.User_type, Permissionarray); }, 3000);
                        },
                        (err) => {
                          this.intervalId = setInterval(() => { this.userPermission(this.User_type, Permissionarray); }, 3000);
                        }
                      );
                    }
                  });

                  if (this.LoginRes.groups.length != 0) {
                    for (let i = 0; i < this.LoginRes.groups.length; i++) {
                      this.groupArray[i] = this.LoginRes.groups[i].groupname;
                      this.AssigneGroupcode.push(this.LoginRes.groups[i].groupcode);
                      this.AssigneGroupId.push(this.LoginRes.groups[i].groupid);
                      if (this.LoginRes.groups[i].tin == "" && this.LoginRes.groups[i].ccn == "") {
                        this.grparray[i] = this.LoginRes.groups[i];
                      }
                    }
                    if (this.groupArray.length == 0) {
                      sessionStorage.setItem("groupname", "");
                    } else {
                      let encrypttoken = this.LoginRes.groups[0].groupcode;
                      sessionStorage.setItem('groupname', this.tokenEncryptDecrypt.encryptToken(encrypttoken));

                      sessionStorage.setItem("tin", this.LoginRes.groups[0].tin);
                      sessionStorage.setItem('ccn', this.tokenEncryptDecrypt.encryptToken(this.LoginRes.groups[0].ccn));
                    }
                  }

                  this.npi = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("sNPI"));
                  if ((this.npi = "")) {
                    sessionStorage.setItem('npiID', this.tokenEncryptDecrypt.encryptToken(null));
                  } else {
                    sessionStorage.setItem('npiID', this.tokenEncryptDecrypt.encryptToken(this.LoginRes["snpi"]));
                  }
                  this.roles = [];

                  if (this.LoginRes.roles != 0) {
                    this.roles = this.LoginRes.roles;
                  }

                  if (this.roles.length == 0) {
                    sessionStorage.setItem('roles', this.tokenEncryptDecrypt.encryptToken(JSON.stringify("0")));
                  } else {
                    sessionStorage.setItem('roles', this.tokenEncryptDecrypt.encryptToken(JSON.stringify(this.roles)));
                  }
                  sessionStorage.setItem('grpname', this.tokenEncryptDecrypt.encryptToken(JSON.stringify(this.grparray)));
                  sessionStorage.setItem('validuser', this.tokenEncryptDecrypt.encryptToken("yes"));
                }
                else {
                  if (this.permission.paymentAnalysisMIU || this.permission.chargesAnalysisMIU || this.permission.keyMetricsMIU
                    || this.permission.MIUproductivity || this.permission.ExpectedConfigMIU || this.permission.MIUdailyTracker || this.permission.ARMIUanalysis || this.permission.miufinancialprod || this.permission.MiuDenialManagement) {
                    sessionStorage.setItem('isMIUuser', this.tokenEncryptDecrypt.encryptToken("true"));
                    this.navigateToDashboardMIU();
                  } else {
                    sessionStorage.setItem("issidebar", "true");
                    this._Router.navigate(["/qdashboards/AppAccess"]);
                  }
                  this.loginLoader = false;
                }
              });
            }
            else {
              sessionStorage.setItem("issidebar", "true");
              let permission = localStorage.getItem('Permissions');
              if (permission) {
                localStorage.removeItem('Permissions');
              }
              this._Router.navigate(["/qdashboards/AppAccess"]);
            }
          });
        },
        (err) => {
          this.toastr.warning("Invalid User");
          this.loginLoader = false;
          setTimeout(() => {
            window.location.assign(environment.Login_url);
          }, 2000);
        }
      );
    } else {
      window.location.assign(environment.Login_url);
    }
  }

  DDLdivisionlist = new Array();
  DDLClient = new Array();
  DivisionAndPracticeList: any[];
  userPermission(userType, Permissionarray) {
    if (this.allgroupsavailable == true) {
      clearInterval(this.intervalId);
      if (userType === "Manager") {
        if (Permissionarray.length != 0) {
          setTimeout(() => {
            this.getManager();
          }, 5000);
        } else {
        }
      } else if (userType === "Provider") {
        this.getOtherUser(Permissionarray);
      } else {
        this.getOtherUser(Permissionarray);
      }
    }
  }

  getOtherUser(Permissionarray) {
    if (this.AssigneGroupcode.length == 0) {
    } else {
      for (let i = 0; i < this.AssigneGroupcode.length; i++) {
        if (this.BnanQclientlist.length != 0) {
          for (let j = 0; j < this.BnanQclientlist.length; j++) {
            if (this.AssigneGroupcode[i] == this.BnanQclientlist[j].clientkey) {
              this.DDLClient.push({
                clientid: this.BnanQclientlist[j].clientid,
                clientname: this.BnanQclientlist[j].clientname,
              });
            }
          }
          if (this.DDLClient.length != 0) {
            sessionStorage.setItem('ListofClient', this.tokenEncryptDecrypt.encryptToken(JSON.stringify(this.DDLClient)));
          }
        }
        this.navigateToDashboard();
      }
    }

    if (this.AllDivisionlist.length == 0) {
      this.loginLoader = false;
      sessionStorage.setItem('isDivision', this.tokenEncryptDecrypt.encryptToken("false"));
    } else {
      for (let j = 0; j < this.AllDivisionlist.length; j++) {
        this.DDLdivisionlist.push({
          divisioncode: this.AllDivisionlist[j].divisioncode,
          divisionname: this.AllDivisionlist[j].divisionname,
        });
      }
      sessionStorage.setItem('onlydiv', this.tokenEncryptDecrypt.encryptToken("true"));
      sessionStorage.setItem('isDivision', this.tokenEncryptDecrypt.encryptToken("true"));
      sessionStorage.setItem('Divisioncode', this.tokenEncryptDecrypt.encryptToken(this.DDLdivisionlist[0].divcode));
      sessionStorage.setItem('ListofDivision', this.tokenEncryptDecrypt.encryptToken(JSON.stringify(this.AllDivisionlist)));
    }

    if (Permissionarray.length != 0) {
    } else {
    }
  }

  getManager() {
    if (this.BnanQclientlist.length != 0) {
      sessionStorage.setItem('ListofClient', this.tokenEncryptDecrypt.encryptToken(JSON.stringify(this.BnanQclientlist)));
    }

    if (this.AllDivisionlist.length == 0) {
      sessionStorage.setItem('onlydiv', this.tokenEncryptDecrypt.encryptToken("false"));
      sessionStorage.setItem('Divisioncode', this.tokenEncryptDecrypt.encryptToken(""));
    } else {
      sessionStorage.setItem('onlydiv', this.tokenEncryptDecrypt.encryptToken("false"));
      sessionStorage.setItem('Divisioncode', this.tokenEncryptDecrypt.encryptToken(this.AllDivisionlist[0].divisioncode));
      sessionStorage.setItem('ListofDivision', this.tokenEncryptDecrypt.encryptToken(JSON.stringify(this.AllDivisionlist)));
    }
    this.navigateToDashboard();
  }

  navigateToDashboard() {
    if (localStorage.getItem('URL') && !(localStorage.getItem('URL') == "null")) {
      this._Router.navigate([localStorage.getItem('URL')]);
    } else {
      this.navigateToDashboardRCM();
    }
    this.loginLoader = true;
  }

  navigateToDashboardRCM() {
    if (this.permission.home == true) {
      this._Router.navigate(["/qdashboards/home"]);
    } else if (this.permission.keyMetrics == true) {
      this._Router.navigate(["/financialmetrics/keyMetrics"]);
    } else if (this.permission.ProjectInsight == true) {
      this._Router.navigate(["/qdashboards/ProjectInsight"]);
    } else if (this.permission.MyProjects == true) {
      this._Router.navigate(["/qdashboards/MyProjects"]);
    } else if (this.permission.ProviderProductivity == true) {
      this._Router.navigate(["/financialmetrics/ProviderProductivity"]);
    } else if (this.permission.Financialproductivity == true) {
      this._Router.navigate(["/financialmetrics/Financialproductivity"]);
    } else if (this.permission.ChargesAnalysis == true) {
      this._Router.navigate(["/financialmetrics/ChargesAnalysis"]);
    } else if (this.permission.paymentAnalysis == true) {
      this._Router.navigate(["/financialmetrics/paymentAnalysis"]);
    } else if (this.permission.RefundAnalysis == true) {
      this._Router.navigate(["/financialmetrics/RefundAnalysis"]);
    } else if (this.permission.TakebackAnalysis == true) {
      this._Router.navigate(["/financialmetrics/TakebackAnalysis"]);
    } else if (this.permission.DailyTracker == true) {
      this._Router.navigate(["/financialmetrics/DailyTracker"]);
    } else if (this.permission.PaymentBreakUP == true) {
      this._Router.navigate(["/financialmetrics/PaymentBreakUP"]);
    } else if (this.permission.Paymentwaterfall == true) {
      this._Router.navigate(["/financialmetrics/Paymentwaterfall"]);
    } else if (this.permission.OverallARAndbreakup == true) {
      this._Router.navigate(["/financialmetrics/OverallARAndbreakup"]);
    } else if (this.permission.InsuranceDebitARAging == true) {
      this._Router.navigate(["/financialmetrics/InsuranceDebitARAging",]);
    } else if (this.permission.ProviderYTD == true) {
      this._Router.navigate(["/financialmetrics/ProviderYTD"]);
    } else if (this.permission.ProviderCharges == true) {
      this._Router.navigate(["/financialmetrics/ProviderCharges"]);
    } else if (this.permission.ProviderPayments == true) {
      this._Router.navigate(["/financialmetrics/ProviderPayments"]);
    }
    // RCM
    else if (this.permission.Acountrecivable == true) {
      this._Router.navigate(["/RCM/Acountrecivable"]);
    } else if (this.permission.DenialsHistory == true) {
      this._Router.navigate(["/RCM/DenialsHistory"]);
    } else if (this.permission.DenialManagement == true) {
      this._Router.navigate(["/RCM/DenialManagement"]);
    } else if (this.permission.MissingCharges == true) {
      this._Router.navigate(["/RCM/MissingCharges"]);
    } else if (this.permission.MonthlyCharges == true) {
      this._Router.navigate(["/RCM/MonthlyCharges"]);
    } else if (this.permission.MissedOpportunities == true) {
      this._Router.navigate(["/RCM/MissedOpportunities"]);
    } else if (this.permission.MonthlyClose == true) {
      this._Router.navigate(["/RCM/MonthlyClose"]);
    } else if (this.permission.MtdYtdReport == true) {
      this._Router.navigate(["/RCM/MtdYtdReport"]);
    } else if (this.permission.DailyCharges == true) {
      this._Router.navigate(["/RCM/DailyCharges"]);
    } else if (this.permission.DailyClose == true) {
      this._Router.navigate(["/RCM/DailyClose"]);
    } else if (this.permission.MonthlyPayment == true) {
      this._Router.navigate(["/RCM/MonthlyPayment"]);
    } else if (this.permission.allrcmProductivity == true) {
      this._Router.navigate(["/RCM/allrcmProductivity"]);
    } else if (this.permission.rcmProductivity == true) {
      this._Router.navigate(["/RCM/rcmProductivity"]);
    } else if (this.permission.dellclaimexport == true) {
      this._Router.navigate(["/RCM/dellclaimexport"]);
    } else if (this.permission.rcmanalytics == true) {
      this._Router.navigate(["/RCM/rcmanalytics"]);
    } else if (this.permission.chargeliquidation == true) {
      this._Router.navigate(["/RCM/chargeliquidation"]);
    } else if (this.permission.availablereserve == true) {
      this._Router.navigate(["/RCM/availablereserve"]);
    } else if (this.permission.appointcensusreport == true) {
      this._Router.navigate(["/RCM/appointcensusreport"]);
    } else if (this.permission.QPMholddashboard == true) {
      this._Router.navigate(["/RCM/QPMholddashboard"]);
    } else if (this.permission.TimelyFiling == true) {
      this._Router.navigate(["/RCM/TimelyFiling"]);
    } else if (this.permission.CPTAnalysis == true) {
      this._Router.navigate(["/RCM/CPTAnalysis"]);
    } else if (this.permission.RCMWaterfall == true) {
      this._Router.navigate(["/RCM/RCMWaterfall"]);
    } else if (this.permission.RevenueCycle == true) {
      this._Router.navigate(["/RCM/RevenueCycle"]);
    } else if (this.permission.ExpectedCollection == true) {
      this._Router.navigate(["/RCM/ExpectedCollection"])
    } else if (this.permission.CPTExpectedCollection == true) {
      this._Router.navigate(["/RCM/CPTExpectedCollection"]);
    } else if (this.permission.PanelPayerDistribution == true) {
      this._Router.navigate(["/RCM/PanelPayerDistribution"]);
    } else if (this.permission.paneldistribution == true) {
      this._Router.navigate(["/RCM/paneldistribution"]);
    } else if (this.permission.LocationFinancialPayment == true) {
      this._Router.navigate(["/RCM/LocationFinancialPayment"]);
    } else if (this.permission.RCMDayWisePayerPayment == true) {
      this._Router.navigate(["/RCM/RCMDayWisePayerPayment"]);
    } else if (this.permission.ClaimNotWorkedUpon == true) {
      this._Router.navigate(["/RCM/ClaimNotWorkedUpon"]);
    } else if (this.permission.PatientPayment == true) {
      this._Router.navigate(["/RCM/PatientPayment"]);
    } else if (this.permission.PracticeCompensation == true) {
      this._Router.navigate(["/RCM/PracticeCompensation"]);
    } else if (this.permission.ProviderCompensation == true) {
      this._Router.navigate(["/RCM/ProviderCompensation"]);
    } else if (this.permission.ProviderCompensationbyPOS == true) {
      this._Router.navigate(["/RCM/ProviderCompensationbyPOS"]);
    } else if (this.permission.DailyChargeDetails == true) {
      this._Router.navigate(["/RCM/DailyChargeDetails"]);
    } else if (this.permission.chargebatchLag == true) {
      this._Router.navigate(["/RCM/chargebatchLag"]);
    } else if (this.permission.ExamFinishLag == true) {
      this._Router.navigate(["/RCM/ExamFinishLag"]);
    } else if (this.permission.Metric == true) {
      this._Router.navigate(["/RCM/Metric"]);
    } else if (this.permission.AgedPayments == true) {
      this._Router.navigate(["/RCM/AgedPayments"]);
    } else if (this.permission.erapaymentposting == true) {
      this._Router.navigate(["/RCM/erapaymentposting"]);
    } else if (this.permission.Insurancependingchecks == true) {
      this._Router.navigate(["/RCM/Insurancependingchecks"]);
    }
    // BIOTECH
    else if (this.permission.BatchDistribution == true) {
      this._Router.navigate(["/qdashboards/BatchDistribution"]);
    } else if (this.permission.BiotechConfiguration == true) {
      this._Router.navigate(["/qdashboards/BiotechConfiguration"]);
    } else if (this.permission.IncompleteOrder == true) {
      this._Router.navigate(["/qdashboards/IncompleteOrder"]);
    } else if (this.permission.Trizetto == true) {
      this._Router.navigate(["/qdashboards/Trizetto"]);
    } else if (this.permission.RejectionRate == true) {
      this._Router.navigate(["/qdashboards/RejectionRate"]);
    }
    // HUB
    else if (this.permission.ERAClientDistribution == true) {
      this._Router.navigate(["/qdashboards/ERAClientDistribution"]);
    } else if (this.permission.ERADaywiseDistribution == true) {
      this._Router.navigate(["/qdashboards/ERADaywiseDistribution"]);
    } else if (this.permission.ERADivisionDistribution == true) {
      this._Router.navigate(["/qdashboards/ERADivisionDistribution"]);
    } else if (this.permission.checkdistribution == true) {
      this._Router.navigate(["/qdashboards/checkdistribution"]);
    } else if (this.permission.filedistribution == true) {
      this._Router.navigate(["/qdashboards/filedistribution"]);
    } else if (this.permission.practicesummary == true) {
      this._Router.navigate(["/qdashboards/practicesummary"]);
    } else if (this.permission.DayWisePayerPayment == true) {
      this._Router.navigate(["/qdashboards/DayWisePayerPayment"]);
    } else if (this.permission.honestreport == true) {
      this._Router.navigate(["/qdashboards/honestreport"]);
    } else if (this.permission.newfiledistribution == true) {
      this._Router.navigate(["/qdashboards/newfiledistribution"]);
    } else if (this.permission.mhphubreport == true) {
      this._Router.navigate(["/qdashboards/mhphubreport"]);
    }
    // MIU
    else if (this.permission.keyMetricsMIU == true) {
      this._Router.navigate(["/miu/keyMetricsMIU"]);
    } else if (this.permission.chargesAnalysisMIU == true) {
      this._Router.navigate(["/miu/chargesAnalysisMIU"]);
    } else if (this.permission.paymentAnalysisMIU == true) {
      this._Router.navigate(["/miu/paymentAnalysisMIU"]);
    } else if (this.permission.MIUproductivity == true) {
      this._Router.navigate(["/miu/MIUproductivity"]);
    } else if (this.permission.MIUdailyTracker == true) {
      this._Router.navigate(["/miu/MIUdailyTracker"]);
    } else if (this.permission.ExpectedConfigMIU == true) {
      this._Router.navigate(["/miu/ExpectedConfigMIU"]);
    } else if (this.permission.ARMIUanalysis == true) {
      this._Router.navigate(["/miu/ARMIUanalysis"]);
    } else if (this.permission.miufinancialprod == true) {
      this._Router.navigate(["/miu/miufinancialprod"]);
    } else if (this.permission.MiuDenialManagement == true) {
      this._Router.navigate(["/miu/MiuDenialManagement"]);
    }
    // Monitor
    else if (this.permission.productusage == true) {
      this._Router.navigate(["/qdashboards/productusage"]);
    }
    // Adhoc Report
    else if (this.permission.CPTAllowedvsASPPricing == true) {
      this._Router.navigate(["/qdashboards/CPTAllowedvsASPPricing"]);
    }
    // QBots Monitor
    else if (this.permission.qbotdashboard == true) {
      this._Router.navigate(["/qdashboards/qbotdashboard"]);
    } else if (this.permission.dashboardloadingtime == true) {
      this._Router.navigate(["/qdashboards/dashboardloadingtime"]);
    } else if (this.permission.dashboardupdatedtime == true) {
      this._Router.navigate(["/qdashboards/dashboardupdatedtime"]);
    }
    // Configuration
    else if (this.permission.MRIDetails == true) {
      this._Router.navigate(["/qdashboards/MRIDetails"]);
    } else if (this.permission.QAdminPracticeConfiguration == true) {
      this._Router.navigate(["/qdashboards/QAdminPracticeConfiguration"]);
    } else if (this.permission.ModelConfiguration == true) {
      this._Router.navigate(["/qdashboards/ModelConfiguration"]);
    } else if (this.permission.ProviderConfiguration == true) {
      this._Router.navigate(["/qdashboards/ProviderConfiguration"]);
    } else if (this.permission.ProviderConfigurationbyPOS == true) {
      this._Router.navigate(["/qdashboards/ProviderConfigurationbyPOS"]);
    } else if (this.permission.QAdminBillingConfiguration == true) {
      this._Router.navigate(["/qdashboards/QAdminBillingConfiguration"]);
    } else if (this.permission.QAdminDayWisePayerPayment == true) {
      this._Router.navigate(["/qdashboards/QAdminDayWisePayerPayment"]);
    } else if (this.permission.ExpectedValueConfig == true) {
      this._Router.navigate(["/qdashboards/ExpectedValueConfig"]);
    } else if (this.permission.DenialConfig == true) {
      this._Router.navigate(["/qdashboards/DenialConfig"]);
    } else if (this.permission.RCMUserConfig == true) {
      this._Router.navigate(["/qdashboards/RCMUserConfig"]);
    }
    // Value
    else if (this.permission.alliancesummaryanalytics == true) {
      this._Router.navigate(["/value/alliancesummaryanalytics"]);
    } else if (this.permission.practicesummaryanalytics == true) {
      this._Router.navigate(["/value/practicesummaryanalytics"]);
    } else if (this.permission.facilityanalytics == true) {
      this._Router.navigate(["/value/facilityanalytics"]);
    } else if (this.permission.hhaanalytics == true) {
      this._Router.navigate(["/value/hhaanalytics"]);
    } else if (this.permission.snfanalytics == true) {
      this._Router.navigate(["/value/snfanalytics"]);
    } else if (this.permission.ptanalytics == true) {
      this._Router.navigate(["/value/ptanalytics"]);
    } else if (this.permission.orderanalytics == true) {
      this._Router.navigate(["/value/orderanalytics"]);
    }
    //  Other
    else if (this.permission.ChemononChemo == true) {
      this._Router.navigate(["/qdashboards/ChemononChemo"]);
    } else if (this.permission.RenderingProviderYTD == true) {
      this._Router.navigate(["/qdashboards/RenderingProviderYTD"]);
    } else if (this.permission.InsuranceARComparision == true) {
      this._Router.navigate(["/qdashboards/InsuranceARComparision"]);
    } else if (this.permission.CustomizedDashboard == true) {
      this._Router.navigate(["/qdashboards/CustomizedDashboard"]);
    } else if (this.permission.DenialAnalysis == true) {
      this._Router.navigate(["/qdashboards/DenialAnalysis"]);
    } else if (this.permission.AddDenialConfig == true) {
      this._Router.navigate(["/qdashboards/AddDenialConfig"]);
    } else if (this.permission.TFLUserConfig == true) {
      this._Router.navigate(["/qdashboards/TFLUserConfig"]);
    } else if (this.permission.automailconfig == true) {
      this._Router.navigate(["/qdashboards/automailconfig"]);
    } else if (this.permission.Insurancefollowup == true) {
      this._Router.navigate(["/qdashboards/Followup"]);
    } else if (this.permission.Biotechproductivity == true) {
      this._Router.navigate(["/qdashboards/Biotechproductivity"]);
    } else if (this.permission.orthoLowerSummary == true) {
      this._Router.navigate(["/qdashboards/orthoLowerSummary"]);
    } else if (this.permission.OrtholowerIPRiskStratified == true) {
      this._Router.navigate(["/qdashboards/orthoLowerIPRisk"]);
    } else if (this.permission.OrtholowerFractures == true) {
      this._Router.navigate(["/qdashboards/fractures"]);
    } else if (this.permission.OrtholowerReadmissions == true) {
      this._Router.navigate(["/qdashboards/orthoLowerReadmissions"]);
    } else if (this.permission.Orthouppersummary == true) {
      this._Router.navigate(["/qdashboards/orthoUpperSummary"]);
    } else if (this.permission.OrthoupperIPRiskStratified == true) {
      this._Router.navigate(["/qdashboards/orthoUpperIPRisk"]);
    } else if (this.permission.UtilizationComparison == true) {
      this._Router.navigate(["/qdashboards/UtilizationComparison"]);
    } else if (this.permission.ReconciliationPerformance == true) {
      this._Router.navigate(["/qdashboards/ReconciliationPerformance"]);
    } else if (this.permission.ActualReconciliation == true) {
      this._Router.navigate(["/qdashboards/ActualReconciliation"]);
    } else if (this.permission.Covid == true) {
      this._Router.navigate(["/qdashboards/covid"]);
    } else if (this.permission.QualityDashboard2021 == true) {
      this._Router.navigate(["/qdashboards/QualityDashboard2021"]);
    } else if (this.permission.Services == true) {
      this._Router.navigate(["/qdashboards/Services"]);
    } else if (this.permission.QualityDashboard2021PatientSummary == true) {
      this._Router.navigate(["/qdashboards/QualityDashboard2021PatientSummary"]);
    } else if (this.permission.patienttracking == true) {
      this._Router.navigate(["/qdashboards/patienttracking"]);
    } 
    else if (this.permission.surgicalordertracking == true) {
      this._Router.navigate(["/qdashboards/surgicalordertracking"]);
    }else if (this.permission.AcountrecivableinDash == true) {
      this._Router.navigate(["/qdashboards/AcountrecivableinDash"]);
    } else if (this.permission.ReconcilationUtilization == true) {
      this._Router.navigate(["/qdashboards/ReconcilationUtilization"]);
    } else if (this.permission.PostUtilization == true) {
      this._Router.navigate(["/qdashboards/PostUtilization"]);
    } else if (this.permission.PracticeUpdate == true) {
      this._Router.navigate(["/qdashboards/PracticeUpdate"]);
    } else if (this.permission.denialepayment == true) {
      this._Router.navigate(["/mldashboards/denialepayment"]);
    } else if (this.permission.forecastalowedpayment == true) {
      this._Router.navigate(["/mldashboards/forecastalowedpayment"]);
    }
  }

  navigateToDashboardMIU() {
    if (this.permission.keyMetricsMIU == true) {
      this._Router.navigate(["/miu/keyMetricsMIU"]);
    } else if (this.permission.chargesAnalysisMIU == true) {
      this._Router.navigate(["/miu/chargesAnalysisMIU"]);
    } else if (this.permission.paymentAnalysisMIU == true) {
      this._Router.navigate(["/miu/paymentAnalysisMIU"]);
    } else if (this.permission.MIUproductivity == true) {
      this._Router.navigate(["/miu/MIUproductivity"]);
    } else if (this.permission.MIUdailyTracker == true) {
      this._Router.navigate(["/miu/MIUdailyTracker"]);
    } else if (this.permission.ExpectedConfigMIU == true) {
      this._Router.navigate(["/miu/ExpectedConfigMIU"]);
    } else if (this.permission.ARMIUanalysis == true) {
      this._Router.navigate(["/miu/ARMIUanalysis"]);
    } else if (this.permission.miufinancialprod == true) {
      this._Router.navigate(["/miu/miufinancialprod"]);
    } else if (this.permission.MiuDenialManagement == true) {
      this._Router.navigate(["/miu/MiuDenialManagement"]);
    } else {
      this._Router.navigate(["/qdashboards/AppAccess"])
    }
  }

  private newMethod(): any {
    return "/qdashboards/metrics";
  }

  navToProviderdash() {
    this._Router.navigate(["/financialmetrics/KeyMetrics"]);
  }

  public getbroweser() {
    var sBrowser,
      sUsrAg = navigator.userAgent;
    if (sUsrAg.indexOf("Firefox") > -1) {
      sBrowser = "Mozilla Firefox";
      // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
    } else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
      sBrowser = "Samsung Internet";
      // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
    } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
      sBrowser = "Opera";
      // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
    } else if (sUsrAg.indexOf("Trident") > -1) {
      sBrowser = "Microsoft Internet Explorer";
      // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
    } else if (sUsrAg.indexOf("Edge") > -1) {
      sBrowser = "Microsoft Edge";
      // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
    } else if (sUsrAg.indexOf("Chrome") > -1) {
      sBrowser = "Google Chrome or Chromium";
      // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
    } else if (sUsrAg.indexOf("Safari") > -1) {
      sBrowser = "Apple Safari";
      // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
    } else {
      sBrowser = "unknown";
    }

    return sBrowser;
  }

  WriteAuditLog() {
    this.logapi.insertActivityLog("User Login", "Login", "Login");
  }

  Logout() {
    setTimeout(() => {
      sessionStorage.clear();
      localStorage.clear();
      this.cookieService.delete("UID");
      this.cookieService.delete("AID");
    }, 1000);
    window.location.assign(environment.Logout_url);
  }
}
function isNullOrUndefined(value: any): boolean { return value === null || value === undefined; }