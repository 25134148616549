import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minusSignToParens'
})
export class MinusSignToParens implements PipeTransform {

  transform(value: string, args?: any): any {
    if (value == null) {
    } else {
      let val = value.toString();
      return val.charAt(0) === '-' ?
        '(' + val.substring(1, val.length) + ')' :
        val;
    }
  }
}
