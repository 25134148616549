<!-- <div style="margin-left: 1in">
    <p>
      <B>Cookies set!</B>
    </p>
    <p>
      <B>UID :{{ this.UID }} </B>
    </p>
    <p>
      <B>AID : {{ this.AID }}</B>
    </p>
  </div>
   -->

   <!-- loader panel -->
<div class="loader-block" *ngIf="loginLoader">
  <div class="loader-content text-center">
      <div class="loader-data">
          <img src="../../assets/img/Q-progressbar-blue.gif" alt="" class="mb-4" width="100" />
      </div>
  </div>
</div>
<!-- end loader -->