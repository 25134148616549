import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import {Router} from '@angular/router';
import { CookieService } from "ngx-cookie-service";
import { environment } from "./../../environments/environment";
import { TokenEncryptionService } from "../CallServices/token-encryption.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private auth: AuthService,private cookieService:CookieService,private tokenEncryptDecrypt:TokenEncryptionService,

    private Route: Router, private route: ActivatedRoute){
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      //debugger;
      if (this.cookieService.check('UID') && this.cookieService.check('AID')) //if (localStorage.getItem('newmyqone')) 
      {
        if(sessionStorage.getItem("URL") || sessionStorage.length>0){
          return true;
        }else{
          localStorage.setItem("URL",this.tokenEncryptDecrypt.encryptToken(state.url) )  
          window.location.assign(
            environment.Login_url
          );
          return true;
        }
      } else {
       // environment.Login_url
       localStorage.setItem("URL", this.tokenEncryptDecrypt.encryptToken(state.url))
       window.location.assign(
        environment.Login_url
      );
        return false;
      }
  }
   
}
