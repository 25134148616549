<!-- Loader -->
<div class="loader-block" *ngIf="bIsLoader">
    <div class="loader-content text-center">
        <div class="loader-data">
            <img src="assets/img/Q-progressbar-blue.gif" alt="" class="mb-4" width="50" />
            <p>{{ LoaderLabel }}</p>
        </div>
    </div>
</div>
<!-- Loader -->

<div class="row">
    <div class="col-md-12">
        <div class="media">
            <div>
                <div class="main-header">My Projects 
                    <span class="filter-btn ml-2 pointer" arToggle>
                        <i class="fa fa-filter"> </i>
                        Filter
                    </span>
                </div>
            </div>

        </div>
        <div class="media-body">
            <div class="py-3">
                <span class="text-success tag-data mt-1 mr-2">
                    <span>{{ practicename | uppercase }}</span>
                </span>
                <span class="text-success tag-data mt-1 mr-2">
                    <span>Start Date : {{ StartOfDate | date: 'MM-dd-yyyy'}}</span>
                </span>
                <span class="text-success tag-data mt-1 mr-2">
                    <span>End Date : {{ EndOfDate | date: 'MM-dd-yyyy'}}</span>
                </span>                
            </div>
        </div>
    </div>
</div>

    <div class="row">    
        <div class="col-md-12">
            <div class="card days_card">
                <div class="card-body">
                   
                    <div class="d-flex align-items-start justify-content-between">
                        <div  class="claim-box border-right mr-3">
                            <div class="font-12 text-info">My Project</div>
                            <h5 class="mb-0">  {{ Scorecard_MyProjects }} </h5>
                        </div>
                        <div class="claim-box border-right mr-3">
                            <div class="font-12 text-primary">Assigned Project</div>
                            <h5 class="mb-0"> {{ Scorecard_AssignedProjectCount }} </h5>
                        </div>
                        <div class="claim-box   border-right mr-3">
                            <div class="font-12 text-info">In-Process Project</div>
                            <h5 class="mb-0"> {{ Scorecard_MyProjectsInprocess }} </h5>
                        </div>
                        <div class="claim-box  border-right mr-3">
                            <div class="font-12 text-danger">Completed Project</div>
                            <h5 class="mb-0"> {{ Scorecard_MyProjectsComplete }} </h5>
                        </div>
                        <div class="claim-box ">
                            <div class="font-12 text-danger">Closed Project</div>
                            <h5 class="mb-0"> {{ Scorecard_MyProjectsClose }} </h5>
                        </div>
                    </div>
    
                       
                </div>
            </div>
        </div>
    </div>     


<!-- <div class="card">
    <div class="card-header pl-3 py-2">
        <h6 class="header-title mb-0">Assigned Projects</h6>
    </div>
    <div class="card-body"> -->
        <kendo-grid [kendoGridBinding]="CreatedProjectData" [height]="410" [loading]="loadingClaimDetailsGrid" (edit)="EditProjects($event)"
        [sortable]="true" [pageSize]="pageSize" [pageable]="true" class="color-header" (cellClick)="OnCellClickCreatedProject($event)">
            <ng-template kendoGridToolbarTemplate>
                <div class="d-flex align-items-center">
                    <div class="header-title">My Projects</div>
                    <div class="ml-auto">
                        <input placeholder="Search in all columns..." style="width:400px;margin-right: 5px;" kendoTextBox (input)="DetailProjectSearch($event.target.value)"  />
                        <button kendoGridExcelCommand [icon]="'file-excel'" class="btn-export">
                            Export
                        </button>
                    </div>
                </div>
                
            </ng-template>
            <kendo-grid-command-column title="Action" width="70">
                <ng-template kendoGridCellTemplate>
                    <button kendoGridEditCommand title="Edit" data-toggle="modal" data-target="#editassignedcreatedprojectsconfirmationModal">
                        <span class="k-icon k-i-pencil mr-1" style="font-size: 14px; color: black;"></span>
                    </button>                                                            
                </ng-template>
            </kendo-grid-command-column>
            <kendo-grid-column field="ProjectName" title="Project" width="200">
                <ng-template kendoGridCellTemplate let-dataItem="dataItem">            
                    <div class="mb-0 pointer text-left">
                        {{ dataItem.ProjectName }}
                    </div>
                </ng-template>
            </kendo-grid-column>                                                
            <kendo-grid-column field="ClaimCount" title="Claim Count" width="110" class="text-right">                
            </kendo-grid-column>
            <kendo-grid-column field="Priority" title="Priority" width="90" class="text-left">                
            </kendo-grid-column>
            <kendo-grid-column field="Status" title="Status" width="90" class="text-left">                
            </kendo-grid-column>
            <kendo-grid-column field="Balance" title="Balance" width="90">
                <ng-template kendoGridCellTemplate let-dataItem="dataItem">                                                     
                    <div class="text-right">
                        {{ dataItem.Balance | currency : "USD":true:"2.0" }}
                    </div>                    
                </ng-template>                            
            </kendo-grid-column>  
            <kendo-grid-column field="ExpectedCompletionDate" title="Expected Completion Date" width="140">
                <ng-template kendoGridHeaderTemplate let-column>
                    <span title="" style="white-space: pre-wrap !important;"
                      >Expected Completion Date</span
                    >
                  </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem="dataItem">                                                 
                    <div class="text-right">
                        {{ dataItem.ExpectedCompletionDate | date: 'MM-dd-yyyy' }}
                    </div>                    
                </ng-template>                            
            </kendo-grid-column> 
            <kendo-grid-column field="CreatedBy" title="Created By" width="140" class="text-left">                
            </kendo-grid-column>
            <kendo-grid-column field="CreatedOn" title="Created On" width="100">
                <ng-template kendoGridCellTemplate let-dataItem="dataItem">                                
                    <div class="text-right">
                        {{ dataItem.CreatedOn  | date: 'MM-dd-yyyy' }}                                    
                    </div>
                </ng-template>
            </kendo-grid-column>  
            <kendo-grid-column field="Description" title="Note" width="380" class="text-left">                
            </kendo-grid-column>
            <kendo-grid-excel fileName="CreatePecoject.xlsx" [fetchData]="MyProjectwiseDetailsExport"></kendo-grid-excel> 
        </kendo-grid>
    <!-- </div>
</div> -->
<br>
<!-- Side Panel START -->
<div class="ar-panel">
    <div class="ar-panel-wrapper ar-side-panel shadow">
        <div class="card mb-0">
            <div class="card-body pt-4">
                <div class="row">
                    <div class="col-md-3 form-group">
                        <div class="form-group">
                            <label for="" class="form-label">Practice</label>
                            <kendo-multiselect [data]="PracticeList" [textField]="'groupname'"
                                [(ngModel)]="selectedPractice" [valueField]="'groupcode'"
                                (valueChange)="PracticeSelect($event)" [placeholder]="'Search Practice'">
                            </kendo-multiselect>
                        </div>
                      </div>
                    <div class="col-md-3 form-group">
                        <label for="" class="form-label">Start Date</label>
                        <kendo-datepicker class="w-100" [(value)]="StartOfDate"></kendo-datepicker>
                    </div>
                    <div class="col-md-3 form-group">
                        <label for="" class="form-label">End Date</label>
                        <kendo-datepicker class="w-100" [(value)]="EndOfDate"></kendo-datepicker>
                    </div>                    
                </div>
            </div>
            <div class="card-footer">
                <div class="float-left">
                    <button class="btn btn-success btn-sm mr-2" arToggle (click)="Submit_Filter()">
                        Apply
                    </button>
                    <button class="btn btn-secondary btn-sm" arToggle (click)="Close_Filter()">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Side Panel END -->

<!--Edit Confirmation Model -->
<div class="modal fade" data-backdrop="static" data-keyboard="false" id="editassignedcreatedprojectsconfirmationModal" role="dialog"
  aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edit</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">      
          </button>
        </div>
        <div class="modal-body">
          <p>{{EditMessage}}</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="onEditYes()" data-toggle="modal" data-target="#EditCreateProjectModal" data-dismiss="modal">Yes</button>
          <button type="button" class="btn btn-secondary" (click)="onEditNo()" data-dismiss="modal">No</button>
        </div>
      </div>
  </div>
</div>
<!--Edit Confirmation Model -->
<div class="modal fade" data-backdrop="static" data-keyboard="false" id="EditCreateProjectModal" role="dialog"
  aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Update Project</h5>
          <button type="button" class="close" aria-label="Close" data-dismiss="modal" (click)="onCancel()">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form [formGroup]="EditProjectGroup">
                <div class="row">
                    <div class="col-md-6">                    
                        <label class="form-label">Claim Count : {{ClaimCount}}</label> 
                    </div>
                    <div class="col-md-6">                    
                        <label class="form-label">Total Balance : {{Balance | currency: "USD":true:"2.0"}}</label>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-label">Project Name</label>
                            <kendo-textbox
                                [disabled]="true"
                                formControlName="fcProjectName"
                                [(ngModel)]="ProjectNamedisplay"
                                type="text"
                                id="ProjectName"
                                class="form-control form-control-sm flex-fill"
                                title="Enter Project Name"
                                placeholder="Enter Project Name"                
                            ></kendo-textbox>  
                            <div *ngIf="submitted || (ProjectName.invalid && ProjectName.touched)">
                                <small *ngIf="ProjectName.errors?.required" class="text-danger">Project Name is required</small>                            
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label class="form-label">Expected Completion Date</label>
                        <kendo-datepicker class="w-100" [(value)]="EnddateValue" formControlName="fcExpectedCompletionDate" [min]="min">
                        </kendo-datepicker>
                        <div *ngIf="submitted || (ExpectedCompletionDate.touched && ExpectedCompletionDate.invalid)">
                            <small *ngIf="ExpectedCompletionDate.errors?.required" class="text-danger">
                                Expected Completion Date is required</small>
                        </div>
                    </div> 
                    <div class="col-md-6">                        
                        <div class="form-group">                        
                            <label class="form-label">Status</label>                        
                            <kendo-dropdownlist                              
                              formControlName="fcStatus"
                              class="w-100"
                              id="ddStatus"
                              [(ngModel)]="Selected_Status"
                              [textField]="'text'"
                              [valueField]="'value'"
                              [data]="Status_List"
                              [valuePrimitive]="true"
                            ></kendo-dropdownlist> 
                        </div>
                    </div>                
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-label">Project Type</label>                                
                            <kendo-dropdownlist
                            class="w-100"
                            formControlName="fcProjectType"
                            [data]="data"
                            [filterable]="true"
                            [textField]="'Projecttype'"
                            [valueField]="'Projecttypeid'"
                            (filterChange)="handleFilter($event)"
                            [(ngModel)]="Selected_ProjectType"
                            >
                            <ng-template kendoDropDownListNoDataTemplate>
                                <div>
                                No data found.
                                <ng-container *ngIf="filter"
                                    >Do you want to add new item - "{{ filter }}" ?</ng-container
                                >
                                <br />
                                <button *ngIf="filter" class="k-button" (click)="addNew()">
                                    Add new item
                                </button>
                                </div>
                            </ng-template>
                            </kendo-dropdownlist>
                            <div *ngIf="submitted || (ProjectType.invalid && ProjectType.touched)">
                                <small *ngIf="ProjectType.errors?.required" class="text-danger">Project Type is required</small>                            
                            </div>
                        </div>
                    </div>               
                    <div class="col-md-6">                      
                        <div class="form-group">
                            <label class="form-label">Priority</label>
                            <kendo-dropdownlist
                              formControlName="fcPriority"
                              class="w-100"
                              id="ddPrority"
                              [(ngModel)]="Selected_Priority"
                              [textField]="'text'"
                              [valueField]="'value'"
                              [data]="Priority_List"
                              [valuePrimitive]="true"
                            ></kendo-dropdownlist>
                        </div>
                    </div>
                    <div class="col-md-6"> 
                        <div class="form-group">
                            <label class="form-label">Owner</label>
                          <kendo-combobox 
                          formControlName="fcTo"
                          [data]="OwnerListData" 
                          [textField]="'displayname'" 
                          [valueField]="'email'" 
                          [(ngModel)]="SelectedSendToUser"
                          (valueChange)="SelectSendToUserEmailSingle($event)" 
                          [placeholder]="'Search Owner'" class="w-100"
                          >
                          </kendo-combobox>     
                          
                          <div *ngIf="submitted || (AssignedTo.invalid && AssignedTo.touched)" class="text-danger">
                            <small *ngIf="AssignedTo.errors?.required">Owner is required</small>
                          </div>
                        </div>
                    </div>
                    <div class="col-md-6" [hidden]="true">
                        <div class="form-group">
                            <label class="form-label" [hidden]="true">Send To</label>
                            <input
                                [hidden]="true"
                                formControlName="fcSendTo"
                                type="text"
                                class="w-100"
                                [(ngModel)]="SelectedSendToUserEmailtxt"
                                kendoTextBox
                            />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-label">Follower</label>
                            <kendo-multiselect
                            formControlName="fcCC"
                            [data]="OwnerListData"                       
                            [placeholder]="'Select Follower'"
                            [textField]="'displayname'"
                            [valueField]="'email'"
                            [(ngModel)]="SelectedCCUser"
                            (valueChange)="SelectCCUserEmail($event)"
                          >
                          </kendo-multiselect>                          
                        </div>
                    </div>
                    <div class="col-md-6" [hidden]="true">
                        <div class="form-group">
                            <label class="form-label" [hidden]="true">CC</label>
                            <input
                                [hidden]="true"
                                formControlName="fcCCSendTo"
                                type="text"
                                class="w-100"
                                [(ngModel)]="SelectedCCUserEmailtxt"
                                kendoTextBox
                            />
                        </div>
                    </div>      
                    <div class="col-md-12">                    
                      <div class="form-group">
                        <label class="form-label">Description</label>
                        <textarea kendoTextArea [(ngModel)]="Descriptiontext" formControlName="fcDescription" id="Description" class="w-100"></textarea>
                      </div>
                    </div>  
                </div>
            </form>  
        </div>
        <div class="modal-footer py-2">      
            <button type="button" class="btn btn-success btn-sm mr-2"  (click)="onEdit()">Update</button>                    
            <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal" (click)="onCancel()" >Cancel</button>
            <button type="button" id = 'hidemodal' class="btn btn-secondary d-none" data-dismiss="modal"  (click)="onCloseModal()" >CloseModal</button>
        </div>
    </div>   
  </div>
</div>


<div class="modal fade" data-backdrop="static" data-keyboard="false" id="claimbatchdetailsModal" tabindex="-1"
    role="dialog" aria-labelledby="info" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header d-flex p-3">
                <h4 class="modal-title">Claim Details</h4>
                <em class="fa fa-times ml-auto" data-dismiss="modal" (click)="onCloseModelClick()"></em>
            </div>
            <div class="modal-body">
                <kendo-grid [data]="DetailProjectData" [height]="410" [loading]="loadingProjectGrid">
                    <kendo-grid-column field="dos" title="DOS" width="40">
                        <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                            <span class="mb-0">
                                {{ dataItem.dos | date: 'MM-dd-yyyy' }}
                            </span>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="claim" title="Claim#" width="40">
                        <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                            <div class="text-right">
                                <span class="mb-0">
                                    {{ dataItem.claim }}
                                </span>
                            </div>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="Insurance" title="Insurance" width="160">
                        <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                            <span class="mb-0">
                                {{ dataItem.Insurance }}
                            </span>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="Insuranceplan" title="Plan" width="160">
                        <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                            <span class="mb-0">
                                {{ dataItem.Insuranceplan }}
                            </span>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="Age" title="Age" width="40">
                        <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                            <div class="text-right">
                                <span class="mb-0">
                                    {{ dataItem.Age }}
                                </span>
                            </div>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="Balance" title="Total Due" width="40">
                        <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                            <div class="text-right">
                                <span class="mb-0 pointer">
                                    {{ dataItem.Balance | currency : "USD":true:"2.0" }}
                                </span>
                            </div>
                        </ng-template>                        
                    </kendo-grid-column>
                </kendo-grid>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="onCloseModelClick()" data-dismiss="modal">
                    No
                </button>
            </div>
        </div>
    </div>
</div>
<!--Create Project Model -->