<div class="loader-block" *ngIf="bIsLoader">
    <div class="loader-content text-center">
        <div class="loader-data">
            <img src="assets/img/Q-progressbar-blue.gif" alt="" class="mb-4" width="50" />
            <p>{{ LoaderLabel }}</p>
        </div>
    </div>
    </div>




        <div class="modal-content">
            <div class="modal-header">
            <h5 class="modal-title">Create Project</h5>
        
            <button type="button" class="close" aria-label="Close" data-dismiss="modal" (click)="onCancel(false)">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="SpecialClaimBatchCreateProjectGroup">
                    <div class="card bg-light">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-6">                          
                                    <label class="form-label mb-0">Claim Count :
                                        <!-- {{bIsLoaderClaimcount}} -->
                                        <span *ngIf = "bIsLoaderClaimcount && this.ClaimCount==0" class="spinner-border" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </span>
                                        <span *ngIf="!bIsLoaderClaimcount">{{ClaimCount}}</span>
                                    </label> 
                                </div>
                                <div class="col-md-6">                                                
                                    <label class="form-label mb-0">Total Balance :
                                        <!-- {{bIsLoaderBalance}} -->
                                        <span *ngIf = "bIsLoaderBalance && this.Balance==0" class="spinner-border" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </span>
                                        <span *ngIf="!bIsLoaderBalance">{{Balance | currency: "USD":true:"2.0"}}</span>
                                    </label> 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8">
                            <div class="form-group">
                                <label class="form-label">Project Name<span class="text-danger">*</span></label>
                                <kendo-textbox
                                    [disabled]="disabled"
                                    formControlName="fcSpecialclaimProjectName"
                                    [(ngModel)]="ProjectNamedisplay"
                                    type="text"
                                    id="ProjectName"
                                    class="form-control form-control-sm flex-fill"
                                    title="Enter Project Name"
                                    placeholder="Enter Project Name"                
                                ></kendo-textbox>  
                                <div *ngIf="submitted || (ProjectName.invalid && ProjectName.touched)">
                                    <small *ngIf="ProjectName.errors?.required" class="text-danger">Project Name is required</small>                            
                                </div>
                            </div>
                        </div> 
                        <div class="col-md-4">
                            <div class="form-group">
                                <label class="form-label">Project Type</label>                                
                                <kendo-dropdownlist  class="w-100"
                                formControlName="fcProjectType"
                                [data]="data"
                                [filterable]="true"
                                [textField]="'Projecttype'"
                                [valueField]="'Projecttypeid'"
                                (filterChange)="handleFilter($event)"
                                [(ngModel)]="Selected_ProjectType"
                                [loading]="bIsLoaderProjecttype"
                                >
                                <ng-template kendoDropDownListNoDataTemplate>
                                    <div>
                                    No data found.
                                    <ng-container *ngIf="filter"
                                        >Do you want to add new item - "{{ filter }}" ?</ng-container
                                    >
                                    <br />
                                    <button *ngIf="filter" class="k-button" (click)="addNew()">
                                        Add new item
                                    </button>
                                    </div>
                                </ng-template>
                                </kendo-dropdownlist>
                                <div *ngIf="submitted || (ProjectType.invalid && ProjectType.touched)">
                                    <small *ngIf="ProjectType.errors?.required" class="text-danger">Project Type is required</small>                            
                                </div>
                            </div>
                        </div>  
                        <div class="col-md-12">                    
                            <div class="form-group">
                                <label class="form-label">Description</label>
                                <textarea kendoTextArea [(ngModel)]="Descriptiontext" formControlName="fcSpecialclaimDescription" id="Description" class="w-100"></textarea>
                            </div>
                        </div>  
                        <!-- <div class="col-md-4">
                            <div class="form-group">
                                <label class="form-label">Owner<span class="text-danger">*</span></label>
                           
                            <kendo-combobox 
                            formControlName="fcSpecialclaimTo"
                            [data]="RCMUserlist" 
                            [textField]="'displayname'" 
                            [valueField]="'email'" 
                            [(ngModel)]="SelectedSendToUser"
                            (valueChange)="SelectSendToUserEmailSingle($event)" 
                            [placeholder]="'Search Owner'" class="w-100">
                            [loading]="bIsLoaderOwner"
                            </kendo-combobox>
                            <div *ngIf="submitted || (AssignedTo.invalid && AssignedTo.touched)" class="text-danger">
                                <small *ngIf="AssignedTo.errors?.required">Owner is required</small>
                            </div>
                            </div>
                        </div> -->
                        <div class="col-md-4">
                            <div class="form-group">
                                <label class="form-label">Owner<span class="text-danger">*</span></label>
                            <kendo-combobox 
                            formControlName="fcSpecialclaimTo"
                            [data]="OwnerListData" 
                            [textField]="'displayname'" 
                            [valueField]="'email'" 
                            [(ngModel)]="SelectedSendToUser"
                            (valueChange)="SelectSendToUserEmailSingle($event)" 
                            [placeholder]="'Search Owner'" class="w-100"
                            [loading]="bIsLoaderOwner">
                           
                            </kendo-combobox>
        
                         
                            <div *ngIf="submitted || (AssignedTo.invalid && AssignedTo.touched)" class="text-danger">
                                <small *ngIf="AssignedTo.errors?.required">Owner is required</small>
                            </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <label class="form-label">Expected Completion Date<span class="text-danger">*</span></label>
                            <kendo-datepicker class="w-100" [(value)]="EnddateValue" formControlName="fcSpecialclaimExpectedCompletionDate" [min]="min">
                            </kendo-datepicker>
                            <div *ngIf="submitted || (ExpectedCompletionDate.touched && ExpectedCompletionDate.invalid)">
                                <small *ngIf="ExpectedCompletionDate.errors?.required" class="text-danger">
                                    Expected Completion Date is required</small>
                            </div>
                        </div>                         
                        <div class="col-md-6"  [hidden]="true">                            
                            <div class="form-group">                                
                                <label class="form-label">Status</label>                        
                                <kendo-dropdownlist
                                [disabled] = "disableStatus"
                                formControlName="fcSpecialclaimStatus"
                                class="w-100"
                                id="ddStatus"
                                [(ngModel)]="Selected_Status"
                                [textField]="'text'"
                                [valueField]="'value'"
                                [data]="Status_List"
                                [valuePrimitive]="true"
                                ></kendo-dropdownlist> 
                            </div>
                        </div>     
                           
                        <div class="col-md-4">
                        <!-- {{Selected_Priority|json}} -->
                            <div class="form-group">
                                <label class="form-label">Priority</label>
                                <kendo-dropdownlist
                                formControlName="fcSpecialclaimPriority"
                                class="w-100"
                                id="ddPrority"
                                [(ngModel)]="Selected_Priority"
                                [textField]="'text'"
                                [valueField]="'value'"
                                [data]="Priority_List"
                                [valuePrimitive]="true"                                
                                ></kendo-dropdownlist>
                                <div *ngIf="submitted || (Priority.touched && Priority.invalid)">
                                    <small *ngIf="Priority.errors?.required" class="text-danger">
                                        Priority is required</small>
                                </div>
                            </div>                            
                        </div>
                        
                        <div class="col-md-6" [hidden]="true">
                            <div class="form-group">
                                <label class="form-label" [hidden]="true">Send To</label>
                                <input
                                    [hidden]="true"
                                    formControlName="fcSpecialclaimSendTo"
                                    type="text"
                                    class="w-100"
                                    [(ngModel)]="SelectedSendToUserEmailtxt"
                                    kendoTextBox
                                />
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <label class="form-label">Follower</label>
                                <kendo-multiselect
                                formControlName="fcSpecialclaimCC"
                                [data]="OwnerListData"                       
                                [placeholder]="'Select Follower'"
                                [textField]="'displayname'"
                                [valueField]="'email'"
                                [(ngModel)]="SelectedCCUser"
                                (valueChange)="SelectCCUserEmail($event)"
                                [loading]="bIsLoaderUser"
                            >
                            </kendo-multiselect>
                         
                            </div>
                        </div>
                        
                        <div class="col-md-6" [hidden]="true">
                            <div class="form-group">
                                <label class="form-label" [hidden]="true">CC</label>
                                <input
                                    [hidden]="true"
                                    formControlName="fcSpecialclaimCCSendTo"
                                    type="text"
                                    class="w-100"
                                    [(ngModel)]="SelectedCCUserEmailtxt"
                                    kendoTextBox
                                />
                            </div>
                        </div> 
                      
                    </div>
                </form>        
            </div>
            <div class="modal-footer py-2">      
              
                <button type="button" class="btn btn-success btn-sm mr-2 ppClose" data-dismiss="modal" [class.disabled]="this.ClaimCount==0" *ngIf="!bIsshowEditButton" (click)="onSave()">Save</button>
                <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal" (click)="onCancel(false)" >Cancel</button>
                <button type="button" id = 'hidemodal' class="btn btn-secondary d-none ppClose" data-dismiss="modal"  (click)="onCloseModal()" >CloseModal</button>
            </div>
        </div>
  
  
<div class="loader-block" *ngIf=" ">
<div class="loader-content text-center">
    <div class="loader-data">
        <img src="assets/img/Q-progressbar-blue.gif" alt="" class="mb-4" width="50" />
        <p>{{ LoaderLabel }}</p>
    </div>
</div>
</div>