import { Component, HostListener } from '@angular/core';
import { CommonService } from './services/common.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  public toggle: boolean = false;

  title = 'QInsight';
  loadsidebar=sessionStorage.getItem("loadsidebar");
  constructor(private commonService: CommonService) {

    // this.commonService.sidebarSub.next(true);
    this.commonService.sidebarSub.subscribe((toggle) => {
      this.toggle = toggle;
    })
  }


  @HostListener('document:mousemove', ['$event'])
  @HostListener('document:keyup', ['$event'])
  @HostListener('document:click', ['$event'])
  @HostListener('document:wheel', ['$event'])
  resetTimer() {
    this.commonService.notifyUserAction();
  }

  
  // sidepanel() {
  //   this.toggle = !this.toggle;
  // }
  
  
}
