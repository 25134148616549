import { DatePipe } from '@angular/common';
import { Component, Input, OnChanges, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CallserviceService } from 'src/app/CallServices/callservice.service';
import { SubSink } from 'subsink';
import { Utility } from "src/app/ModelFinancialMetrics/CommonService/utility";import { CreatedProjectsComponent } from '../../QAdmin/created-projects/created-projects.component';
import { DatatransaferService } from 'src/app/ModelFinancialMetrics/CommonService/datatransafer.service';
import { AuditLogService } from 'src/app/CallServices/audit-log.service';
import { TokenEncryptionService } from 'src/app/CallServices/token-encryption.service';
declare var $: any;

@Component({
  selector: "app-claimbatchcreateproject",
  templateUrl: "./claimbatchcreateproject.component.html",
  styleUrls: ["./claimbatchcreateproject.component.css"],
})
export class ClaimbatchcreateprojectComponent implements OnInit, OnChanges {
  constructor(
    private myProjects: CreatedProjectsComponent,
    private fb: UntypedFormBuilder,
    private toaster: ToastrService,
    private api: CallserviceService,
    private datePipe: DatePipe,
    private datatransfer: DatatransaferService,
    public logapi: AuditLogService,
    private tokenEncryptDecrypt:TokenEncryptionService
  ) {
    this.clsUtility = new Utility(toaster);
    this.pageSize = this.clsUtility.pagesize;
  }

  clsUtility: Utility;
  private subscription = new SubSink();
  private CurrentDataSet: any;
  private CurrentEnvironment: any;
  private CurrentUser: any;
  ClaimSelectionEdit: any;
  public min: Date = new Date(
    new DatePipe("en-US").transform(new Date(), "MM/dd/yyyy")
  );
  public pageSize = 0;
  ClaimBatchCreateProjectGroup = this.fb.group({
    fcProjectName: ["", [Validators.required, Validators.maxLength(150)]],
    fcStatus: [""],
    fcPriority: [""],
    fcTo: ["", Validators.required],
    fcSendTo: [""],
    fcCC: [""],
    fcCCSendTo: [""],
    fcOther: [""],
    fcDescription: [""],
    fcExpectedCompletionDate: ["", Validators.required],
  });

  get ProjectName() {
    return this.ClaimBatchCreateProjectGroup.get("fcProjectName");
  }
  get Status() {
    return this.ClaimBatchCreateProjectGroup.get("fcStatus");
  }
  get Priority() {
    return this.ClaimBatchCreateProjectGroup.get("fcPriority");
  }
  get AssignedTo() {
    return this.ClaimBatchCreateProjectGroup.get("fcTo");
  }
  get SendToEmail() {
    return this.ClaimBatchCreateProjectGroup.get("fcSendTo");
  }
  get CC() {
    return this.ClaimBatchCreateProjectGroup.get("fcCC");
  }
  get CCEmail() {
    return this.ClaimBatchCreateProjectGroup.get("fcCCSendTo");
  }
  get Description() {
    return this.ClaimBatchCreateProjectGroup.get("fcDescription");
  }
  get ExpectedCompletionDate() {
    return this.ClaimBatchCreateProjectGroup.get("fcExpectedCompletionDate");
  }

  public Priority_List = [
    { text: "High", value: "High" },
    { text: "Medium", value: "Medium" },
    { text: "Low", value: "Low" },
  ];

  public Status_List = [
    { text: "Open", value: "Open" },
    { text: "Close", value: "Close" },
    { text: "In-process", value: "In-process" },
  ];

  Selected_Priority: any;
  Selected_Status: any;
  Userlist: any;
  SelectedSendToUser: any;
  SelectedSendToUserEmailtxt: any;
  SelectedSendToUserEmail: any;
  SelectedCCUser: any;
  SelectedCCUserEmailtxt: any;
  SelectedCCUserEmail: any;
  SaveResult: any = [];
  selectedClientkey: any;
  public practicelist: any = [];
  practicename: any;
  public SelectedClaims: any;

  public ClaimCount: number = 0;
  public Balance: number = 0;
  public ProjectNamedisplay: any;
  public Descriptiontext: any;
  public bIsLoader: any;
  public SelectedExternalUserEmailtxt: any;
  public submitted = false;
  public disabled: boolean = false;
  public bIsshowEditButton: boolean = false;

  d = new Date();
  public EnddateValue: Date = new Date(
    this.d.getFullYear(),
    this.d.getMonth(),
    this.d.getDate() - 1,
    0,
    0,
    0
  );

  public loadingClaimDetailsGrid = false;
  public ClaimData: any;
  public mySelection: number[] = [];
  public CreateProject: any;
  public disableStatus: boolean = false;
  public LoaderLabel: any;
  public ProjectDetails: any;
  // Received Input from parent component
  @Input() InputClaimBatch: any;

  // Send Output to parent component
  @Output() OutputClaimBatchResult = new EventEmitter<boolean>();

  sampleProducts = [
    {
      ProductID: 1,
      ProductName: "Chai",
      SupplierID: 1,
      CategoryID: 1,
      QuantityPerUnit: "10 boxes x 20 bags",
      UnitPrice: 18,
      UnitsInStock: 39,
      UnitsOnOrder: 0,
      ReorderLevel: 10,
      Discontinued: false,
      Category: {
        CategoryID: 1,
        CategoryName: "Beverages",
        Description: "Soft drinks, coffees, teas, beers, and ales",
      },
      FirstOrderedOn: new Date(1996, 8, 20),
    },
    {
      ProductID: 2,
      ProductName: "Chang",
      SupplierID: 1,
      CategoryID: 1,
      QuantityPerUnit: "24 - 12 oz bottles",
      UnitPrice: 19,
      UnitsInStock: 17,
      UnitsOnOrder: 40,
      ReorderLevel: 25,
      Discontinued: false,
      Category: {
        CategoryID: 1,
        CategoryName: "Beverages",
        Description: "Soft drinks, coffees, teas, beers, and ales",
      },
      FirstOrderedOn: new Date(1996, 7, 12),
    },
    {
      ProductID: 3,
      ProductName: "Aniseed Syrup",
      SupplierID: 1,
      CategoryID: 2,
      QuantityPerUnit: "12 - 550 ml bottles",
      UnitPrice: 10,
      UnitsInStock: 13,
      UnitsOnOrder: 70,
      ReorderLevel: 25,
      Discontinued: false,
      Category: {
        CategoryID: 2,
        CategoryName: "Condiments",
        Description:
          "Sweet and savory sauces, relishes, spreads, and seasonings",
      },
      FirstOrderedOn: new Date(1996, 8, 26),
    },
    {
      ProductID: 4,
      ProductName: "Chef Anton's Cajun Seasoning",
      SupplierID: 2,
      CategoryID: 2,
      QuantityPerUnit: "48 - 6 oz jars",
      UnitPrice: 22,
      UnitsInStock: 53,
      UnitsOnOrder: 0,
      ReorderLevel: 0,
      Discontinued: false,
      Category: {
        CategoryID: 2,
        CategoryName: "Condiments",
        Description:
          "Sweet and savory sauces, relishes, spreads, and seasonings",
      },
      FirstOrderedOn: new Date(1996, 9, 19),
    },
    {
      ProductID: 5,
      ProductName: "Chef Anton's Gumbo Mix",
      SupplierID: 2,
      CategoryID: 2,
      QuantityPerUnit: "36 boxes",
      UnitPrice: 21.35,
      UnitsInStock: 0,
      UnitsOnOrder: 0,
      ReorderLevel: 0,
      Discontinued: true,
      Category: {
        CategoryID: 2,
        CategoryName: "Condiments",
        Description:
          "Sweet and savory sauces, relishes, spreads, and seasonings",
      },
      FirstOrderedOn: new Date(1996, 7, 17),
    },
    {
      ProductID: 6,
      ProductName: "Grandma's Boysenberry Spread",
      SupplierID: 3,
      CategoryID: 2,
      QuantityPerUnit: "12 - 8 oz jars",
      UnitPrice: 25,
      UnitsInStock: 120,
      UnitsOnOrder: 0,
      ReorderLevel: 25,
      Discontinued: false,
      Category: {
        CategoryID: 2,
        CategoryName: "Condiments",
        Description:
          "Sweet and savory sauces, relishes, spreads, and seasonings",
      },
      FirstOrderedOn: new Date(1996, 9, 19),
    },
    {
      ProductID: 7,
      ProductName: "Uncle Bob's Organic Dried Pears",
      SupplierID: 3,
      CategoryID: 7,
      QuantityPerUnit: "12 - 1 lb pkgs.",
      UnitPrice: 30,
      UnitsInStock: 15,
      UnitsOnOrder: 0,
      ReorderLevel: 10,
      Discontinued: false,
      Category: {
        CategoryID: 7,
        CategoryName: "Produce",
        Description: "Dried fruit and bean curd",
      },
      FirstOrderedOn: new Date(1996, 7, 22),
    },
    {
      ProductID: 8,
      ProductName: "Northwoods Cranberry Sauce",
      SupplierID: 3,
      CategoryID: 2,
      QuantityPerUnit: "12 - 12 oz jars",
      UnitPrice: 40,
      UnitsInStock: 6,
      UnitsOnOrder: 0,
      ReorderLevel: 0,
      Discontinued: false,
      Category: {
        CategoryID: 2,
        CategoryName: "Condiments",
        Description:
          "Sweet and savory sauces, relishes, spreads, and seasonings",
      },
      FirstOrderedOn: new Date(1996, 11, 1),
    },
    {
      ProductID: 9,
      ProductName: "Mishi Kobe Niku",
      SupplierID: 4,
      CategoryID: 6,
      QuantityPerUnit: "18 - 500 g pkgs.",
      UnitPrice: 97,
      UnitsInStock: 29,
      UnitsOnOrder: 0,
      ReorderLevel: 0,
      Discontinued: true,
      Category: {
        CategoryID: 6,
        CategoryName: "Meat/Poultry",
        Description: "Prepared meats",
      },
      FirstOrderedOn: new Date(1997, 1, 21),
    },
    {
      ProductID: 10,
      ProductName: "Ikura",
      SupplierID: 4,
      CategoryID: 8,
      QuantityPerUnit: "12 - 200 ml jars",
      UnitPrice: 31,
      UnitsInStock: 31,
      UnitsOnOrder: 0,
      ReorderLevel: 0,
      Discontinued: false,
      Category: {
        CategoryID: 8,
        CategoryName: "Seafood",
        Description: "Seaweed and fish",
      },
      FirstOrderedOn: new Date(1996, 8, 5),
    },
  ];

  OutputpayerEditResult(data: any) {
    let outPayerEditResult = data;
    this.OutputClaimBatchResult.emit(outPayerEditResult);
    this.ResetComponents();
  }

  ngOnInit() {
    try {
      // this.ResetComponents();      
      this.ClaimCount = 0;
      this.Balance = 0;
      this.submitted = false;
      if (!isNullOrUndefined(this.InputClaimBatch)) {
        this.ClaimCount = this.InputClaimBatch.Claimcount;
        this.Balance = this.InputClaimBatch.Balance;
      }

      this.CurrentEnvironment = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("currentEnvironment"));
      this.CurrentDataSet = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("currentDataset"))  //Decryption sessionStorage.getItem("currentDataset");
      this.CurrentUser = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("fname"));

      // this.Selected_Priority = "High";
      // this.Selected_Status = "Open";
      // Set default status
      let status_index = this.Status_List.findIndex(
        (x) => x.text == 'Open'
      );
      if (status_index >= 0) {
        this.Selected_Status = this.Status_List[status_index].text;
      }
      //Set default priority
      let priority_index = this.Priority_List.findIndex(
        (x) => x.text == 'High'
      );
      if (priority_index >= 0) {
        this.Selected_Priority = this.Priority_List[priority_index].text;
      }

      if (sessionStorage.getItem("ListofPractice")) {
        this.practicelist =  JSON.parse(this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("ListofPractice"))); //Decryption 
        this.practicelist.sort((a, b) =>
          a.groupname.localeCompare(b.groupname)
        );
        this.selectedClientkey = this.practicelist[0].groupcode;
        this.practicename = this.practicelist[0].groupname;
      }

      this.GetUserList();
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }

  ngOnChanges() {
    try {
      // this.ResetComponents();
      this.submitted = false;
      this.LoaderLabel = "";
      this.ClaimCount = 0;
      this.Balance = 0;
      this.ProjectNamedisplay = "";
      // Get environment, dataset, loginusername
      this.CurrentEnvironment = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("currentEnvironment"));
      this.CurrentDataSet = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("currentDataset"))  //Decryption sessionStorage.getItem("currentDataset");
      this.CurrentUser = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("fname"));

      if (!isNullOrUndefined(this.InputClaimBatch)) {
        this.ClaimCount = this.InputClaimBatch.Claimcount;
        this.Balance = this.InputClaimBatch.Balance;
      }
      if (
        sessionStorage.getItem("ClaimBatchSelection")
      ) {
        this.ClaimSelectionEdit = JSON.parse(sessionStorage.getItem("ClaimBatchSelection"));
        if (this.ClaimSelectionEdit != undefined) {
          if (this.ClaimSelectionEdit.actionType == "Edit") {
            this.bIsLoader = true;
            this.getProjectData();
            this.disableStatus = false;
            this.bIsshowEditButton = true;
            this.disabled = true;
          } else {
            this.disableStatus = true;
            this.bIsshowEditButton = false;
            this.disabled = false;
          }
        } else {
          this.bIsshowEditButton = false;
          this.disabled = false;
        }
      }
      this.CurrentEnvironment = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("currentEnvironment"));
      this.CurrentDataSet = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("currentDataset"))  //Decryption sessionStorage.getItem("currentDataset");
      this.CurrentUser = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("fname"));

      // this.Selected_Priority = "High";
      // this.Selected_Status = "Open";
      // Set default status
      let status_index = this.Status_List.findIndex(
        (x) => x.text == 'Open'
      );
      if (status_index >= 0) {
        this.Selected_Status = this.Status_List[status_index].text;
      }
      //Set default priority
      let priority_index = this.Priority_List.findIndex(
        (x) => x.text == 'High'
      );
      if (priority_index >= 0) {
        this.Selected_Priority = this.Priority_List[priority_index].text;
      }

      if (sessionStorage.getItem("ListofPractice")) {
        this.practicelist =  JSON.parse(this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("ListofPractice"))); //Decryption  //this.practicelist = JSON.parse(sessionStorage.getItem("ListofPractice"));
        this.practicelist.sort((a, b) =>
          a.groupname.localeCompare(b.groupname)
        );
        this.selectedClientkey = this.practicelist[0].groupcode;
        this.practicename = this.practicelist[0].groupname;
      }

      this.GetUserList();
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }

  SelectSendToUserEmail(user: any) {
    try {
      this.SelectedSendToUserEmail = [];
      this.SelectedSendToUserEmailtxt = "";
      if (user != null && user != undefined) {
        for (let i = 0; i < user.length; i++) {
          if (i == user.length - 1) {
            this.SelectedSendToUserEmailtxt =
              this.SelectedSendToUserEmailtxt + user[i]["email"];
          } else {
            this.SelectedSendToUserEmailtxt =
              this.SelectedSendToUserEmailtxt + user[i]["email"] + ";";
          }
        }
      }
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }

  SelectSendToUserEmailSingle(user: any) {
    try {
      this.SelectedSendToUserEmail = [];
      this.SelectedSendToUserEmailtxt = "";
      if (user != null && user != undefined) {
        this.SelectedSendToUserEmailtxt =
          this.SelectedSendToUserEmailtxt + user["email"];
      }
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }

  SelectCCUserEmail(user: any) {
    try {
      this.SelectedCCUserEmail = [];
      this.SelectedCCUserEmailtxt = "";
      if (user != null && user != undefined) {
        for (let i = 0; i < user.length; i++) {
          if (i == user.length - 1) {
            this.SelectedCCUserEmailtxt =
              this.SelectedCCUserEmailtxt + user[i]["email"];
          } else {
            this.SelectedCCUserEmailtxt =
              this.SelectedCCUserEmailtxt + user[i]["email"] + ";";
          }
        }
      }
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }

  GetUserList() {
    try {
      this.SelectedSendToUser = [];
      this.SelectedSendToUserEmailtxt = "";
      this.SelectedCCUser = [];
      this.SelectedCCUserEmailtxt = "";
      this.Userlist = [];

      const seq = this.api.getUser().share();
      seq
        .map((res) => res.json())
        .subscribe((res) => {
          this.Userlist = res;
        });
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }

  validateClaimBatch() {
    try {
      if (
        this.ProjectName.valid &&
        this.AssignedTo.valid &&
        // this.CC.valid &&
        this.ExpectedCompletionDate.valid &&
        !this.clsUtility.CheckEmptyString(this.ProjectName.value)
      ) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }

  onSave() {
    try {
      // $("#arCreateProjectModal").modal("hide");
      this.submitted = true;
      this.LoaderLabel = "Saving Details";
      if (this.validateClaimBatch()) {
        let CreateProjectFromDashboard;
        this.datatransfer.ClaimBatchCreateProject.subscribe(data => {
          CreateProjectFromDashboard = data;
        });

        if (CreateProjectFromDashboard == 'Claim-at-risk') {

          this.Save_ClaimAtRisk();
        }
        else if (CreateProjectFromDashboard == 'AR') {
          // this.SaveDynamically();
          this.Save_AR();
        }
        if (CreateProjectFromDashboard == 'Claim-not-worked-upon') {
          this.Save_Claimnotwork();
        }
      }
    } catch (error) {
      this.OutputpayerEditResult(false);
      this.clsUtility.LogError(error);
    }
  }

  onEdit() {
    try {
      this.bIsLoader = true;
      this.LoaderLabel = "Updating Details";
      const datepipe = new DatePipe("en-US");
      const currentDate = datepipe.transform(
        Date.now(),
        "MM-dd-yyyy"
      );
      let userid = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("userid"));
      let AssignedSendToName: any = [];
      AssignedSendToName.push(this.AssignedTo.value.displayname);

      let AssignedCCName: any = [];
      for (let i = 0; i < this.CC.value.length; i++) {
        if (!isNullOrUndefined(AssignedCCName)) {
          AssignedCCName.push(this.CC.value[i].displayname);
        }
      }

      let input_claimbatch: {
        ProjectId: any;
        envname: any;
        DatasetName: any;
        PracticeName: any;
        ProjectName: any;
        Status: any;
        Priority: any;
        AssignedToName: any;
        AssignedToEmail: any;
        AssignedCCName: any;
        AssignedCCEmail: any;
        Description: any;
        ExpectedCompletionDate: any;
        ExternalUserEmail: any;
        ClaimCount: any;
        Balance: any;
        CreatedOn: any;
        CreatedBy: any;
        AssigneeName: any;
        AssignedOn: any;
        AssignedToUserId: any;
        CreatedByUserid: any;
        Projecttype: any;
        DML: any;
      } = {
        ProjectId: this.ClaimSelectionEdit.SelectedProjectId,
        envname: this.CurrentEnvironment,
        DatasetName: this.selectedClientkey,
        PracticeName: "",
        ProjectName: this.ProjectNamedisplay,
        Status: !isNullOrUndefined(this.Status.value) ? this.Status.value : '',
        Priority: !isNullOrUndefined(this.Selected_Priority) ? this.Selected_Priority : '',
        AssignedToName: AssignedSendToName.toString(),
        AssignedToEmail: this.SendToEmail.value,
        AssignedCCName: AssignedCCName.toString(),
        AssignedCCEmail: this.CCEmail.value,
        Description: this.Descriptiontext,
        ExpectedCompletionDate: this.datePipe.transform(this.EnddateValue, 'yyyy-MM-dd').toString(),
        ExternalUserEmail: !isNullOrUndefined(this.SelectedExternalUserEmailtxt) ? this.SelectedExternalUserEmailtxt : '',
        ClaimCount: this.ClaimCount.toString(),
        Balance: this.Balance.toString(),
        CreatedOn: this.datePipe.transform(currentDate, 'yyyy-MM-ddTHH:mm:ss.SSS').toString(),
        CreatedBy: this.CurrentUser,
        AssigneeName: this.CurrentUser,
        AssignedOn: this.datePipe.transform(currentDate, 'yyyy-MM-ddTHH:mm:ss.SSS').toString(),
        AssignedToUserId: this.AssignedTo.value.userid,
        CreatedByUserid: userid,
        Projecttype: this.ProjectDetails[0]["Projecttype"],
        DML: "Update",
      };

      let querystr = "call " + this.CurrentDataSet + ".SP_workqueuemasterdetails('" + this.CurrentDataSet + "','','" + JSON.stringify(input_claimbatch) + "','savedata')";
      let param: { querystring: string } = { querystring: querystr };
      this.api.getFunctionName("DynamicCallsResult ");
      const metricsdata = this.api.BindDataRCM(param).share();
      metricsdata
        .map((res) => res.json())
        .subscribe(
          (res) => {
            sessionStorage.removeItem("ClaimBatchSelection");
            if (!isNullOrUndefined(res)) {
              // if (res[0]["f0_"] == 'Record inserted successfully') 
              if (res[0]["ProjectId"] != '' && res[0]["ProjectId"].length > 0) {
                this.clsUtility.showSuccess('Record updated successfully');
                this.logapi.insertActivityLog(
                  this.ProjectNamedisplay + " project updated successfully",
                  "Create Project",
                  "Read"
                );
                this.bIsLoader = false;
                document.getElementById('hidemodal').click();
              }
              else {
                this.bIsLoader = false;
              }
            }
            // this.myProjects.getScoreCardData();
            // this.myProjects.getProjectsData();
            // this.bIsLoader = false;

          },
          (err) => {
            this.bIsLoader = false;
            this.clsUtility.showWarning(err);
            this.logapi.insertActivityLog(
              this.ProjectNamedisplay + " error while updating project",
              "Create Project",
              "Read"
            );
          }
        );
    } catch (error) {
      this.bIsLoader = false;
      this.clsUtility.LogError(error);
    }

  }

  onCancel() {
    try {
      this.ProjectDetails = undefined;
      this.ClaimSelectionEdit = undefined;
      sessionStorage.removeItem("ClaimBatchSelection");
      this.OutputpayerEditResult(false);
      // $("#arCreateProjectModal").modal("hide");
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }

  SendEmail() {
    try {
      let assignParam: {
        clientkey: string;
        clinicname: string;
        assignto: string;
        email: string;
        claimno: string;
        note: string;
      } = {
        clientkey: "",
        clinicname: "",
        assignto: "",
        email: "",
        claimno: "",
        note: "",
      };
      const assignto = "example text";
      const email = this.SendToEmail.value + ";" + this.CCEmail.value;
      var Text_Description;
      if (this.Description != undefined) {
        Text_Description = this.Description.value;
      } else {
        Text_Description = "-";
      }

      const valid = this.ValidateEmail(Text_Description);
      if (valid == true) {
        assignParam.clientkey = this.selectedClientkey;
        assignParam.clinicname = this.practicename;
        assignParam.claimno = String(1);
        assignParam.assignto = String(assignto);
        assignParam.email = String(email);

        for (let i = 0; i < this.SelectedSendToUser.length; i++) {
          if (this.SelectedSendToUser != undefined) {
            if (i == this.SelectedSendToUser.length - 1) {
              assignParam.note = String(
                Text_Description + ", task added in Qonductor"
              );
              this.api.getFunctionName("workqueuemail");
              const practiceftr = this.api.BindDataRCM(assignParam).share();
              practiceftr
                .map((res) => res.json())
                .subscribe((res) => {
                  if (res.success) {
                    // alert("Task Assigned");
                  }
                });
              this.clsUtility.showSuccess(
                "Assign Task to Qondutor user Sucessfully"
              );
            }
          } else {
            this.clsUtility.showWarning("Please Select User to assigne claim");
          }
        }
      }
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }

  ValidateEmail(Description) {
    try {
      let isempty;
      if (Description.length == 0) {
        isempty = true;
      } else {
        isempty = false;
      }
      if (isempty == false) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }

  ResetComponents() {
    try {
      this.submitted = false;
      // this.ClaimBatchCreateProjectGroup.reset();
      this.ClaimCount = 0;
      this.Balance = 0;
      // this.Selected_Priority = "High";
      // this.Selected_Status = "Open";
      // Set default status
      let status_index = this.Status_List.findIndex(
        (x) => x.text == 'Open'
      );
      if (status_index >= 0) {
        this.Selected_Status = this.Status_List[status_index].text;
      }
      //Set default priority
      let priority_index = this.Priority_List.findIndex(
        (x) => x.text == 'High'
      );
      if (priority_index >= 0) {
        this.Selected_Priority = this.Priority_List[priority_index].text;
      }
      this.SelectedSendToUser = [];
      this.SelectedSendToUserEmailtxt = '';
      this.SelectedCCUser = [];
      this.SelectedCCUserEmailtxt = '';
      this.subscription.unsubscribe();
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }
  getClaimData() {
    try {
      this.loadingClaimDetailsGrid = true;
      this.ClaimData = [];
      // let querystr = "call qinsights." + this.CurrentDataSet + ".SP_AR('ClaimWiseDetail', '" + this.selectedClientkey + "', '2021-01-21', '', '', 0, 'DOS')";
      let querystr =
        "call" +
        this.CurrentDataSet +
        ".SP_AR('ClaimWiseDetail', '" +
        this.selectedClientkey +
        "', '2021-01-21', '', '', 0, 'DOS','','','','','','','',0)";
      // ('ClaimWiseDetail', 'GCPBQDEMO1', '2021-01-21', '', '', 0, 'DOS','','','','','','','',0)
      let param: { querystring: string } = { querystring: querystr };
      this.api.getFunctionName("DynamicCallsResult ");
      const metricsdata = this.api.BindDataRCM(param).share();
      metricsdata
        .map((res) => res.json())
        .subscribe(
          (res) => {
            if (res.length > 0) {
              this.ClaimData = res;
            }
            this.loadingClaimDetailsGrid = false;
          },
          (err) => {
            this.clsUtility.showWarning(err);
            this.loadingClaimDetailsGrid = false;
          }
        );
    } catch (error) {
      this.clsUtility.LogError(error);
      this.loadingClaimDetailsGrid = false;
    }
  }

  dateConvert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    //return [mnth, day, date.getFullYear()].join("/"); // mm-dd-yyyy
    return [date.getFullYear(), mnth, day].join("-"); //yyyy-mm-dd
  }

  Save_SendEmail() {
    try {
      const datepipe = new DatePipe("en-US");
      const currentDate = datepipe.transform(Date.now(), "MM-dd-yyyy");

      let ClaimSelection: any = sessionStorage.getItem("ClaimBatchSelection");
      // console.log("ClaimSelection Before: ", ClaimSelection);
      let Claim = JSON.parse(ClaimSelection);
      // console.log("ClaimSelection After: ", Claim.ClaimSelection);

      // let AssignedSendToName: any = [];
      // for (let i = 0; i < this.AssignedTo.value.length; i++) {
      //   if (!isNullOrUndefined(AssignedSendToName)) {
      //     AssignedSendToName.push(this.AssignedTo.value[i].displayname);
      //   }
      // }

      let AssignedSendToName: any = [];
      AssignedSendToName.push(this.AssignedTo.value.displayname);

      let AssignedCCName: any = [];
      for (let i = 0; i < this.CC.value.length; i++) {
        if (!isNullOrUndefined(AssignedCCName)) {
          AssignedCCName.push(this.CC.value[i].displayname);
        }
      }

      this.datatransfer.Obj_CreateProject.subscribe(data => {
        this.CreateProject = data;
      });

      let input_claimbatch: {
        envname: any;
        DatasetName: any;
        PracticeName: any;
        ProjectName: any;
        Status: any;
        Priority: any;
        AssignedToName: any;
        AssignedToEmail: any;
        AssignedCCName: any;
        AssignedCCEmail: any;
        Description: any;
        ExpectedCompletionDate: any;
        ExternalUserEmail: any;
        ClaimCount: any;
        Balance: any;
        CreatedOn: any;
        CreatedBy: any;
        AssigneeName: any;
        AssignedOn: any;
        AssignedToUserId: any;
        DML: any;
      } = {
        envname: this.CurrentEnvironment,
        DatasetName: this.CreateProject.Clientkey,
        PracticeName: this.CreateProject.PracticeName,
        ProjectName: this.ProjectName.value,
        Status: this.Status.value,
        Priority: this.Priority.value,
        AssignedToName: AssignedSendToName.toString(),
        AssignedToEmail: !isNullOrUndefined(this.SendToEmail.value) ? this.SendToEmail.value : '',
        AssignedCCName: AssignedCCName.toString(),
        AssignedCCEmail: !isNullOrUndefined(this.CCEmail.value) ? this.CCEmail.value : '',
        //Description: this.Description.value,
        Description: !isNullOrUndefined(this.Description.value) ? this.Description.value : '',
        ExpectedCompletionDate: this.datePipe
          .transform(this.EnddateValue, "yyyy-MM-dd")
          .toString(),
        ExternalUserEmail: !isNullOrUndefined(this.SelectedExternalUserEmailtxt)
          ? this.SelectedExternalUserEmailtxt
          : "",
        ClaimCount: this.ClaimCount.toString(),
        Balance: this.Balance.toString(),
        CreatedOn: this.datePipe
          .transform(currentDate, "yyyy-MM-ddTHH:mm:ss.SSS")
          .toString(),
        CreatedBy: this.CurrentUser,
        AssigneeName: this.CurrentUser,
        AssignedOn: this.datePipe
          .transform(currentDate, "yyyy-MM-ddTHH:mm:ss.SSS")
          .toString(),
        AssignedToUserId: this.AssignedTo.value.userid,
        DML: "Insert",
      };

      let input_data: {
        data: any;
      } = {
        data: input_claimbatch,
      };

      this.api.getFunctionName("workqueuemail");
      let seq = this.api.BindDataRCM(input_data).share();
      this.subscription.add(
        seq.subscribe(
          (response) => {
            if (response != null && response != undefined) {
              // console.log("response : ", response);
              let result: any = response;
              if (result != []) {
                let res: any = result._body;
                if (res == "Email Send and Data Insert Successfully Done") {
                  // this.clsUtility.showSuccess("Data saved successfully");
                } else if (res == "Unsuccessfull Email Send") {
                  this.clsUtility.showError("Email not sent");
                }
              } else {
                this.clsUtility.showError("Email not sent");
              }
            } else {
              this.clsUtility.showError("Email not sent");
            }
            this.OutputpayerEditResult(true);
          },
          (err) => {
            this.clsUtility.LogError(err);
            this.OutputpayerEditResult(false);
          }
        )
      );
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }

  getProjectData() {
    try {
      this.bIsLoader = true;
      this.disabled = true;
      this.Selected_Status = '';
      this.Selected_Priority = '';
      this.ClaimCount = 0;
      this.Balance = 0;

      let querystr =
        "call " +
        this.CurrentDataSet +
        ".sp_projectdetails('','" +
        this.CurrentDataSet +
        "','','','','','ProjectWiseFilter','" +
        this.ClaimSelectionEdit.SelectedProjectId +
        "','')";

      let param: { querystring: string } = { querystring: querystr };
      this.api.getFunctionName("DynamicCallsResult ");
      const metricsdata = this.api.BindDataRCM(param).share();
      metricsdata
        .map((res) => res.json())
        .subscribe(
          (res) => {
            if (res.length > 0) {
              this.ProjectDetails = res;
              this.ClaimCount = res[0].ClaimCount;
              this.Balance = res[0].Balance;
              this.ProjectNamedisplay = res[0].ProjectName;
              this.EnddateValue = new Date(res[0].ExpectedCompletionDate);
              // status
              let gindex = this.Status_List.findIndex(
                (x) => x.text == res[0].Status
              );
              if (gindex >= 0) {
                this.Selected_Status = this.Status_List[gindex].text;
              }
              //Priority
              let pindex = this.Priority_List.findIndex(
                (x) => x.text == res[0].Priority
              );
              if (pindex >= 0) {
                this.Selected_Priority = this.Priority_List[pindex].text;
              }
              //Owner
              let oindex = this.Userlist.findIndex(
                (x) => x.displayname == res[0].AssignedToName
              );
              if (oindex >= 0) {
                this.SelectedSendToUser = this.Userlist[oindex];
                this.SelectedSendToUserEmailtxt = this.Userlist[oindex].email;
              }

              //follower
              this.SelectedCCUserEmailtxt = "";
              let ccemails = res[0].AssignedCCEmail.split(",");
              for (let i = 0; i < ccemails.length; i++) {
                let findex = this.Userlist.findIndex(
                  (x) => x.email == ccemails[i]
                );
                if (findex >= 0) {
                  this.SelectedCCUser.push(this.Userlist[findex]);
                  this.SelectedCCUserEmailtxt =
                    this.SelectedCCUserEmailtxt +
                    this.Userlist[findex].email +
                    ",";
                }
              }
              this.Descriptiontext = res[0].Description;
            }
            this.bIsLoader = false;
          },
          (err) => {
            this.bIsLoader = false;
            this.clsUtility.showWarning(err);
          }
        );
    } catch (error) {
      this.bIsLoader = false;
      this.clsUtility.LogError(error);
    }
  }

  Save_ClaimAtRisk() {
    try {
      this.bIsLoader = true;
      /*  let CurrentUser:any= JSON.parse(this.tokenEncryptDecrypt.decryptToken(localStorage.getItem("currentUser")));
       let ClientIP = CurrentUser.visitorip;
       let ClientBrowser = CurrentUser.visitorbrowser;
       let UserName = CurrentUser.userFullName; */
      let userid = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("userid"));
      let userEmail = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("username"))  //Decryption  //sessionStorage.getItem("username");
      const datepipe = new DatePipe("en-US");
      const currentDate = datepipe.transform(Date.now(), "MM-dd-yyyy");

      let AssignedSendToName: any = [];
      AssignedSendToName.push(this.AssignedTo.value.displayname);

      let AssignedCCName: any = [];
      for (let i = 0; i < this.CC.value.length; i++) {
        if (!isNullOrUndefined(AssignedCCName)) {
          AssignedCCName.push(this.CC.value[i].displayname);
        }
      }

      this.datatransfer.Obj_CreateProject.subscribe(data => {
        this.CreateProject = data;
      });

      let input_claimbatch: {
        envname: any;
        DatasetName: any;
        PracticeName: any;
        ProjectName: any;
        Status: any;
        Priority: any;
        AssignedToName: any;
        AssignedToEmail: any;
        AssignedCCName: any;
        AssignedCCEmail: any;
        Description: any;
        ExpectedCompletionDate: any;
        ExternalUserEmail: any;
        ClaimCount: any;
        Balance: any;
        CreatedOn: any;
        CreatedBy: any;
        AssigneeName: any;
        AssignedOn: any;
        AssignedToUserId: any;
        CreatedByUserid: any;
        Projecttype: any;
        DML: any;
      } = {
        envname: this.CurrentEnvironment,
        DatasetName: this.CreateProject.Clientkey,
        PracticeName: this.CreateProject.PracticeName,
        ProjectName: this.ProjectName.value,
        Status: this.Status.value,
        Priority: this.Priority.value,
        AssignedToName: AssignedSendToName.toString(),
        AssignedToEmail: this.SendToEmail.value,
        AssignedCCName: AssignedCCName.toString(),
        AssignedCCEmail: this.CCEmail.value,
        Description: !isNullOrUndefined(this.Description.value) ? this.Description.value : '',
        ExpectedCompletionDate: this.datePipe
          .transform(this.EnddateValue, "yyyy-MM-dd")
          .toString(),
        ExternalUserEmail: !isNullOrUndefined(this.SelectedExternalUserEmailtxt)
          ? this.SelectedExternalUserEmailtxt
          : "",
        ClaimCount: this.ClaimCount.toString(),
        Balance: this.Balance.toString(),
        CreatedOn: this.datePipe
          .transform(currentDate, "yyyy-MM-ddTHH:mm:ss.SSS")
          .toString(),
        CreatedBy: userEmail,
        AssigneeName: this.CurrentUser,
        AssignedOn: this.datePipe
          .transform(currentDate, "yyyy-MM-ddTHH:mm:ss.SSS")
          .toString(),
        AssignedToUserId: this.AssignedTo.value.userid,
        CreatedByUserid: userid,
        Projecttype: this.CreateProject.Projecttype,
        DML: "Insert",
      };



      let claimselection = this.CreateProject.ClaimSelection;
      if (!isNullOrUndefined(claimselection)) {
        claimselection.result = 'savedata';
        claimselection.data = input_claimbatch;
      }

      let querystr = "call " + this.CurrentDataSet + ".SP_ClaimAtRisk('" + this.CreateProject.Clientkey + "','" + this.dateConvert(this.CreateProject.StartDate) + "'," +
        this.CreateProject.MinDays + "," + this.CreateProject.MaxDays + ",'" + JSON.stringify(this.CreateProject.ClaimSelection) + "','" + this.CreateProject.Filtertype + "','" + this.CreateProject.Filtername + "');";

      let param: {
        querystring: string,
        /*   loginuser:any,
          clientip:any,
          clientbrowser, */
      }
        = {
        querystring: querystr,
         /*  loginuser:UserName,
             clientip:ClientIP,
             clientbrowser:ClientBrowser */ };
      this.api.getFunctionName("DynamicCallsResult ");
      const metricsdata = this.api.BindDataRCM(param).share();
      metricsdata
        .map((res) => res.json())
        .subscribe(
          (res) => {
            if (res.length > 0) {
              if (!isNullOrUndefined(res)) {
                if (res[0]["ProjectId"] != '' && res[0]["ProjectId"].length > 0) {
                  // console.log("getClaimCountData : ", res);              
                  this.clsUtility.showSuccess("Data saved successfully");
                  this.logapi.insertActivityLog(
                    "Claim at risk : " + this.ProjectName.value + " project created successfully",
                    "Create Project",
                    "Create"
                  );
                  this.Save_SendEmail();
                } else {
                  this.clsUtility.showError("Error while saving");
                }
              }
            }
            this.bIsLoader = false;
            document.getElementById('hidemodal').click();
          },
          (err) => {
            this.clsUtility.showWarning(err);
            this.bIsLoader = false;
            this.logapi.insertActivityLog(
              "Claim at risk : " + this.ProjectName.value + " error while creating project",
              "Create Project",
              "Create"
            );
          }
        );
    } catch (error) {
      this.bIsLoader = false;
      this.clsUtility.LogError(error);
    }
  }

  Save_AR() {
    try {
      let CurrentUser: any = JSON.parse(this.tokenEncryptDecrypt.decryptToken(localStorage.getItem("currentUser")));
      this.bIsLoader = true;
      let userid = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("userid"));
      let userEmail =  this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("username"))  //Decryption //sessionStorage.getItem("username");
      let token =  this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("usertoken"))  //Decryption //sessionStorage.getItem("usertoken");
      console.log('token',)
      let ClientIP = CurrentUser.visitorip;
      let ClientBrowser = CurrentUser.visitorbrowser;
      let UserName = CurrentUser.userFullName;
      /*   console.log(ClientIP)
        console.log(CurrentUser) */
      const datepipe = new DatePipe("en-US");
      const currentDate = datepipe.transform(Date.now(), "MM-dd-yyyy");

      let AssignedSendToName: any = [];
      AssignedSendToName.push(this.AssignedTo.value.displayname);

      let AssignedCCName: any = [];
      for (let i = 0; i < this.CC.value.length; i++) {
        if (!isNullOrUndefined(AssignedCCName)) {
          AssignedCCName.push(this.CC.value[i].displayname);
        }
      }

      this.datatransfer.Obj_CreateProject.subscribe(data => {
        this.CreateProject = data;
      });

      let input_ar_claimbatch: {
        envname: any;
        DatasetName: any;
        PracticeName: any;
        ProjectName: any;
        Status: any;
        Priority: any;
        AssignedToName: any;
        AssignedToEmail: any;
        AssignedCCName: any;
        AssignedCCEmail: any;
        Description: any;
        ExpectedCompletionDate: any;
        ExternalUserEmail: any;
        ClaimCount: any;
        Balance: any;
        CreatedOn: any;
        CreatedBy: any;
        AssigneeName: any;
        AssignedOn: any;
        AssignedToUserId: any;
        CreatedByUserid: any;
        Projecttype: any;
        DML: any;
      } = {
        envname: this.CurrentEnvironment,
        DatasetName: this.CreateProject.Clientkey,
        PracticeName: this.CreateProject.PracticeName,
        ProjectName: this.ProjectName.value,
        Status: this.Status.value,
        Priority: this.Priority.value,
        AssignedToName: AssignedSendToName.toString(),
        AssignedToEmail: this.SendToEmail.value,
        AssignedCCName: AssignedCCName.toString(),
        AssignedCCEmail: this.CCEmail.value,
        Description: !isNullOrUndefined(this.Description.value) ? this.Description.value : '',
        ExpectedCompletionDate: this.datePipe
          .transform(this.EnddateValue, "yyyy-MM-dd")
          .toString(),
        ExternalUserEmail: !isNullOrUndefined(this.SelectedExternalUserEmailtxt)
          ? this.SelectedExternalUserEmailtxt
          : "",
        ClaimCount: this.ClaimCount.toString(),
        Balance: this.Balance.toString(),
        CreatedOn: this.datePipe
          .transform(currentDate, "yyyy-MM-ddTHH:mm:ss.SSS")
          .toString(),
        CreatedBy: userEmail,
        AssigneeName: this.CurrentUser,
        AssignedOn: this.datePipe
          .transform(currentDate, "yyyy-MM-ddTHH:mm:ss.SSS")
          .toString(),
        AssignedToUserId: this.AssignedTo.value.userid,
        CreatedByUserid: userid,
        Projecttype: this.CreateProject.Projecttype,
        DML: "Insert",
      };

      let claimselection = this.CreateProject.ClaimSelection;
      if (!isNullOrUndefined(claimselection)) {
        claimselection.result = 'savedata';
        claimselection.data = input_ar_claimbatch;
      }

      let querystr = "call " + this.CurrentDataSet + ".SP_AR_V1('" + JSON.stringify(claimselection) + "','" + this.CreateProject.Clientkey + "','" + this.dateConvert(this.CreateProject.asofdate) + "','" +
        this.CreateProject.skipbefore + "','" + this.CreateProject.skipafter + "'," + this.CreateProject.payerid + ",'" + this.CreateProject.datetype +
        "','" + this.CreateProject.cpt_type + "','" + this.CreateProject.cpt_value + "','"
        + this.CreateProject.insurance_type + "','" + this.CreateProject.insurance_value + "','"
        + this.CreateProject.insurancetypedesc_value + "','" + this.CreateProject.procedure_value + "','"
        + this.CreateProject.provider_type + "'," + this.CreateProject.provider_id + ");";

      let param:
        {
          querystring: string,
          loginuser: any,
          clientip: any,
          clientbrowser: any,
          authorization: any
        } = {
        querystring: querystr,
        loginuser: UserName,
        clientip: ClientIP,
        clientbrowser: ClientBrowser,
        authorization: "Bearer " + token,
      };
      this.api.getFunctionName("SpecialClaimDynamicCallsResult ");
      const metricsdata = this.api.BindDataRCM(param).share();
      metricsdata
        .map((res) => res.json())
        .subscribe(
          (res) => {
            if (res.length > 0) {
              if (!isNullOrUndefined(res)) {
                if (res[0]["ProjectId"] != '' && res[0]["ProjectId"].length > 0) {
                  // console.log("getClaimCountData : ", res);
                  this.clsUtility.showSuccess("Data saved successfully");
                  this.logapi.insertActivityLog(
                    "AR : " + this.ProjectName.value + " project created successfully",
                    "Create Project",
                    "Create"
                  );
                  this.Save_SendEmail();
                }
                else {
                  this.clsUtility.showError("Error while saving");
                }
              }
            }
            this.bIsLoader = false;
            document.getElementById('hidemodal').click();
          },
          (err) => {
            this.logapi.insertActivityLog(
              "AR : " + this.ProjectName.value + " error while creating project",
              "Create Project",
              "Create"
            );
            this.bIsLoader = false;
            this.clsUtility.showWarning(err);
          }
        );
    } catch (error) {
      this.bIsLoader = false;
      this.clsUtility.LogError(error);
    }
  }
  Save_Claimnotwork() {
    try {
      this.bIsLoader = true;
      /* let CurrentUser:any= JSON.parse(this.tokenEncryptDecrypt.decryptToken(localStorage.getItem("currentUser")));
      let ClientIP = CurrentUser.visitorip;
      let ClientBrowser = CurrentUser.visitorbrowser;
      let UserName = CurrentUser.userFullName; */
      let userid = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("userid"));
      let userEmail =  this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("username"))  //Decryption  //sessionStorage.getItem("username");
      const datepipe = new DatePipe("en-US");
      const currentDate = datepipe.transform(Date.now(), "MM-dd-yyyy");

      let AssignedSendToName: any = [];
      AssignedSendToName.push(this.AssignedTo.value.displayname);

      let AssignedCCName: any = [];
      for (let i = 0; i < this.CC.value.length; i++) {
        if (!isNullOrUndefined(AssignedCCName)) {
          AssignedCCName.push(this.CC.value[i].displayname);
        }
      }

      this.datatransfer.Obj_CreateProject.subscribe(data => {
        this.CreateProject = data;
      });

      let input_claimnotwork_claimbatch: {
        envname: any;
        DatasetName: any;
        PracticeName: any;
        ProjectName: any;
        Status: any;
        Priority: any;
        AssignedToName: any;
        AssignedToEmail: any;
        AssignedCCName: any;
        AssignedCCEmail: any;
        Description: any;
        ExpectedCompletionDate: any;
        ExternalUserEmail: any;
        ClaimCount: any;
        Balance: any;
        CreatedOn: any;
        CreatedBy: any;
        AssigneeName: any;
        AssignedOn: any;
        AssignedToUserId: any;
        CreatedByUserid: any;
        Projecttype: any;
        DML: any;
      } = {
        envname: this.CurrentEnvironment,
        DatasetName: this.CreateProject.Clientkey,
        PracticeName: this.CreateProject.PracticeName,
        ProjectName: this.ProjectName.value,
        Status: this.Status.value,
        Priority: this.Priority.value,
        AssignedToName: AssignedSendToName.toString(),
        AssignedToEmail: this.SendToEmail.value,
        AssignedCCName: AssignedCCName.toString(),
        AssignedCCEmail: this.CCEmail.value,
        Description: !isNullOrUndefined(this.Description.value) ? this.Description.value : '',
        ExpectedCompletionDate: this.datePipe
          .transform(this.EnddateValue, "yyyy-MM-dd")
          .toString(),
        ExternalUserEmail: !isNullOrUndefined(this.SelectedExternalUserEmailtxt)
          ? this.SelectedExternalUserEmailtxt
          : "",
        ClaimCount: this.ClaimCount.toString(),
        Balance: this.Balance.toString(),
        CreatedOn: this.datePipe
          .transform(currentDate, "yyyy-MM-ddTHH:mm:ss.SSS")
          .toString(),
        CreatedBy: userEmail,
        AssigneeName: this.CurrentUser,
        AssignedOn: this.datePipe
          .transform(currentDate, "yyyy-MM-ddTHH:mm:ss.SSS")
          .toString(),
        AssignedToUserId: this.AssignedTo.value.userid,
        CreatedByUserid: userid,
        Projecttype: "Claim not worked upon",
        DML: "Insert",
      };

      let claimselection = this.CreateProject.ClaimSelection;
      if (!isNullOrUndefined(claimselection)) {
        claimselection.result = 'savedata';
        claimselection.data = input_claimnotwork_claimbatch;
      }


      let querystr = "call " + this.CurrentDataSet + ".SP_ClaimNotWorkedUpon('" + this.CreateProject.Clientkey + "',null,'" + this.dateConvert(this.EnddateValue) + "','" + this.CreateProject.noofdays + "','" + JSON.stringify(this.CreateProject.ClaimSelection) + "','" + this.CreateProject.Filtername + "','" + this.CreateProject.Filtertype + "','" + this.CreateProject.activitytype + "','');";

      let param: {
        querystring: string
                    /* loginuser:any,
                    clientip:any,
                    clientbrowser, */ }
        = {
        querystring: querystr,
               /*  loginuser:UserName,
                clientip:ClientIP,
                clientbrowser:ClientBrowser */ };
      this.api.getFunctionName("DynamicCallsResult ");
      const metricsdata = this.api.BindDataRCM(param)
        .map((res) => res.json())
        .subscribe(
          (res) => {
            if (res.length > 0) {
              if (!isNullOrUndefined(res)) {
                if (res[0]["ProjectId"] != '' && res[0]["ProjectId"].length > 0) {
                  // console.log("getClaimCountData : ", res);
                  this.clsUtility.showSuccess("Data saved successfully");
                  this.logapi.insertActivityLog(
                    "Claim not worked upon : " + this.ProjectName.value + " project created successfully",
                    "Create Project",
                    "Create"
                  );
                  this.Save_SendEmail();
                }
                else {
                  this.clsUtility.showError("Error while saving");
                }
              }
            }
            this.bIsLoader = false;
            document.getElementById('hidemodal').click();
          },
          (err) => {
            this.logapi.insertActivityLog(
              "Claim not worked upon : " + this.ProjectName.value + " error while creating project",
              "Create Project",
              "Create"
            );
            this.bIsLoader = false;
            this.clsUtility.showWarning(err);
          }
        );
    } catch (error) {
      this.bIsLoader = false;
      this.clsUtility.LogError(error);
    }
  }
  onCloseModal() {
    try {
      if (this.ClaimSelectionEdit.actionType == "Edit") {
        this.myProjects.getScoreCardData();
        this.myProjects.getProjectsData();
      }
    } catch (error) {
      this.clsUtility.LogError(error);
    }
  }

  SaveDynamically() {
    try {
      console.log("SaveDynamically");
     let CurrentUser: any = JSON.parse(this.tokenEncryptDecrypt.decryptToken(localStorage.getItem("currentUser")));
     
      this.bIsLoader = true;
      this.LoaderLabel = "Saving Details";
      const datepipe = new DatePipe("en-US");
      const currentDate = datepipe.transform(
        Date.now(),
        "MM-dd-yyyy"
      );
      let userid = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("userid"));
      let userEmail =  this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("username"))  //Decryption  //sessionStorage.getItem("username");
      let token =  this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("usertoken"))  //Decryption //sessionStorage.getItem("usertoken");
      let ClientIP = CurrentUser.visitorip;
      let ClientBrowser = CurrentUser.visitorbrowser;
      let UserName = CurrentUser.userFullName;
      let AssignedSendToName: any = [];
      AssignedSendToName.push(this.AssignedTo.value.displayname);

      let AssignedCCName: any = [];
      for (let i = 0; i < this.CC.value.length; i++) {
        if (!isNullOrUndefined(AssignedCCName)) {
          AssignedCCName.push(this.CC.value[i].displayname);
        }
      }

      this.datatransfer.Obj_CreateProject.subscribe(data => {
        this.CreateProject = data;
      });

      let input_claimbatch: {
        ProjectName: any;
        ClaimData: any;
        Projecttype: any;
        DatasetName: any;
        PracticeName: any;
        Status: any;
        CreatedOn: any;
        CreatedBy: any;
        CreatedByUserid: any;
        ExpectedCompletionDate: any;
        Priority: any;
        Description: any;
        AssigneeName: any;
        AssigneeEmail: any;
        AssignedToName: any;
        AssignedToEmail: any;
        AssignedOn: any;
        AssignedCCName: any;
        AssignedCCEmail: any;
        ExternalUserEmail: any;
        ClaimCount: any;
        Balance: any;
        AssignedToUserId: any;
        envname: any;
        DML: any;
      } = {
        ProjectName: this.ProjectName.value,
        ClaimData: [
          {
            "ntransactionid": "233896744353854654",
            "ClaimNo": 10,
            "chargesclosedate": "2011-01-21",
            "Balance": 607
          },
          {
            "ntransactionid": "643459898877837388",
            "ClaimNo": 85,
            "chargesclosedate": "2020-11-30",
            "Balance": 500
          },
          {
            "ntransactionid": "156017562464521013",
            "ClaimNo": 66,
            "chargesclosedate": "2012-03-15",
            "Balance": 424
          },
          {
            "ntransactionid": "289157821127523628",
            "ClaimNo": 21,
            "chargesclosedate": "2011-02-07",
            "Balance": 418
          },
          {
            "ntransactionid": "156190045005037372",
            "ClaimNo": 23,
            "chargesclosedate": "2011-02-07",
            "Balance": 337.25
          },
          {
            "ntransactionid": "145966552721860393",
            "ClaimNo": 22,
            "chargesclosedate": "2011-02-07",
            "Balance": 278
          },
          {
            "ntransactionid": "234431059724759322",
            "ClaimNo": 71,
            "chargesclosedate": "2012-02-15",
            "Balance": 490.77
          },
          {
            "ntransactionid": "123245281378781686",
            "ClaimNo": 24,
            "chargesclosedate": "2011-02-07",
            "Balance": 443
          },
          {
            "ntransactionid": "857765911405043221",
            "ClaimNo": 15,
            "chargesclosedate": "2011-02-07",
            "Balance": 253
          },
          {
            "ntransactionid": "107275096589303001",
            "ClaimNo": 76,
            "chargesclosedate": "2012-03-16",
            "Balance": 207.29
          },
          {
            "ntransactionid": "242536910807223338",
            "ClaimNo": 83,
            "chargesclosedate": "2020-06-05",
            "Balance": 200
          },
          {
            "ntransactionid": "104063885261025336",
            "ClaimNo": 86,
            "chargesclosedate": "2020-10-07",
            "Balance": 200
          },
          {
            "ntransactionid": "344494420756430634",
            "ClaimNo": 17,
            "chargesclosedate": "2011-02-08",
            "Balance": 186
          },
          {
            "ntransactionid": "594579283391546346",
            "ClaimNo": 73,
            "chargesclosedate": "2012-03-13",
            "Balance": 185.15
          },
          {
            "ntransactionid": "730826678281130919",
            "ClaimNo": 36,
            "chargesclosedate": "2011-11-03",
            "Balance": 182
          },
          {
            "ntransactionid": "231437286204891323",
            "ClaimNo": 34,
            "chargesclosedate": "2011-11-03",
            "Balance": 182
          },
          {
            "ntransactionid": "342852977633956380",
            "ClaimNo": 25,
            "chargesclosedate": "2011-02-07",
            "Balance": 182
          },
          {
            "ntransactionid": "149162150156298653",
            "ClaimNo": 35,
            "chargesclosedate": "2011-11-03",
            "Balance": 182
          }
        ],
        Projecttype: this.CreateProject.Projecttype,
        DatasetName: this.CreateProject.Clientkey,
        PracticeName: this.CreateProject.PracticeName,
        Status: this.Status.value,
        CreatedOn: this.datePipe.transform(currentDate, 'yyyy-MM-ddTHH:mm:ss.SSSZ').toString(),
        CreatedBy: this.CurrentUser,
        CreatedByUserid: userEmail,
        ExpectedCompletionDate: this.datePipe
          .transform(this.EnddateValue, "yyyy-MM-dd")
          .toString(),
        Priority: this.Priority.value,
        Description: !isNullOrUndefined(this.Description.value) ? this.Description.value : '',
        AssigneeName: this.CurrentUser,
        AssigneeEmail: userEmail,
        AssignedToName: AssignedSendToName.toString(),
        AssignedToEmail: this.SendToEmail.value,
        AssignedOn: this.datePipe
          .transform(currentDate, "yyyy-MM-ddTHH:mm:ss.SSSZ")
          .toString(),
        AssignedCCName: AssignedCCName.toString(),
        AssignedCCEmail: this.CCEmail.value,
        ExternalUserEmail: !isNullOrUndefined(this.SelectedExternalUserEmailtxt)
          ? this.SelectedExternalUserEmailtxt
          : "",
        ClaimCount: this.ClaimCount.toString(),
        Balance: this.Balance.toString(),
        AssignedToUserId: this.AssignedTo.value.userid,
        envname: this.CurrentEnvironment,
        DML: "Insert",
      }

      // let input_claimbatch = {
      //   "ProjectName": "AR DAsh",
      //   "ClaimData": [
      //     {
      //       "ntransactionid": "233896744353854654",
      //       "ClaimNo": 10,
      //       "chargesclosedate": "2011-01-21",
      //       "Balance": 607
      //     },
      //     {
      //       "ntransactionid": "643459898877837388",
      //       "ClaimNo": 85,
      //       "chargesclosedate": "2020-11-30",
      //       "Balance": 500
      //     },
      //     {
      //       "ntransactionid": "156017562464521013",
      //       "ClaimNo": 66,
      //       "chargesclosedate": "2012-03-15",
      //       "Balance": 424
      //     },
      //     {
      //       "ntransactionid": "289157821127523628",
      //       "ClaimNo": 21,
      //       "chargesclosedate": "2011-02-07",
      //       "Balance": 418
      //     },
      //     {
      //       "ntransactionid": "156190045005037372",
      //       "ClaimNo": 23,
      //       "chargesclosedate": "2011-02-07",
      //       "Balance": 337.25
      //     },
      //     {
      //       "ntransactionid": "145966552721860393",
      //       "ClaimNo": 22,
      //       "chargesclosedate": "2011-02-07",
      //       "Balance": 278
      //     },
      //     {
      //       "ntransactionid": "234431059724759322",
      //       "ClaimNo": 71,
      //       "chargesclosedate": "2012-02-15",
      //       "Balance": 490.77
      //     },
      //     {
      //       "ntransactionid": "123245281378781686",
      //       "ClaimNo": 24,
      //       "chargesclosedate": "2011-02-07",
      //       "Balance": 443
      //     },
      //     {
      //       "ntransactionid": "857765911405043221",
      //       "ClaimNo": 15,
      //       "chargesclosedate": "2011-02-07",
      //       "Balance": 253
      //     },
      //     {
      //       "ntransactionid": "107275096589303001",
      //       "ClaimNo": 76,
      //       "chargesclosedate": "2012-03-16",
      //       "Balance": 207.29
      //     },
      //     {
      //       "ntransactionid": "242536910807223338",
      //       "ClaimNo": 83,
      //       "chargesclosedate": "2020-06-05",
      //       "Balance": 200
      //     },
      //     {
      //       "ntransactionid": "104063885261025336",
      //       "ClaimNo": 86,
      //       "chargesclosedate": "2020-10-07",
      //       "Balance": 200
      //     },
      //     {
      //       "ntransactionid": "344494420756430634",
      //       "ClaimNo": 17,
      //       "chargesclosedate": "2011-02-08",
      //       "Balance": 186
      //     },
      //     {
      //       "ntransactionid": "594579283391546346",
      //       "ClaimNo": 73,
      //       "chargesclosedate": "2012-03-13",
      //       "Balance": 185.15
      //     },
      //     {
      //       "ntransactionid": "730826678281130919",
      //       "ClaimNo": 36,
      //       "chargesclosedate": "2011-11-03",
      //       "Balance": 182
      //     },
      //     {
      //       "ntransactionid": "231437286204891323",
      //       "ClaimNo": 34,
      //       "chargesclosedate": "2011-11-03",
      //       "Balance": 182
      //     },
      //     {
      //       "ntransactionid": "342852977633956380",
      //       "ClaimNo": 25,
      //       "chargesclosedate": "2011-02-07",
      //       "Balance": 182
      //     },
      //     {
      //       "ntransactionid": "149162150156298653",
      //       "ClaimNo": 35,
      //       "chargesclosedate": "2011-11-03",
      //       "Balance": 182
      //     }
      //   ],
      //   "Projecttype": "AR",
      //   "DatasetName": "QIDEVDEMO1",
      //   "PracticeName": "",
      //   "Status": "Open",
      //   "CreatedOn": "2021-05-12T00:00:00.000",
      //   "CreatedBy": "Shital Bairagi",
      //   "CreatedByUserid": "ebe7e0e3-40f1-11eb-a5b7-91932972b845",
      //   "ExpectedCompletionDate": "2021-05-24",
      //   "Priority": "High",
      //   "Description": "AR Dash done",
      //   "AssigneeName": "Shital Bairagi",
      //   "AssigneeEmail": "shital.bairagi@triarqhealth.com",
      //   "AssignedToName": "Shital Bairagi",
      //   "AssignedToEmail": "shital.bairagi@triarqhealth.com",
      //   "AssignedOn": "2021-05-12T00:00:00.000",
      //   "AssignedCCName": "Jagruti Patil",
      //   "AssignedCCEmail": "jagruti.patil@triarqhealth.com",
      //   "ExternalUserEmail": "",
      //   "ClaimCount": "18",
      //   "Balance": "7953.31",
      //   "AssignedToUserId": "ebe7e0e3-40f1-11eb-a5b7-91932972b845",
      //   "envname": "Development",
      //   "DML": "Insert"
      // };

      // let querystr = "call " + this.CurrentDataSet + ".SP_insertworkqueuemasterdetails('" + this.CurrentDataSet + "','" + JSON.stringify(input_claimbatch) + "','savedata')";
      // let param: { querystring: string } = { querystring: querystr };

      let querystr = "call " + this.CurrentDataSet + ".SP_insertworkqueuemasterdetails('" + this.CurrentDataSet + "','" + JSON.stringify(input_claimbatch) + "','savedata')";
      let param:
        {
          querystring: string,
          loginuser: any,
          clientip: any,
          clientbrowser: any,
          authorization: any
        } = {
        querystring: querystr,
        loginuser: UserName,
        clientip: ClientIP,
        clientbrowser: ClientBrowser,
        authorization: "Bearer " + token,
      };
      this.api.getFunctionName("SpecialClaimDynamicCallsResult ");
      const metricsdata = this.api.BindDataRCM(param).share();
      metricsdata
        .map((res) => res.json())
        .subscribe(
          (res) => {
            // sessionStorage.removeItem("ClaimBatchSelection");
            if (!isNullOrUndefined(res)) {
              if (res[0]["ProjectId"] != '' && res[0]["ProjectId"].length > 0) {
                this.clsUtility.showSuccess('Record updated successfully');
                this.logapi.insertActivityLog(
                  this.ProjectNamedisplay + " project updated successfully",
                  "Create Project",
                  "Read"
                );
                this.bIsLoader = false;
                document.getElementById('hidemodal').click();
              }
              else {
                this.bIsLoader = false;
              }
            }

          },
          (err) => {
            this.bIsLoader = false;
            this.clsUtility.showWarning(err);
            this.logapi.insertActivityLog(
              this.ProjectNamedisplay + " error while creataing project",
              "Create Project",
              "Read"
            );
          }
        );
    } catch (error) {
      this.clsUtility.LogError(error)
    }
  }
}
;
function isNullOrUndefined(value: any): boolean { return value === null || value === undefined; }
