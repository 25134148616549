import { Component, OnInit, Injectable, Input, OnChanges } from "@angular/core";
import { ExcelExportData } from "@progress/kendo-angular-excel-export";
import { CallserviceService } from "../../../CallServices/callservice.service";
import {
  process,
  GroupDescriptor,
  State,
  aggregateBy
} from "@progress/kendo-data-query";
import { ToastrService } from "ngx-toastr";
import { Utility } from "src/app/ModelFinancialMetrics/CommonService/utility";import { TokenEncryptionService } from "src/app/CallServices/token-encryption.service";
import { AuditLogService } from "src/app/CallServices/audit-log.service";
declare var $ : any;

@Injectable()
@Component({
  selector: "app-summarytable",
  templateUrl: "./summarytable.component.html",
  styleUrls: ["./summarytable.component.css"]
})
export class SummarytableComponent implements OnInit,OnChanges {

  @Input() gridData:any[]=[];
singlesearchdata:any;
public apicall
public pageSize: number = 10;
  constructor(  private toaster: ToastrService,  private api: CallserviceService,
    private tokenEncryptDecrypt:TokenEncryptionService, public logapi: AuditLogService,) {
    this.clsUtility = new Utility(toaster);
    this.expectedCollectionSummaryexcel = this.expectedCollectionSummaryexcel.bind(this);
   
  }
  private clsUtility: Utility;
  public list: any;
  binddt: any[];
  public detailssearch: string[] = [];
  bindgrid:any;
  ngOnInit() {
   // this.gridlable=this.gridData[0].callby;
  //  this.gridData=this.bindgrid;
  
    // this.bindgrid=this.tableBind(this.list);
    // return this.bindgrid;
  }
  public tableBind(data: any) {
    this.binddt = data;
    console.log(this.binddt);
    // return true;
  }
  gridlable:any;
  ngOnChanges(){
    if(this.gridData.length != 0){
      this.gridlable= this.gridData[0].callby;
      this.singlesearchdata=this.gridData;
    }
   
  }
  public onDetailsFilter(inputValue: string): void {
    // this.groupdata=this.gridView;
    this.gridData = process(this.singlesearchdata, {
      filter: {
        logic: "or",
        filters: [
          {
            field: "nclaimno",
            operator: "contains",
            value: inputValue
          },
          {
            field: "BillingProvidersFullName",
            operator: "contains",
            value: inputValue
          },
          {
            field: "RenderingProvidersFullName",
            operator: "contains",
            value: inputValue
          },
          {
            field: "FacilityName",
            operator: "contains",
            value: inputValue
          },
          {
            field: "scptcode",
            operator: "contains",
            value: inputValue
          },
          {
            field: "cptgroup",
            operator: "contains",
            value: inputValue
          }
          ,
          {
            field: "Collection_Percentage",
            operator: "contains",
            value: inputValue
          },
          {
            field: "ChargesDate",
            operator: "contains",
            value: inputValue
          },
          {
            field: "DOR",
            operator: "contains",
            value: inputValue
          },
          {
            field: "ExpectedCollectionType",
            operator: "contains",
            value: inputValue
          },
          {
            field: "Charges",
            operator: "contains",
            value: inputValue
          },
          {
            field: "ReimbursementName",
            operator: "contains",
            value: inputValue
          },
          {
            field: "Deductable_UncontrollableAdjustmentCodes",
            operator: "contains",
            value: inputValue
          },
          {
            field: "Deductable_UncontrollableAdjustmentAmount",
            operator: "contains",
            value: inputValue
          },
          {
            field: "NotDeductable_UncontrollableAdjustmentCodes",
            operator: "contains",
            value: inputValue
          },
          {
            field: "NotDeductable_UncontrollableAdjustmentAmount",
            operator: "contains",
            value: inputValue
          },
          {
            field: "CalculatedWriteOffType",
            operator: "contains",
            value: inputValue
          }
        ]
      }
    }).data;
  }
    //deatils popup item variable
    chargesdate: any;
    facility: any;
    cptcode: any;
    cptgroup: any;
    billingp: any;
    renderingp: any;
    ExpectedCollectionType: any;
    ReimbursementName: any;
    CalculatedWriteOffType: any;
    bispaymentvoid: any;
    charges: any;
    InsurancePayment: any;
    InsuranceDue: any;
    PatientPayment: any;
    PatientDue: any;
    NetAdjustment: any;
    TotalChargePaid: any;
    TotalChargeBalance: any;
    NetCollectionAmount: any;
    NetExpectedCollection: any;
    CollectionPercentage: any;
    AllowedAmount: any;
    RemittedAllowedAmount: any;
    ModeAllowedAmount: any;
    ReimbursementAmount: any;
    DeductableUncontrollableAdjustmentCode: any;
    DeductableUncontrollableAdjustmentAmount: any;
    NonDeductableUncontrollableAdjustmentCode: any;
    NonDeductableUncontrollableAdjustmentAmount: any;
    CalculatedAdjustmentAmount: any;
    CalculatedWO: any;
    BadDebtDueAmount: any;
    ExpectedCollectioncalc: any;
    DOR: any;
    editboxclainno:any;
    clientkey:any;
   ClinicName:any;
    public BindPopup(dataItem:any) {
    
 this.clientkey=dataItem.clientkey;
 this.ClinicName=dataItem.clinicname;
      this.editboxclainno = dataItem.nclaimno;
      this.chargesdate = dataItem.ChargesDate;
      this.DOR = dataItem.DOR;
      this.facility = dataItem.FacilityName;
      this.cptcode = dataItem.scptcode;
      this.cptgroup = dataItem.cptgroup;
      this.billingp = dataItem.BillingProvidersFullName;
      this.renderingp = dataItem.BillingProvidersFullName;
      this.ExpectedCollectionType = dataItem.ExpectedCollectionType;
      this.ReimbursementName = dataItem.ReimbursementName;
      this.CalculatedWriteOffType = dataItem.CalculatedWriteOffType;
      this.bispaymentvoid = dataItem.bispaymentvoid;
      this.ExpectedCollectioncalc = dataItem.ExpectedCollection;
      // table column data
      this.charges = dataItem.Charges;
  
      this.InsurancePayment = dataItem.inspayment;
      this.InsuranceDue = dataItem.InsuranceDue;
      this.PatientPayment = dataItem.patientpayment;
      this.PatientDue = dataItem.PatientDueAmount;
      this.NetAdjustment = dataItem.NetAdjustment;
      this.TotalChargePaid = dataItem.TotalChargePaid;
      this.TotalChargeBalance = dataItem.TotalChargeBalance;
      this.NetCollectionAmount = dataItem.NetCollectionAmount;
      this.NetExpectedCollection = dataItem.NetExpectedCollection;
      this.CollectionPercentage = dataItem.Collection_Percentage;
      this.AllowedAmount = dataItem.TotalAllowedAmount;
      this.RemittedAllowedAmount = dataItem.RemittedAllowedAmount;
      this.ModeAllowedAmount = dataItem.ModeAllowedAmount;
      this.ReimbursementAmount = dataItem.ReimbursementAmount;
      this.DeductableUncontrollableAdjustmentCode = dataItem.Deductable_UncontrollableAdjustmentCodes;
      this.DeductableUncontrollableAdjustmentAmount = dataItem.Deductable_UncontrollableAdjustmentAmount;
      this.NonDeductableUncontrollableAdjustmentCode = dataItem.NotDeductable_UncontrollableAdjustmentCodes;
      this.NonDeductableUncontrollableAdjustmentAmount = dataItem.NotDeductable_UncontrollableAdjustmentAmount;
      this.CalculatedAdjustmentAmount = dataItem.CalculatedAdjAmt;
      this.CalculatedWO = dataItem.dCalculatedWriteOff;
      this.BadDebtDueAmount = dataItem.BadDebtDueAmount;
  this.bindUserList();
    }
    userlist:any;
    NoteText:any;
    SelectedUserEmail:any;
    SelectedUser:any;
    bindUserList() {
      
      const seq = this.api.getUser().share();
      seq
        .map(res => res.json())
        .subscribe(res => {
          this.userlist=res;
          //console.log("user list *********** "+res);
        });
    }
    selectUserEmail(user:any){
    //  console.log(JSON.stringify(user));
      this.SelectedUserEmail=user["email"];
    }
    claimactionable: { calculated_adjustment: number, charge_amount: number, claimno: number, collection_percentage: number, cpt_code: string, dos: Date, insurance_due: number, insurance_payment: number, note: string, patient_due: number, patient_payment: number, practicename: string, total_charge_paid: number, assignuserid: string, assignusername: string, createduserid: string, createdusername: string }
    = { calculated_adjustment: 0, charge_amount: 0, claimno: 0, collection_percentage: 0, cpt_code: "", dos: new Date, insurance_due: 0, insurance_payment: 0, note: "", patient_due: 0, patient_payment: 0, practicename: "", total_charge_paid: 0, assignuserid: "", assignusername: "", createduserid: "", createdusername: "" };
    SaveAssignTask() {
     let assignParam: {
        clientkey: string;
        clinicname: string;
        assignto: string;
        email: string;
        claimno: string;
        note: string;
      } = {
          clientkey: "",
          clinicname: "",
          assignto: "",
          email: "",
          claimno: "",
          note: ""
        };
      const assignto = "example text"//$("#assigntxt").val();
      const email = this.SelectedUserEmail;//$("#emailtxt").val();
      const notetxt = this.NoteText;
      const valid = this.ValidateEmail(notetxt);
      if (valid == true) {
        this.api.getFunctionName("EC97DetailsLineTask");
        assignParam.clientkey = this.clientkey;
        assignParam.clinicname = this.ClinicName;
        assignParam.claimno = String(this.editboxclainno);
        assignParam.assignto = String(assignto);
        assignParam.email = String(email);
        assignParam.note = String(notetxt);
        const practiceftr = this.api.BindDataRCM(assignParam).share();
        practiceftr
          .map(res => res.json())
          .subscribe(res => {
            if (res.success) {
              // alert("Task Assigned");
            }
          });
        // save deatils 
        this.api.getFunctionName("Save");
  
        // Qonducter details save
        this.claimactionable.calculated_adjustment = this.CalculatedAdjustmentAmount;
        this.claimactionable.charge_amount = this.charges;
        this.claimactionable.claimno = this.editboxclainno
        this.claimactionable.collection_percentage = this.CollectionPercentage;
        this.claimactionable.cpt_code = this.cptcode;
        this.claimactionable.dos = (new Date(this.chargesdate));
        this.claimactionable.insurance_due = this.InsuranceDue;
        this.claimactionable.insurance_payment = this.InsurancePayment,
          this.claimactionable.note = String(notetxt);
        this.claimactionable.patient_due = this.PatientDue;
        this.claimactionable.patient_payment = this.PatientPayment;
        this.claimactionable.practicename = this.ClinicName;
        this.claimactionable.total_charge_paid = this.TotalChargePaid;
        // need to change this param as per call params variable
        this.claimactionable.assignuserid = this.SelectedUser["userid"];
        this.claimactionable.assignusername = this.SelectedUser["displayname"];
        this.claimactionable.createduserid = this.tokenEncryptDecrypt.decryptToken(this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("userid")));  //Decryption  //sessionStorage.getItem('userid');
        this.claimactionable.createdusername = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("username")); //Decryption  //sessionStorage.getItem('username');
        const savemap = this.api.SaveData(this.claimactionable).share();
        savemap
          .subscribe(res => {
            this.clsUtility.showSuccess("Assign Task to Qondutor user Sucessfully");
            //alert("Assign Task to Qondutor user Sucessfully");
           // $("#modelclose").click();
          });
      } else {
        this.clsUtility.showWarning("Assign Task to Qondutor user Sucessfully");
      }
    }
    ValidateEmail(notetxt) {
      let isempty;
      if (notetxt.length == 0) {
        isempty = true;
      } else {
        isempty = false;
      }
      //if (isempty == false && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
      if (isempty == false) {
        return (true)
      } else {
        return (false)
      }
  
    }
     
   // expected Collection summary export to excel
   public expectedCollectionSummaryexcel(): ExcelExportData {
    this.toaster.info('Please Wait', 'Excel is downloading', {
      timeOut: 2000,
      positionClass: "toast-bottom-right",
    });
      // message, module, useraction
     this.logapi.insertActivityLog(
      "Downloaded " + this.gridlable,
       "Expected Collection",
       "Download")
    const result: ExcelExportData = {
      data: process(this.gridData, {
        sort: [{ field: "ChargesDate", dir: "asc" }]
      }).data
    };
    return result;
  }

}
