import { AfterViewInit, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
// import { Permission } from "../../../CallServices/Permission";
import { environment } from "../../../../environments/environment";
import { CookieService } from "ngx-cookie-service";
// import { take, takeUntil } from 'rxjs/operators';
import { Subject, Subscription, timer } from 'rxjs';
import { AuditLogService } from 'src/app/CallServices/audit-log.service';
import { CallserviceService } from 'src/app/CallServices/callservice.service';
//import { BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
// import { Keepalive } from '@ng-idle/keepalive';
// import { Injectable } from '@angular/core';
import { TokenEncryptionService } from 'src/app/CallServices/token-encryption.service';
import { Router } from '@angular/router';
import { FirebaseService } from 'src/app/CallServices/firebase.service';
import { AuthGuardUserGuard } from 'src/app/AuthGuard/auth-guard-user.guard';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  // public modalRef: BsModalRef;
  // @ViewChild('childModal', { static: false }) childModal: ModalDirective;


  toggle: boolean = false;
  appEnvironment: any = environment.currentEnvironment;
  initials: any;
  invalid: boolean;
  passcode: string;
  user: string;


  public isActive: boolean = false;
  hidemaintenancebutton: boolean = false;
  idleState = "Not started.";
  timedOut = false;
  lastPing?: Date = null;
  counter: any;
  percent: number = 0;
  percentIncrease: number = 0;
  idletime: number = 0;
  timeouttime: number = 0;
  firstname: string = "";
  lastname: string = "";
  name: string = "";
  userFullName: any;
  subscription: any;
  Currentdataset: any;
  currentUrl: string;

  constructor(
    public authService: AuthGuardUserGuard,
    private router: Router,
    private commonService: CommonService,
    private cookieService: CookieService,
    public logapi: AuditLogService, private api: CallserviceService,
    private tokenEncryptDecrypt: TokenEncryptionService,
    private idle: Idle,
    private firebaseService: FirebaseService,) {
    this.getabVisibility();
    //this.getIdleTime();
  }


  getabVisibility() {
    document.addEventListener("visibilitychange", function () {
      if (document.hidden) {
        (<HTMLInputElement>document.getElementById('ishidden')).value = 'true';
      }
      else {
        (<HTMLInputElement>document.getElementById('ishidden')).value = 'false';
      }
    });
  }

  username: any;
  //session time out variable
  unsubscribe$: Subject<void> = new Subject();
  timerSubscription: Subscription;
  isTabActive: boolean = true;
  isSessionPopupActive: boolean = false;
  minutesDisplay = 0;
  secondsDisplay = 0;
  isTimerStart: number = 0;
  maintenanceMode: any;
  maintenancemessage: string = "";

  // session code for listners
  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    // Do something
    //console.log("------focused------");
    this.isTabActive = true;
    if (this.isTabActive && this.isSessionPopupActive && this.isTimerStart == 0) {
      // this.startTimer()
    }
  }

  @HostListener('window:blur', ['$event'])
  onBlur(event: any): void {
    // Do something
    // console.log("------blur------");
    this.isTabActive = false;
    // this.stoptimer();
  }

  ngOnInit() {
    this.commonService.sidebarSub.subscribe((toggle) => {
      this.toggle = toggle;
    })
    this.firstname = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("fname"));
    this.username = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("username"))  //Decryption  //sessionStorage.getItem("username");
    let result = this.firstname.split(" ", 2);
    let firstName = result[0];
    let secondName = result[1];
    this.initials = firstName.charAt(0) + secondName.charAt(0);
    this.getIdleTime(); // Idle session time out

    this.Currentdataset = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("currentDataset"));

    setInterval(() => { this.myFunction(); }, 3000); // Call myFunction every 1000 milliseconds (1 second)

    this.firebaseService.getMaintenanceMode().subscribe(data => {
      this.maintenanceMode = data.maintenanceMode;
      if (this.maintenanceMode == true) {
        this.isActive = true;
        this.maintenancemessage = "Are you sure you want to turn OFF maintenance?";
      }
      else {
        this.isActive = false;
        this.maintenancemessage = "Are you sure you want to turn ON maintenance?";
        this.currentUrl = this.router.url;
        if (this.currentUrl == '/financialmetrics/maintenance') {
          setTimeout(() => {
            sessionStorage.clear();
            localStorage.clear();
            this.cookieService.delete("UID");
            this.cookieService.delete("AID");
          }, 1000);

          window.location.assign(environment.maintainancelogout); //logouturl added before delete aid and uid
        }
      }
    });

    if (this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("maintenance")) == "true") {
      this.hidemaintenancebutton = true;
    }
  }
  async myFunction() {
    if (this.cookieService.check('UID') && this.cookieService.check('AID')) {
    } else {
      // environment.Login_url
      window.location.assign(
        environment.Login_url
      );
      return false;
    }
  }

  maintenancetoggle(data: any) {
    try {
      let opttype: boolean;
      if (data == true) {
        opttype = true;
        this.maintenancemessage = "Are you sure you want to turn OFF maintenance?";
      }
      if (data == false) {
        opttype = false;
        this.maintenancemessage = "Are you sure you want to turn ON maintenance?";
      }

      if (opttype != undefined && opttype != null) {
        this.firebaseService.setMaintenanceMode(opttype);
      }
    } catch (error) {
      console.log(error);
    }
  }
  cancelchange() {
    if (this.isActive == true) {
      this.isActive = false;
    } else {
      this.isActive = true;
    }
  }
  maintenancetogglefun() {
    if (this.isActive == true) {
      this.maintenancetoggle(true);
    } else {
      this.maintenancetoggle(false);
    }
  }

  Logout() {
    // setTimeout(() => {
    //   sessionStorage.clear();
    //   localStorage.clear();
    //   this.cookieService.delete("UID");
    //   this.cookieService.delete("AID");
    // }, 1000);

    // window.location.assign(environment.Logout_url); //logouturl added before delete aid and uid

    this.authService.doLogout().then(
      res => {
        sessionStorage.clear();
        localStorage.clear();
        this.cookieService.delete('UID');
        this.cookieService.delete('AID');
        window.location.replace(environment.Logout_url);
      },
      error => {
        console.log("Logout error", error);
      }
    );
  }

  sidepanel() {
    this.toggle = !this.toggle;
    this.commonService.sidebarSub.next(this.toggle);
  }


  // ngAfterViewInit(){
  //     this.resetTimer(); 
  //    }

  //   // session timeout related chnages added

  //   private render(count) {
  //     this.secondsDisplay = this.getSeconds(count);
  //     this.minutesDisplay = this.getMinutes(count);
  //   }

  //   private getSeconds(ticks: number) {
  //     const seconds = ((ticks % 60000) / 1000).toFixed(0);
  //     return this.pad(seconds);
  //   }

  //   private getMinutes(ticks: number) {
  //     const minutes = Math.floor(ticks / 60000);
  //     return this.pad(minutes);
  //   }

  //   private pad(digit: any) {
  //     return digit <= 9 ? '0' + digit : digit;
  //   }

  // resetTimer() {
  //   const interval = 1000;
  //   const duration = 300;


  //   this.timerSubscription = timer(0, interval).pipe(
  //     take(duration)
  //   ).subscribe(value =>
  //     this.render((duration - +value) * interval),
  //     err => { },
  //     () => {

  //       // it will show popup of logout after 60 sec (if idle/not on application it will logout)

  //       //if (this.isTabActive && (sessionStorage.getItem("isLockScreenActivated")=='false')) { 
  //         $("#logOutPopup").show();
  //         this.isSessionPopupActive = true;
  //         this.isTabActive = document.hasFocus();
  //         if (this.isSessionPopupActive && this.isTimerStart == 0) {
  //           this.startTimer();
  //         }

  //       // } else {
  //       //   $("#logOutPopup").show();
  //       //   sessionStorage.setItem("isLockScreenActivated", 'false');
  //       // }

  //     }
  //   )
  // }


  // time: number = 30;
  // interval;

  // startTimer() {
  //   //debugger;
  //   if(localStorage.length!=0){
  //   this.interval = setInterval(() => {
  //     console.log(this.time);
  //     this.percentIncrease =   this.time / 60;
  //     this.percent = this.percent + 3.33;
  //     this.percent++;
  //     console.log(this.percent);
  //     this.time--;
  //     this.isTimerStart = 1;
  //     if (this.time === 0) {
  //       this.stoptimer();
  //       this.Logout();
  //     }
  //   }, 1000)
  // }else{
  //   this.Logout();
  // }
  // }

  // stoptimer() {
  //   this.time = 30;
  //   this.percentIncrease =0;
  //   this.percent=0;
  //   clearInterval(this.interval);

  // }

  // clickOnContineu() {
  //   this.isTimerStart = 0;
  //   this.percentIncrease =0;
  //   this.percent=0;
  //   $("#logOutPopup").hide();
  //   this.isSessionPopupActive = false;
  //   this.stoptimer();
  // }

  // // unlockUser(password) {
  // //   console.log(password);
  // //   if (password === 'password') {
  // //     console.log('user valid');
  // //     $("#logOutPopup").hide();
  // //     sessionStorage.setItem("isLockScreenActivated", 'false');
  // //     this.invalid = false;
  // //     this.passcode = "";
  // //     this.checkUserLoggedIn();
  // //   } else {
  // //     console.log('user invalid');
  // //     this.invalid = true;
  // //     this.passcode = "";
  // //     this.checkUserLoggedIn();
  // //   }
  // // }


  // checkUserLoggedIn() {
  //   if (!this.cookieService.get('UID') && !this.cookieService.get('AID')) {
  //     this.Logout();
  //   }
  // }


  //Session

  stay() {
    this.changeFavicon("continue");
    this.logapi.insertActivityLog(
      "Session Timeout",
      "Session Timeout",
      "clicked on continue to continue session"
    );
    //.log("logapi",this.logapi);
    $("#idleModel").hide();
    this.reset();
  }

  logout() {
    this.api.setLoginStatus(false);
    this.api.setusername("");
    localStorage.clear();
    this.cookieService.delete('UID');
    this.cookieService.delete('AID');
    window.location.assign(environment.Logout_url);
  }


  getIdleTime() {
    this.api.getIdleTime().subscribe(response => {
      let res: any = JSON.parse(response._body);
      //30+150 =180,180*20=3600,3600/60= 60 minutes time for Qinsight only for Production
      //60+120 =180,180*20=3600,3600/60= 60 minutes time for Qinsight for DEV,qa,Staging;

      this.idletime = res.ssettingsvalue; //core response value
      this.idletime = this.idletime * 20;

      //  this.idletime = 3600; //given hardcoded value for QA
      //this.idletime = 300; //given hardcoded value 
      console.log('idletime', this.idletime);
      this.sessiontimeout();
    });
  }


  public userID: string = "";
  sessiontimeout() {
    //this.api.userid.subscribe(value => this.userid = value);

    let CurrentUser: any = JSON.parse(this.tokenEncryptDecrypt.decryptToken(localStorage.getItem("currentUser"))); //old one
    let userid = CurrentUser.userid;
    this.userID = userid;
    //console.log('userid',this.userID);
    this.timeouttime = + Number(this.api.idleTimeout);
    this.idle.setIdle(this.idletime);
    this.idle.setTimeout(this.timeouttime);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.percentIncrease = 100 / this.timeouttime;
    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = "No longer idle.";
      this.reset();
    });

    this.idle.onTimeout.subscribe(() => {
      this.idleState = "Timed out!";
      this.timedOut = true;
      this.checkUserActivity();
    });


    this.idle.onIdleStart.subscribe(() => {
      this.setusername();
      this.idleState = "You've gone idle!";
      this.api.sessiontimeout(this.userID, Math.floor(this.idletime / 60)).subscribe((res) => {
        let response: any = JSON.parse(res._body);
        //console.log('sessiontimeout res', res);
        if (response['result'] == true) {
          this.changeFavicon("continue");
          $("#idleModel").hide();
          // this.idle.stop();
          this.reset();
        } else {

          $("#idleModel").show();
          this.changeFavicon("logout");
        }
      })
    });

    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.counter = countdown;
      this.percent = this.percent + this.percentIncrease;
      this.idleState = "You will time out in " + countdown + " seconds!";
      //console.log(this.idleState)
      setTimeout(() => {
        if ((<HTMLInputElement>document.getElementById('ishidden')).value == 'true') {
          this.checkUserActivity();
        } else {
          return;
        }
      }, 1000 * this.timeouttime);
    });
    this.reset();
  }

  reset() {
    //console.log("in reset");
    this.idle.stop();
    this.idle.watch();
    this.timedOut = false;
    this.percent = 0;
    this.counter = "" + this.timeouttime;
  }

  checkUserActivity() {
    this.api.sessiontimeout(this.userID, Math.floor(this.idletime / 60)).subscribe((res) => {
      //console.log('sessiontimeoutNew', res);
      $("#idleModel").hide();
      if (res['result'] == true) {
        this.changeFavicon("continue");
        $("#idleModel").hide();
        this.reset();
      } else {
        $("#idleModel").hide();
        this.changeFavicon("logout");
        // this.idle.stop();
        this.logout();
      }
    })
  }


  setusername() {
    let CurrentUser: any = JSON.parse(this.tokenEncryptDecrypt.decryptToken(localStorage.getItem("currentUser")));
    //let userEmail = sessionStorage.getItem("username");
    let UserName = CurrentUser.userFullName;
    this.name = UserName;
    this.name ? this.name : 'User';
    //console.log(' name', this.name);
  }


  changeFavicon(from: string) {
    switch (from) {
      case "logout":
        (<HTMLLinkElement>document.getElementById("imgFavicon")).href =
          "assets/img/favicon.ico";
        break;
      case "continue":
        (<HTMLLinkElement>document.getElementById("imgFavicon")).href =
          "favicon.ico";
        break;
    }
  }


}
