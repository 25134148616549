import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { SeriesLabels } from '@progress/kendo-angular-charts';
import { ChartSelectEvent } from 'ng2-google-charts';
import { CallserviceService } from 'src/app/CallServices/callservice.service';
@Component({
  selector: 'app-horizontal-bar-graph',
  templateUrl: './horizontal-bar-graph.component.html',
  styleUrls: ['./horizontal-bar-graph.component.css']
})
export class HorizontalBarGraphComponent implements OnInit,OnInit,OnChanges {
  @Input() dataSource: any;
  @Output() adjustmentCodeSelect   = new EventEmitter<any>();
  @Input() refresh:any;
  @Input() metaData : any;
    AdjustmentTrendData: {};
    categories: any;
    data: any;
  code: any;
  amount: any;
  isData: boolean;
  options: { xaxis: string; yaxis: string; position: string; tooltip: any[]; tooltipTitle: any[]; tooltipFormat: any[]; chartType :string };
//  public isselected =false;
  constructor(private api: CallserviceService,) { }

  ngOnInit(): void {
  }

  ngOnChanges(){
      this.options = { "xaxis": "", "yaxis": "", "position": "top", tooltip: [], tooltipTitle: [], tooltipFormat: [], chartType:"BarChart"}
      if(!isNullOrUndefined(this.dataSource)){
      if (this.dataSource.length > 0) {
        this.isData = false;
        for (var key in this.options) {
          if (this.metaData.hasOwnProperty(key)) {
            if (!(this.metaData[key] == "")) {
              this.options[key] = this.metaData[key];
            }
          }
        }
        const adjustmentChart = new Array();
        adjustmentChart[0] = [
          this.options.xaxis,
          this.options.yaxis,
          { role: "annotation" },
          { type: "string", role: "tooltip", p: { html: true } },
          { role: "style" },
        ]; 
        var tooltipval = "";
        let colon = "";
        for (let d = 0; d < this.dataSource.length; d++) {
          if (this.options.tooltip.length > 0) {
            for (let i = 0; i < this.options.tooltip.length; i++) {
              if (this.options.tooltipTitle[i] == "") {
                colon = "";
              } else {
                colon = ': ';
              }
              if (i == 0) {
                if (this.options.tooltipFormat[i] == "currency") {
                  tooltipval = '<div style="padding:5px 5px 5px 5px;text-align:left;border-style: solid;border-width: thin;border-color: #0c59e8;font-size: 12px;">' +
                    this.options.tooltipTitle[i].toString() +
                    colon + "$" + this.numberWithCommas(this.dataSource[d][this.options.tooltip[i]]);
                } else if (this.options.tooltipFormat[i] == "number") {
                  tooltipval = '<div style="padding:5px 5px 5px 5px;text-align:left;border-style: solid;border-width: thin;border-color: #0c59e8;font-size: 12px;">' +
                    this.options.tooltipTitle[i].toString() +
                    colon + this.numberWithCommas(this.dataSource[d][this.options.tooltip[i]]);
                } else {
                  tooltipval = '<div style="padding:5px 5px 5px 5px;text-align:left;border-style: solid;border-width: thin;border-color: #0c59e8;font-size: 12px;">' +
                    this.options.tooltipTitle[i].toString() +
                    colon + this.dataSource[d][this.options.tooltip[i]]
                }
              }
              else {
                if (this.options.tooltipFormat[i] == "currency") {
                  tooltipval += '<br>' + (this.options.tooltipTitle[i]).toString() + colon +  "$" + this.numberWithCommas(this.dataSource[d][this.options.tooltip[i]]);
                }
                else if (this.options.tooltipFormat[i] == "number") { 
                  tooltipval += '<br>' + (this.options.tooltipTitle[i]).toString() + colon +  this.numberWithCommas(this.dataSource[d][this.options.tooltip[i]]);
                }
                else {
                  tooltipval += '<br>' + (this.options.tooltipTitle[i]).toString() + colon + this.dataSource[d][this.options.tooltip[i]]
                }
              }
            }
            tooltipval += "</div>"
          }
          adjustmentChart[d + 1] = [
            this.dataSource[d][this.options.xaxis],
            this.dataSource[d][this.options.yaxis],
            "$" + this.numberWithCommas(this.dataSource[d][this.options.yaxis]),
            tooltipval,
            "",
          ];
          tooltipval = "";
        }
        this.AdjustmentTrendData = {
          chartType: this.options.chartType,
          dataTable: adjustmentChart,
  
          pattern: 'short',
          options: {
            title: "",
            // Top" + ' ' +this.options[0].title + ' ' + "codes
            titleTextStyle: {
              fontSize: 14
            },
            vAxis: {
              title: '',
              // format: "short",
              textStyle: {
                fontSize: 11 // or the number you want
              },
              ticks: []
            },
            pattern: 'short',
            hAxis: {
              title: '',
              format: "currency",
              gridlines: {
                count: 0
              },
  
              textStyle: {
                fontSize: 11 // or the number you want
              }
            },
            legend: { position: this.options.position },
            // height: 263,
            animation: {
              startup: true,
              duration: 1000,
              easing: "inAndOut",
            },
            chartArea: {
              // top: 30,
              width: "100%",
              // height: 263,
            },
            annotations: {
              textStyle: {
                color: 'blue',
                fontSize: 11,
              },
              stemColor: 'none',
              stem: {
                length: 5
              },
            },
            // isStacked: true
            // width: 600,
            //bar: {groupWidth: "45%"},
            tooltip: { isHtml: true },
          },
        };
    }else{
      this.isData = true;
    }
  }else{
    this.isData = true;
  }
}

  public seriesLabels: SeriesLabels = {
    visible: true, // Note that visible defaults to false
    padding: 3,
    font: 'bold 10px Arial, sans-serif'
  };

  adjustmentCodes(event) : void{
    this.adjustmentCodeSelect.emit(event);
    // this.api.isselected.next(this.isselected);
  }

  onSeriesClick(e): void {
    //;
    alert(e);
    this.adjustmentCodeSelect.emit(e);
   }

  numberWithCommas(x) {   
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  shortvalue(number) {
    if ( number ) {
      let abs;
      abs = Math.abs(number);
      if ( abs >= Math.pow( 10, 12 ) ) {
      // trillion
      number = ( number / Math.pow( 10, 12 ) ).toFixed( 1 ) + "T";
      } else if ( abs < Math.pow( 10, 12 ) && abs >= Math.pow( 10, 9 ) ) {
      // billion
      number = ( number / Math.pow( 10, 9 ) ).toFixed( 1 ) + "B";
      } else if ( abs < Math.pow( 10, 9 ) && abs >= Math.pow( 10, 6 ) ) {
      // million
      number = ( number / Math.pow( 10, 6 ) ).toFixed( 1 ) + "M";
      } else if ( abs < Math.pow( 10, 6 ) && abs >= Math.pow( 10, 3 ) ) {
      // thousand
      number = ( number / Math.pow( 10, 3 ) ).toFixed( 1 ) + "K";
      }
      return number;
      }
  }

}
function isNullOrUndefined(value: any): boolean { return value === null || value === undefined; }
