<form [formGroup]="FilterFormGroup">
    <div class="card-body">
        <div class="row">
            <div class="col-md-12 form-group" *ngIf="bIsGroup">
                <label class="form-label">Group</label>
                <kendo-combobox formControlName="fcFilterGroup" [data]="GroupList" [textField]="'groupname'"
                    [valueField]="'groupid'" [(ngModel)]="selectedGroup" (valueChange)="SelectGroup($event)"
                    [placeholder]="'Search Group'" class="w-100">
                </kendo-combobox>
            </div>
            <!--Practice1 KM  charges analysis ,Daily Tracker insurance debit,OverAllAR,PaymentAnalysis,PaymentBreakUp,PaymentWaterfall, AR Aging by payer type   -->

            <div class="col-md-12 form-group" *ngIf="bIsPractice && !bIsSinglePractice">
                <label class="form-label">Practice</label>
                <kendo-multiselect formControlName="fcFilterPractice" [data]="PracticeList" [textField]="'groupname'"
                    [placeholder]="'Select Practice'" [valueField]="'groupcode'" [(ngModel)]="selectedPractice"
                    (valueChange)="selectPractice($event)">
                </kendo-multiselect>
            </div>

            <!--Practice2  charge lag batch lag,ProviderPayments,ProviderCharges, examfinishedlag -->
            <div class="col-md-12 form-group" *ngIf="bIsPractice && bIsSinglePractice && !bisProviderdash">
                <label class="form-label">Practice</label>
                <kendo-dropdownlist formControlName="fcFilterPractice" [data]="PracticeList" [textField]="'groupname'"
                    [valueField]="'groupcode'" [(ngModel)]="selectedPractice" class="w-100">
                </kendo-dropdownlist>
            </div>

            <!-- for Single practice -->
            <!-- Practice3 provider Productivity without NPI-->
            <div class="col-md-12 form-group" *ngIf="bIsPractice && bIsSinglePractice && bisProviderdash && !bisNPI">
                <label class="form-label">Practice </label>
                <kendo-dropdownlist formControlName="fcFilterPractice" [data]="PracticeList" [textField]="'groupname'"
                    [valueField]="'groupcode'" [(ngModel)]="selectedPractice" class="w-100"
                    (valueChange)="selectPracticenew($event)">
                </kendo-dropdownlist>
            </div>

             <!-- provider Productivity with NPI-->
             <div class="col-md-12 form-group" *ngIf="bIsPractice && bIsSinglePractice && bisProviderdash && bisNPI">
                <label class="form-label">Practice </label>
                <kendo-dropdownlist formControlName="fcFilterPractice" [data]="PracticeListNPI"
                    [textField]="'groupname'" [valueField]="'groupcode'" [(ngModel)]="SelectedPractice" class="w-100"
                    (valueChange)="selectPracticenew($event)">
                </kendo-dropdownlist>
            </div>
        
            <div class="col-md-6 form-group" *ngIf="bIsMinDays">
                <label for="" class="form-label">TFL Min</label>
                <kendo-textbox id="mintxt" formControlName="fcFilterMinDays"
                    [(ngModel)]="selectedMinDays"></kendo-textbox>
            </div>
            <!--   <div class="col-md-6 " *ngIf="bIsMaxDays">
          <label for="" class="form-label">Max</label>
        </div> -->
            <div class="col-md-6 form-group" *ngIf="bIsMinDays">
                <label for="" class="form-label">TFL Max</label>
                <kendo-textbox id="mintxt" formControlName="fcmaxd" [(ngModel)]="selectedMaxDays"></kendo-textbox>
            </div>

            <div class="col-md-6 form-group" *ngIf="bIsStartDate">
                <label for="" class="form-label">Start Date</label>
                <kendo-datepicker class="w-100" [(value)]="StartdateValue"></kendo-datepicker>
            </div>
            <div class="col-md-6 form-group" *ngIf="bIsEndDate">
                <label for="" class="form-label" *ngIf="!bIsMaxDays">End Date</label>
                <label for="" class="form-label" *ngIf="bIsMaxDays">As of Date</label>
                <kendo-datepicker class="w-100" [(value)]="EnddateValue"></kendo-datepicker>
            </div>
            <div class="col-md-12 form-group" *ngIf="bIsPracticeProvider && !bisProviderdash">
                <label class="form-label">Provider</label>
                <kendo-dropdownlist formControlName="fcFilterProviderName" [data]="PracticeProviderList"
                    [textField]="'ProviderFullName'" [valueField]="'nproviderid'" [(ngModel)]="selectedProviderName"
                    class="w-100">
                </kendo-dropdownlist>
            </div>

            <!--for Single practice provider -->
            <!-- provider Productivity -->
            <div class="col-md-12 form-group" *ngIf="bisProviderdash">
                <label class="form-label">Provider</label>
                <kendo-dropdownlist formControlName="fcFilterProviderName" [data]="PracticeProviderList"
                    [textField]="'RenderingProvidersFullName'" [valueField]="'nproviderid'"
                    [(ngModel)]="selectedProviderName" class="w-100"  [disabled]="disabled"
                    (valueChange)="selectProvidernew($event)">
                </kendo-dropdownlist>
            </div>

           
            <!-- provider Productivity -->
            <div class="col-md-12 form-group" *ngIf="bIsProceduretype">
                <label class="form-label">Procedure Type</label>
                <kendo-dropdownlist formControlName="fcFilterProcedure" [data]="CPTType_List" [textField]="'text'"
                    [valueField]="'value'" [valuePrimitive]="true" [(ngModel)]="selectedProcedure"
                    (valueChange)="CPTType_Change($event)" [loading]="bIsLoaderCptTypes" class="w-100">
                </kendo-dropdownlist>
            </div>

            <!-- provider Productivity -->
            <div class="col-md-12 form-group" *ngIf="bIsProcedureval">
                <label class="form-label">Procedure</label>
                <angular2-multiselect [data]="PracticeCPTList" formControlName="fcFilterProcedureName"
                    [(ngModel)]="selectedCPTName" [settings]="CPTDropdownSettings" (onSelect)="OnCPTSelect($event)"
                    (onDeSelect)="OnCPTDeSelect($event)" (onSelectAll)="OnCPTSelectAll($event)"
                    (onDeSelectAll)="OnCPTDeSelectAll($event)"></angular2-multiselect>
            </div>










            <!-- 12 month only for Provider Productivity -->
            <div class="col-md-12 form-group" *ngIf="bIsMonthInterval && bisProviderdash ">
                <label class="form-label">Interval</label>
                <kendo-dropdownlist formControlName="fcFilterMonthInterval" [data]="MonthIntervalList"
                    [textField]="'text'" [valueField]="'value'" [(ngModel)]="selectedMonthInterval" class="w-100">
                </kendo-dropdownlist>
            </div>

            <!-- Comaprision filter of KM -->
            <div class="col-md-12 form-group" *ngIf="bIsComparision">
                <label class="form-label">Comparison</label>
                <kendo-combobox formControlName="fcFilterGroup" [data]="CompareList" [textField]="'text'"
                    [valueField]="'value'" [(ngModel)]="selectedTypeofCompare"
                    (valueChange)="selectedComparison($event)" [placeholder]="'Search Type'" class="w-100">
                </kendo-combobox>
            </div>

            <!-- 25 month only for KM -->
            <div class="col-md-12 form-group" *ngIf="bIsMonthIntervalKM">
                <label class="form-label">Interval</label>
                <kendo-dropdownlist formControlName="fcFilterMonthInterval" [data]="MonthIntervalListofKeyMetrics"
                    [textField]="'text'" [valueField]="'value'" [(ngModel)]="selectedMonthIntervalKM" class="w-100">
                </kendo-dropdownlist>
            </div>

            <div class="col-md-12 form-group" *ngIf="bIsYear">
                <label class="form-label">Year</label>
                <kendo-dropdownlist formControlName="fcFilterYear" [data]="YearList" [textField]="'text'"
                    [valueField]="'value'" [(ngModel)]="selectedYear" class="w-100">
                </kendo-dropdownlist>
            </div>

            <!-- Only for KM -->
            <div class="col-md-12 form-group" *ngIf="bIsyearNumber">
                <label class="form-label">Year</label>
                <kendo-dropdownlist formControlName="fcFilterYear" [data]="YearList" [textField]="'text'"
                    [valueField]="'value'" [(ngModel)]="selectedYear" class="w-100">
                </kendo-dropdownlist>
            </div>


            <div class="col-md-12 form-group" *ngIf="bIsMonth && !bIsSingleMonth">
                <label class="form-label">Month</label>
                <kendo-multiselect formControlName="fcFilterMonth" [data]="MonthList" [textField]="'text'"
                    [placeholder]="'Select Month'" [valueField]="'value'" [(ngModel)]="selectedMonth"
                    (valueChange)="SelectMonth($event)" class="w-100">
                </kendo-multiselect>
            </div>


            <!-- Single Month selection of KM -->
            <div class="col-md-12 form-group" *ngIf="bIsMonth && bIsSingleMonth && bIstrue">
                <label class="form-label">Month</label>
                <kendo-dropdownlist formControlName="fcFilterMonth" [data]="MonthList" [textField]="'text'"
                    [valueField]="'value'" [(ngModel)]="selectedMonth" (valueChange)="SelectMonth($event)"
                    class="w-100">
                </kendo-dropdownlist>
            </div>

            <!-- ProviderPayments,ProviderCharges -->
            <div class="col-md-12 form-group" *ngIf="bIsProvider">
                <label class="form-label">Provider Type</label>
                <kendo-dropdownlist formControlName="fcFilterProvider" [data]="ProviderList" [textField]="'text'"
                    [valueField]="'value'" [(ngModel)]="selectedProvider" [valuePrimitive]="true" class="w-100">
                </kendo-dropdownlist>
            </div>
            <div class="col-md-12 form-group" *ngIf="bIsStatus">
                <label class="form-label">Status</label>
                <kendo-dropdownlist formControlName="fcStatus" [data]="statusList" [textField]="'text'"
                    [valueField]="'value'" [(ngModel)]="selectedStatus" class="w-100">
                </kendo-dropdownlist>
            </div>
            <!-- <div class="col-md-12 form-group" *ngIf="bIsOnHold && onHoldComponent">
          <label class="form-label">AR Exclusions</label>
        <kendo-switch  formControlName="isonHoldChecked" [(ngModel)]="OnholddataChecked" onLabel="" offLabel=""
        (valueChange)="exludeOnChange($event)"></kendo-switch>&nbsp;
      <span *ngIf="Onholddata==true || Onholddata=='true'">Exclude On Hold From AR</span>
      <span *ngIf="Onholddata==false || Onholddata=='false'">Include On Hold From AR</span>
        </div> -->
        </div>
    </div>
    <div class="card-footer">
        <div class="float-right">
            <button [disabled]="IsselectedPractice" class="btn btn-success btn-sm mr-2" leftToggle
                (click)="btnApplyFilter_Click('Apply')">
                Apply
            </button>
            <button class="btn btn-secondary btn-sm" leftToggle>Cancel</button>
        </div>
    </div>
</form>