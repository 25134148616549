<kendo-grid
  [kendoGridBinding]="gridData"
  [filterable]="true"
  [sortable]="true"
  [pageSize]="pageSize"
  [pageable]="true"
  kendoGridSelectBy="id"
  [selectedKeys]="detailssearch"
>
  <ng-template kendoGridToolbarTemplate >
    <div class="d-flex">
      <h6 class="mb-0 text-uppercase">
        Expected Collection Details by {{gridlable}}
      </h6>

      <div class="ml-auto">
        <input placeholder="Search in all columns..." style="width:400px;margin-right: 5px;" kendoTextBox
        (input)="onDetailsFilter($event.target.value)" />
        <button type="button" kendoGridExcelCommand icon="file-excel">
          Export 
      </button>
      </div>
    </div>
  </ng-template>
  <kendo-grid-column field="nclaimno" title="Claim Number" width="80">
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      <div class="media">
        <div class="media-body ml-3">
          <p
            class="mt-1 mb-0 pointer"
            data-toggle="modal"
            data-target="#data"
            (click)="BindPopup(dataItem)"
          >
            {{ dataItem.nclaimno }}
          </p>
        </div>
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="BillingProvidersFullName"
    [width]="180"
    title="Billing Provider"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="RenderingProvidersFullName"
    [width]="180"
    title="Rendering Provider"
  >
  </kendo-grid-column>
  <kendo-grid-column field="FacilityName" [width]="180" title="Facility">
  </kendo-grid-column>
  <kendo-grid-column field="scptcode" [width]="140" title="CPT Code">
  </kendo-grid-column>
  <kendo-grid-column field="cptgroup" [width]="140" title="CPT Group">
  </kendo-grid-column>
  <kendo-grid-column
    field="Collection_Percentage"
    class="percentcls"
    [width]="180"
    title="Collection Percentage%"
  >
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-numeric-filter-cell
        [column]="column"
        [filter]="filter"
        operator="gte"
      >
        <kendo-filter-gt-operator></kendo-filter-gt-operator>
        <kendo-filter-gte-operator></kendo-filter-gte-operator>
        <kendo-filter-lte-operator></kendo-filter-lte-operator>
        <kendo-filter-lt-operator></kendo-filter-lt-operator>
        <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
      </kendo-grid-numeric-filter-cell>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="ChargesDate" [width]="140" title="DOS">
  </kendo-grid-column>
  <kendo-grid-column field="DOR" [width]="140" title="DOR"> </kendo-grid-column>
  <kendo-grid-column
    field="ExpectedCollectionType"
    [width]="160"
    title="Exp.Collection Type"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="Charges"
    format="{0:c}"
    [width]="140"
    title="Charges"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="inspayment"
    format="{0:c}"
    [width]="160"
    title="Insurance Payment"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="InsuranceDue"
    format="{0:c}"
    [width]="140"
    title="Insurance Due"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="patientpayment"
    format="{0:c}"
    [width]="140"
    title="Patient Payment"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="PatientDueAmount"
    format="{0:c}"
    [width]="160"
    title="Patient Due Amount"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="NetAdjustment"
    format="{0:c}"
    [width]="160"
    title="Net Adjustment"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="TotalChargePaid"
    format="{0:c}"
    [width]="160"
    title="Total Charge Paid"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="TotalChargeBalance"
    format="{0:c}"
    [width]="160"
    title="Total Charge Balance"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="NetCollectionAmount"
    format="{0:c}"
    [width]="180"
    title="Net Collection Amount"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="NetExpectedCollection"
    format="{0:c}"
    [width]="200"
    title="Net Expected Collection"
  >
  </kendo-grid-column>

  <kendo-grid-column
    field="TotalAllowedAmount"
    format="{0:c}"
    [width]="160"
    title="Allowed Ammount"
  >
  </kendo-grid-column>

  <kendo-grid-column
    field="RemittedAllowedAmount"
    format="{0:c}"
    [width]="200"
    title="Remitted Allowed Amount"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="ModeAllowedAmount"
    format="{0:c}"
    [width]="200"
    title="Mode Allowed Amount"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="ReimbursementAmount"
    format="{0:c}"
    [width]="200"
    title="Reimbursement Amount"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="ReimbursementName"
    format="{0:c}"
    [width]="200"
    title="Reimbursement Name"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="Deductable_UncontrollableAdjustmentCodes"
    format="{0:c}"
    [width]="310"
    title="Deductable/Uncontrollable Adjustment Codes"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="Deductable_UncontrollableAdjustmentAmount"
    format="{0:c}"
    [width]="320"
    title="Deductable/Uncontrollable Adjustment Amount"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="NotDeductable_UncontrollableAdjustmentCodes"
    format="{0:c}"
    [width]="320"
    title="NonDeductable/Uncontrollable Adjustment Codes"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="NotDeductable_UncontrollableAdjustmentAmount"
    format="{0:c}"
    [width]="320"
    title="NonDeductable/Uncontrollable Adjustment Amount"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="CalculatedAdjAmt"
    format="{0:c}"
    [width]="200"
    title="Calculated Adjustment Amount"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="CalculatedWriteOffType"
    format="{0:c}"
    [width]="160"
    title="Calculated W/O Type"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="dCalculatedWriteOff"
    format="{0:c}"
    [width]="160"
    title="Calculated W/O"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="BadDebtDueAmount"
    format="{0:c}"
    [width]="180"
    title="Bad Debt Due Amount"
  >
  </kendo-grid-column>
  <kendo-grid-excel
    fileName="Expected Collection Details.xlsx"
    [fetchData]="expectedCollectionSummaryexcel"
  ></kendo-grid-excel>
</kendo-grid>

<div class="col-md-3">
  <!-- Details modal -->

  <div
    class="modal fade"
    id="data"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            Claim: {{ editboxclainno }} Details
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="card card-bg">
            <div class="card-body">
              <div class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <span class="small text-muted">Claim:</span>
                    <span class="ml-2">{{ editboxclainno }}</span>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <span class="small text-muted">DOS:</span>
                    <span class="ml-2">{{ chargesdate }}</span>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <span class="small text-muted">DOR:</span>
                    <span class="ml-2">{{ DOR }}</span>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <span class="small text-muted">CPT Code:</span>
                    <span class="ml-2">{{ cptcode }}</span>
                  </div>
                </div>
                <div class="col-md-9">
                  <div class="form-group">
                    <span class="small text-muted">Practice:</span>
                    <span class="ml-2">{{ ClinicName }}</span>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <span class="small text-muted">CPT Group:</span>
                    <span class="ml-2">{{ cptgroup }}</span>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <span class="small text-muted">Billing Provider:</span>
                    <span class="ml-2">{{ billingp }}</span>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <span class="small text-muted">Rendering Provider:</span>
                    <span class="ml-2">{{ renderingp }}</span>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <span class="small text-muted"
                      >Expected Collection Type:</span
                    >
                    <span class="ml-2">{{ ExpectedCollectionType }}</span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <span class="small text-muted">Reimbursement Name:</span>
                    <span class="ml-2">{{ ReimbursementName }}</span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <span class="small text-muted">Facility:</span>
                    <span class="ml-2">{{ facility }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h5 class="mb-3">Expected Collection Calculation</h5>
          <div class="row">
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-12">
                  <div class="card">
                    <div class="card-header">
                      <h6 class="mb-0">Charges Details</h6>
                    </div>
                    <div class="card-body p-0">
                      <ul class="list-group list-group-flush">
                        <li
                          class="list-group-item d-flex justify-content-between align-items-center"
                        >
                          Charges Amount
                          <span>${{ charges }}</span>
                        </li>
                        <li
                          class="list-group-item d-flex justify-content-between align-items-center"
                        >
                          Net Adjustment
                          <span>${{ NetAdjustment }}</span>
                        </li>
                        <li
                          class="list-group-item d-flex justify-content-between align-items-center"
                        >
                          Insurance Due
                          <span>${{ InsuranceDue }}</span>
                        </li>
                        <li
                          class="list-group-item d-flex justify-content-between align-items-center"
                        >
                          Total Charge Balance
                          <span>${{ TotalChargeBalance }}</span>
                        </li>
                        <li
                          class="list-group-item d-flex justify-content-between align-items-center"
                        >
                          Non Deductable Uncontrollable Adjustment Code
                          <span>{{
                            NonDeductableUncontrollableAdjustmentCode
                          }}</span>
                        </li>
                        <li
                          class="list-group-item d-flex justify-content-between align-items-center"
                        >
                          Deductable Uncontrollable Adjustment Code
                          <span>{{
                            DeductableUncontrollableAdjustmentCode
                          }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="card mt-1">
                    <div class="card-header">
                      <h6 class="mb-0">Priorities</h6>
                    </div>
                    <div class="card-body p-0">
                      <ul class="list-group list-group-flush">
                        <li
                          class="list-group-item d-flex justify-content-between align-items-center"
                        >
                          1.Fee Schedule (Reimbursement Amount)
                          <span>${{ ReimbursementAmount }}</span>
                        </li>
                        <li
                          class="list-group-item d-flex justify-content-between align-items-center"
                        >
                          2.Mode Allowed Amount
                          <span>${{ ModeAllowedAmount }}</span>
                        </li>
                        <li
                          class="list-group-item d-flex justify-content-between align-items-center"
                        >
                          3.Remitted allowed (original allowed amount)
                          <span>${{ RemittedAllowedAmount }}</span>
                        </li>
                        <li
                          class="list-group-item d-flex justify-content-between align-items-center"
                        >
                          Total charge amount
                          <span>${{ charges }}</span>
                        </li>
                        <li class="list-group-item small pb-4">
                          Above sequence is prioritize, if all condition failed
                          then charge amount taken as final Allowed Amount
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="card">
                <div class="card-header">
                  <h6 class="mb-0">Considered</h6>
                </div>
                <div class="card-body p-0">
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                      <span class="text-orange font-size-14 font-weight-500"
                        >Net Expected Collection</span
                      >
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between align-items-center"
                    >
                      <span class="text-success"
                        >Considered: {{ ExpectedCollectionType }}</span
                      >
                      <span>${{ ExpectedCollectioncalc }}</span>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between align-items-center"
                    >
                      Patient Due
                      <span>- ${{ PatientDue }}</span>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between align-items-center"
                    >
                      Bad Debt Due Amount
                      <span> - ${{ BadDebtDueAmount }}</span>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between align-items-center"
                    >
                      Non Deductable Uncontrollable Adjustment Amount
                      <span
                        >- ${{
                          NonDeductableUncontrollableAdjustmentAmount
                        }}</span
                      >
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between align-items-center"
                    >
                      Deductable Uncontrollable Adjustment Code
                      <span>
                        - ${{ DeductableUncontrollableAdjustmentAmount }}</span
                      >
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between align-items-center"
                    >
                      <span class="text-orange ml-auto"
                        >${{ NetExpectedCollection }}</span
                      >
                    </li>
                    <!-- Net Collection Amount -->
                    <li class="list-group-item">
                      <span class="text-azure font-size-14 font-weight-500"
                        >Net Collection Amount</span
                      >
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between align-items-center"
                    >
                      Insurance Payment
                      <span>${{ InsurancePayment }}</span>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between align-items-center"
                    >
                      Patient Payment
                      <span>+ ${{ PatientPayment }}</span>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between align-items-center"
                    >
                      <span class="text-azure ml-auto">
                        + ${{ NetCollectionAmount }}</span
                      >
                    </li>
                    <!-- Collection % -->
                    <li class="list-group-item">
                      <span class="text-indigo font-size-14 font-weight-500"
                        >Collection Percentage</span
                      >
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between align-items-center"
                    >
                      (Net Collection Amount/Net Expected Collection)*100
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between align-items-center"
                    >
                      ${{ NetCollectionAmount }}/${{
                        NetExpectedCollection
                      }}*100 =
                      <span class="text-indigo"
                        >{{ CollectionPercentage }}%</span
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="col-md-12">
              <div class="card">
                <div class="card-header">
                  <h6 class="mb-0">Assign Task</h6>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="" class="form-label mb-0">User List</label>
                      <!--   <kendo-dropdownlist [data]="userlist" class="w-100">
                        </kendo-dropdownlist> -->
                        <kendo-combobox
                        [data]="userlist"
                        [textField]="'displayname'"
                        [valueField]="'email'"
                        [(ngModel)]="SelectedUser"
                        (valueChange)="selectUserEmail($event)"
                        class="w-100"
                      >
                      </kendo-combobox>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="" class="form-label mb-0">Send To</label>
                        <input type="text" class="w-100"  [(ngModel)]="SelectedUserEmail"  kendoTextBox />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="" class="form-label mb-0">Note</label>
                        <textarea
                          name=""
                          id=""
                          [(value)]="NoteText"
                          class="w-100"
                          kendoTextArea
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="SaveAssignTask()" data-dismiss="modal">
            Send
          </button>
          <button type="button" data-toggle="modal" class="btn btn-primary">Close</button>
        </div>
      </div>
    </div>
  </div>
  <!------------ Details Modal---------->
</div>
