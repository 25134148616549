import {Pipe,PipeTransform} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';


@Pipe({
    name:'safeurl'
})

export class Safeurl implements PipeTransform {
    documenturl: any;
    docName: any; 
   
    constructor(public sanitizer: DomSanitizer){
      this.documenturl = sanitizer.bypassSecurityTrustUrl(this.documenturl);
    }
    transform(documenturl) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(documenturl);
    }
  
  }