import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-common-loader',
  templateUrl: './common-loader.component.html',
  styleUrls: ['./common-loader.component.css']
})
export class CommonLoaderComponent implements OnInit {

  @Input() bIsLoader: boolean;
  @Input() LoaderLabel: any;

  constructor() { }

  ngOnInit(): void {
    this.bIsLoader=true;
    //this.LoaderLabel = "";
  }

}

