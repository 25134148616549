import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { SeriesLabels } from '@progress/kendo-angular-charts';
import { ChartSelectEvent } from 'ng2-google-charts';
import { CallserviceService } from 'src/app/CallServices/callservice.service';
@Component({
  selector: 'app-bar-vs-line',
  templateUrl: './bar-vs-line.component.html',
  styleUrls: ['./bar-vs-line.component.css']
})
export class BarVsLineComponent implements OnInit {

  @Input() dataSource: any;
  @Output() adjustmentCodeSelect   = new EventEmitter<any>();
  @Input() refresh:any;
  @Input() metaData : any;
  PredictionData: {};
    categories: any;
    data: any;
  code: any;
  amount: any;
  isData: boolean;
//  public isselected =false;
  constructor(private api: CallserviceService,) { }
  ngOnInit(): void {
  }

  ngOnChanges(){
    //;
  //   this.categories=[];
  //   this.data=[];
  //   this.code=this.metaData[0].name;
  //   this.amount = this.metaData[1].name;
  //   if(this.dataSource.length>0){
  //   for(let i=0; i<this.dataSource.length ; i++){
  //     this.categories.push(this.dataSource[i][this.code]);
  //     this.data.push(this.dataSource[i][this.amount]);
  //   }
  // }

  if(!isNullOrUndefined(this.dataSource)){
  if (this.dataSource.length > 0) {
    this.isData = false;
    const adjustmentChart = new Array();
    adjustmentChart[0] = [
      this.metaData[0].title,
      this.metaData[1].title,
      { type: "string", role: "tooltip", p: { html: true } },
      { role: "style" },
      this.metaData[2].title,
      { type: "string", role: "tooltip", p: { html: true } },
      { role: "style" },
    ];

    for (let d = 0; d < this.dataSource.length; d++) {
      adjustmentChart[d + 1] = [
         this.dataSource[d][this.metaData[0].name],
         this.dataSource[d][this.metaData[1].name],
          // "$"+  this.numberWithCommas(this.dataSource[d][this.metaData[1].name]),
        '<div style="padding:5px 5px 5px 5px;text-align:center;border-style: solid;border-width: thin;border-color: #0c59e8;font-size: 15px;">'+
        this.dataSource[d][this.metaData[0].name] +
                    "<br>" +this.metaData[1].title + ": " +
                    "$" + this.numberWithCommas(
                      this.dataSource[d][this.metaData[1].name]
                    ) +
                    "</div>",
                    "#3F84F6",
//prediction line   
                    this.dataSource[d][this.metaData[2].name],
                    //  "$"+  this.numberWithCommas(this.dataSource[d][this.metaData[2].name]),
                   '<div style="padding:5px 5px 5px 5px;text-align:center;border-style: solid;border-width: thin;border-color: #0c59e8;font-size: 15px;">'+
                   this.dataSource[d][this.metaData[0].name] +
                               "<br>" +this.metaData[2].title + ": " +
                               "$" + this.numberWithCommas(
                                 this.dataSource[d][this.metaData[2].name]
                               ) +
                               "</div>", 
                               ""           
      ];
    }
    
      this.PredictionData = {
        chartType: "ComboChart",
        dataTable: adjustmentChart,
        
        pattern: 'short',
        options: {
          title: "",
          // Top" + ' ' + this.metaData[0].title + ' ' + "codes
          titleTextStyle: { 
            fontSize: 14
        },
        curveType: 'function',
          vAxis: {
            title: '',
            // format: "short",
            textStyle : {
              fontSize: 11 // or the number you want
          },
          gridlines: {
            count: 0
          },
         
          },
          pattern: 'short',
          hAxis: {
            title: '',
            gridlines: {
              count: 0
            },
            direction:-1,slantedText:true, slantedTextAngle:45,

            textStyle : {
              fontSize: 11 // or the number you want
          }
          },
          legend: { position: "bottom" },
          // height: 263,
          animation: {
            startup: true,
            duration: 1000,
            easing: "inAndOut",
          },
          chartArea: {
            top: 5,
            width: "80%",
            height: "70%",
          },
          height:400,
          annotations: {
            textStyle: {
                color: 'blue',
                fontSize: 11,
            },
            stemColor : 'none',
            stem: {
              length: 5
            },   
       },
       seriesType: 'bars',
       series: {1: {type: 'line'}},
       lineWidth: 3,
          // isStacked: true
          // width: 600,
          //bar: {groupWidth: "45%"},
          tooltip: { isHtml: true },
        },
      };
    }else{
      this.isData=true;
    }}else{
      this.isData=true;
    }

  }


  public seriesLabels: SeriesLabels = {
    visible: true, // Note that visible defaults to false
    padding: 3,
    font: 'bold 10px Arial, sans-serif'
  };

  numberWithCommas(x) {   
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}
function isNullOrUndefined(value: any): boolean { return value === null || value === undefined; }
