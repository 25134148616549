<!-- Side Nav START -->
<div class="side-nav">
  <div class="side-nav-inner">
    <div class="side-nav-logo">
      <a href="#">
        <div class="logo logo-dark" style="background-image: url(../../../assets/img/QInsight-logo.png)">
          <!-- <img src="../../../assets/img/QInsight-logo.png" alt="" width="250" class="pt-3"> -->
        </div>
        <div class="logo logo-white">
          <img src="../../../assets/img/QInsight-logo.png" alt="" width="180" class="pt-4" />
        </div>
      </a>
      <div class="mobile-toggle side-nav-toggle">
        <a>
          <i class="fa fa-arrow-left"></i>
        </a>
      </div>
    </div>
    <ul class="side-nav-menu scrollable">
      <li class="nav-item dropdown" [hidden]="!MetricsHomeMenu" *ngIf="permission.home">
        <a class="dropdown-toggle home" routerLink="/qdashboards/home" routerLinkActive="active">
          <span class="icon-holder">
            <i class="mdi mdi-home"></i>
          </span>
          <span class="title">Home</span>
        </a>
      </li>
      <li class="nav-item dropdown" [hidden]="!ProjectsMenu">
        <a class="dropdown-toggle">
          <span class="icon-holder" style="transform: rotate(180deg); line-height: 28px;">
            <i class="ri-bar-chart-box-line"></i>
            <!-- <span class="mask-icons project-icon"></span> -->
          </span>
          <span class="title">Projects</span>
          <span class="arrow">
            <i class="fa fa-angle-right"></i>
          </span>
        </a>
        <ul class="dropdown-menu">
          <li *ngIf="permission.MyProjects">
            <a routerLink="/qdashboards/MyProjects">
              <!-- <i class="mdi mdi-rhombus-medium"></i> -->
              My Projects
              <!-- <div class="badge bg-blue-lt float-right">Beta</div> -->
            </a>
          </li>
          <li *ngIf="permission.ProjectInsight">
            <a routerLink="/qdashboards/ProjectInsight">
              <!-- <i class="mdi mdi-rhombus-medium"></i> -->
              Project Insight
              <!-- <div class="badge bg-blue-lt float-right">Beta</div> -->
            </a>
          </li>
        </ul>
      </li>
      <div class="dropdown-divider" *ngIf="MetricsHomeMenu"></div>


      <li class="nav-item dropdown" [hidden]="!MetricsmainMenu">
        <a class="dropdown-toggle">
          <span class="icon-holder">
            <i class="ri-currency-line"></i>
          </span>
          <span class="title">Financial Metrics</span>
          <span class="arrow">
            <i class="fa fa-angle-right"></i>
          </span>
        </a>
        <ul class="dropdown-menu">
          <li *ngIf="permission.keyMetrics" routerLink="/financialmetrics/keyMetrics" routerLinkActive="active">
            <a>
              <!-- <i class="mdi mdi-rhombus-medium"></i> -->
              <!-- (click)="navtoDashboard()" -->
              Key Metrics
            </a>
          </li>
          <li *ngIf="permission.ProviderProductivity" routerLink="/financialmetrics/ProviderProductivity"
            routerLinkActive="active">
            <a>
              <!-- <i class="mdi mdi-rhombus-medium"></i> -->
              Provider Productivity
              <!-- <div class="badge bg-blue-lt float-right">Beta</div> -->
            </a>
          </li>
          <li *ngIf="permission.Financialproductivity" routerLink="/financialmetrics/Financialproductivity"
            routerLinkActive="active">
            <a>
              Financial Productivity
            </a>
          </li>
          <li *ngIf="permission.ChargesAnalysis" routerLink="/financialmetrics/ChargesAnalysis"
            routerLinkActive="active">
            <a>
              <!-- <i class="mdi mdi-rhombus-medium"></i> -->
              Charges Analysis
            </a>
          </li>
          <li *ngIf="permission.paymentAnalysis" routerLink="/financialmetrics/paymentAnalysis"
            routerLinkActive="active">
            <a>
              <!-- <i class="mdi mdi-rhombus-medium"></i> -->
              Payment Analysis
            </a>
          </li>
          <li *ngIf="permission.RefundAnalysis" routerLink="/financialmetrics/RefundAnalysis" routerLinkActive="active">
            <a>
              <!-- <i class="mdi mdi-rhombus-medium"></i> -->
              Refund Analysis
              <div class="badge bg-green-lt float-right">New</div>
            </a>
          </li>
          <!-- <li *ngIf="permission.TakebackAnalysis" routerLink="/qdashboards/TakebackAnalysis" routerLinkActive="active">
            <a>
              Take Back Analysis
              <div class="badge bg-green-lt float-right">New</div>
            </a>
          </li> -->
          <li *ngIf="permission.DailyTracker" routerLink="/financialmetrics/DailyTracker" routerLinkActive="active">
            <a>
              <!-- <i class="mdi mdi-rhombus-medium"></i> -->
              Daily Tracker
            </a>
          </li>
          <!-- <li *ngIf="permission.PaymentBreakUP" routerLink="/qdashboards/PaymentBreakUP" routerLinkActive="active">
            <a>
              Payment Break UP
            </a>
          </li>
          <li *ngIf="permission.Paymentwaterfall" routerLink="/qdashboards/Paymentwaterfall" routerLinkActive="active">
            <a>
              Payment Waterfall
            </a>
          </li> -->

          <li *ngIf="permission.OverallARAndbreakup" routerLink="/financialmetrics/OverallARAndbreakup"
            routerLinkActive="active">
            <a>
              <!-- <i class="mdi mdi-rhombus-medium"></i> -->
              Overall AR
            </a>
          </li>
          <li *ngIf="permission.InsuranceDebitARAging" routerLink="/financialmetrics/InsuranceDebitARAging"
            routerLinkActive="active">
            <a>
              <!-- <i class="mdi mdi-rhombus-medium"></i> -->
              <!--  Insurance Debit AR Aging -->
              AR Aging by Payer Type
            </a>
          </li>
          <!--    <li * ngIf="permission.ChemononChemo"  routerLinkActive="active">
            <a routerLink="/qdashboards/ChemoNonChemo">
              <i class="mdi mdi-rhombus-medium"></i>
             Chemo/Non Chemo by Provider
            </a>
          </li> -->
          <!-- <li * ngIf="permission.ProviderYTD"  routerLinkActive="active">
            <a routerLink="/qdashboards/ProviderYTD">
              <i class="mdi mdi-rhombus-medium"></i>
              Billing Provider YTD
            </a>
          </li>
          <li * ngIf="permission.RenderingProviderYTD"  routerLinkActive="active">
            <a routerLink="/qdashboards/RederingProviderYtd">
              <i class="mdi mdi-rhombus-medium"></i>
              Rendering Provider YTD
            </a>
          </li> -->
          <li *ngIf="permission.ProviderYTD" routerLink="/financialmetrics/ProviderYTD" routerLinkActive="active">
            <a>
              <!-- <i class="mdi mdi-rhombus-medium"></i> -->
              Provider YTD
            </a>
          </li>
          <li *ngIf="permission.ProviderCharges" routerLink="/financialmetrics/ProviderCharges"
            routerLinkActive="active">
            <a>
              <!-- <i class="mdi mdi-rhombus-medium"></i> -->
              Provider Charges
            </a>
          </li>
          <li *ngIf="permission.ProviderPayments" routerLink="/financialmetrics/ProviderPayments"
            routerLinkActive="active">
            <a>
              <!-- <i class="mdi mdi-rhombus-medium"></i> -->
              Provider Payments
            </a>
          </li>


          <!-- <li *ngIf="permission.InsuranceARComparision" routerLink="/qdashboards/InsuranceARComparision"
            routerLinkActive="active">
            <a>
              Insurance AR Comparision
            </a>
          </li> -->

          <!--<li
            *ngIf="permission.Financialproductivity"
            routerLink="/qdashboards/Productivity"
            routerLinkActive="active"
          >
            <a
              ><i class="mdi mdi-rhombus-medium"></i> Financial Productivity
              <div class="badge badge-pill badge-primary ml-1">Beta</div></a
            >
          </li>-->
          <!-- <li *ngIf="permission.Financialproductivity" routerLink="/qdashboards/FinancialProductivity"
          routerLinkActive="active">
          <a>
            Financial Productivity
          </a>
        </li> -->

          <!--
          <li

          routerLink="/qdashboards/QinsightUsage"
          routerLinkActive="active"
        >
          <a
            ><i class="mdi mdi-rhombus-medium"></i> Qinsight Dashboard Usage
            </a >
        </li> -->

          <!-- <li
          *ngIf="permission.FinancialAR"
            routerLink="/qdashboards/AR"
            routerLinkActive="active"
          >
            <a
              ><i class="mdi mdi-rhombus-medium"></i> AR
              <div class="badge badge-pill badge-primary ml-1">Beta</div></a
            >
          </li> -->

          <!-- <li routerLink="/qdashboards/TESTPYTHON">
            <a><i class="mdi mdi-rhombus-medium"></i>Test Dashboard</a>
          </li> -->
        </ul>
      </li>
      <!-- [ngClass]="{'open': isDropdownOpen}" -->
      <li class="nav-item dropdown" [hidden]="!RCMMainMenu">
        <a class="dropdown-toggle">
          <span class="icon-holder">
            <i class="ri-exchange-dollar-fill"></i>
            <!-- <span class="mask-icons rcm-icon"></span> -->
          </span>
          <span class="title">RCM</span>
          <span class="arrow">
            <i class="fa fa-angle-right"></i>
          </span>
        </a>
        <ul class="dropdown-menu">
          <!-- <li *ngIf="permission.ChargesVsPayment" routerLink="/qdashboards/ChargeVsPayment" routerLinkActive="active">
            <a>
              Charge vs Payment
            </a>
          </li> -->
          <li *ngIf="permission.Acountrecivable" routerLink="/RCM/Acountrecivable" routerLinkActive="active">
            <a>

              Account Receivable
            </a>
          </li>
          <!-- <li
            *ngIf="permission.DenialAnalysis"
            routerLink="/qdashboards/DenialAnalysis"
            routerLinkActive="active"
          >
            <a>
              <i class="mdi mdi-rhombus-medium"></i>
              Denial Analysis
            </a>
          </li> -->

          <!-- <li *ngIf="permission.DenialAnalysis" routerLink="/qdashboards/DenialAnalysis" routerLinkActive="active">
            <a>
              Denial Analysis
            </a>
          </li> -->

          <li *ngIf="permission.DenialsHistory" routerLink="/RCM/DenialsHistory" routerLinkActive="active">
            <a>
              Denial History
              <!-- <div class="badge bg-green-lt float-right">New</div> -->
            </a>
          </li>
          <li *ngIf="permission.DenialManagement" routerLink="/RCM/DenialManagement" routerLinkActive="active">
            <a>
              Denial Management
              <!-- <div class="badge bg-green-lt float-right">New</div> -->
            </a>
          </li>

          <li *ngIf="permission.MissingCharges" routerLink="/RCM/MissingCharges" routerLinkActive="active">
            <a>
              Missing Charges
              <!-- <div class="badge bg-green-lt float-right">New</div> -->
            </a>
          </li>
          <li *ngIf="permission.MonthlyCharges" routerLink="/RCM/MonthlyCharges" routerLinkActive="active">
            <a>
              Monthly Charges
              <!-- <div class="badge bg-green-lt float-right">New</div> -->
            </a>
          </li>
          <li *ngIf="permission.MonthlyClose" routerLink="/RCM/MonthlyClose" routerLinkActive="active">
            <a>
              Monthly Close
              <!-- <div class="badge bg-green-lt float-right">New</div> -->
            </a>
          </li>
          <li *ngIf="permission.MissedOpportunities" routerLink="/RCM/MissedOpportunities" routerLinkActive="active">
            <a>
              Missed Opportunities
              <!-- <div class="badge bg-green-lt float-right">New</div> -->
            </a>
          </li>
          <li *ngIf="permission.MtdYtdReport" routerLink="/RCM/MtdYtdReport" routerLinkActive="active">
            <a>
              MTD YTD Report

            </a>
          </li>
          <li *ngIf="permission.DailyCharges" routerLink="/RCM/DailyCharges" routerLinkActive="active">
            <a>
              Daily Charges

            </a>
          </li>
          <li *ngIf="permission.DailyClose" routerLink="/RCM/DailyClose" routerLinkActive="active">
            <a>
              Daily Close

            </a>
          </li>
          <li *ngIf="permission.MonthlyPayment" routerLink="/RCM/MonthlyPayment" routerLinkActive="active">
            <a>
              Monthly Payment
            </a>
          </li>
          <!-- <li *ngIf="permission.allrcmProductivity">
            <a routerLink="/RCM/allrcmProductivity">
              RCM Productivity
            </a>
          </li> -->
          <li *ngIf="permission.rcmProductivity">
            <a routerLink="/RCM/rcmProductivity">
              RCM Productivity
              <!-- <div class="badge bg-green-lt float-right">New</div> -->
            </a>
          </li>

          <li *ngIf="permission.dellclaimexport">
            <a routerLink="/RCM/dellclaimexport">
              Ins Bal AR
              <!-- <div class="badge bg-green-lt float-right">New</div> -->
            </a>
          </li>
          <li *ngIf="permission.erapaymentposting">
            <a routerLink="/RCM/erapaymentposting">
              ERA Ready To Post
              <!-- <div class="badge bg-green-lt float-right">New</div> -->
            </a>
          </li>

          <li *ngIf="permission.Insurancependingchecks">
            <a routerLink="/RCM/Insurancependingchecks">
              Insurance Pending checks
              <!-- <div class="badge bg-green-lt float-right">New</div> -->
            </a>
          </li>


          <!-- <li
            *ngIf="permission.QPMholddashboard"
            routerLink="/RCM/QPMHold"
            routerLinkActive="active"
          >
            <a>
              <i class="mdi mdi-rhombus-medium"></i>
              Qpm Hold Inventory
             
            </a>
          </li> -->
          <li *ngIf="permission.QPMholddashboard" routerLink="/RCM/QPMholddashboard" routerLinkActive="active">
            <a>
              On Hold Claims
              <!-- <div class="badge bg-green-lt float-right">New</div> -->
            </a>
          </li>
          <!-- <li *ngIf="permission.ClaimAtRisk">
            <a routerLink="/RCM/claimatrisk">           
              Claim At Risk       
            </a>
          </li> -->
          <li *ngIf="permission.TimelyFiling">
            <a routerLink="/RCM/TimelyFiling">
              Timely Filing
              <!-- <div class="badge bg-green-lt float-right">New</div> -->
            </a>
          </li>

          <li *ngIf="permission.CPTAnalysis">
            <a routerLink="/RCM/CPTAnalysis">
              CPT Analysis
            </a>
          </li>

          <li *ngIf="permission.RCMWaterfall" routerLink="/RCM/RCMWaterfall" routerLinkActive="active">
            <a>
              <!-- <i class="mdi mdi-rhombus-medium"></i> -->
              Financial Cash Flow
            </a>
          </li>
          <li *ngIf="permission.RevenueCycle" routerLink="/RCM/RevenueCycle" routerLinkActive="active">
            <a>
              <!-- <i class="mdi mdi-rhombus-medium"></i> -->
              Revenue Cycle
            </a>
          </li>
          <li *ngIf="permission.ExpectedCollection" routerLink="/RCM/ExpectedCollection" routerLinkActive="active">
            <a>
              <!-- <i class="mdi mdi-rhombus-medium"></i> -->
              Expected Collection
            </a>
          </li>

          <li *ngIf="permission.CPTExpectedCollection" routerLink="/RCM/CPTExpectedCollection"
            routerLinkActive="active">
            <a>
              CPT-Expected Collection
              <!-- <div class="badge bg-green-lt float-right">New</div> -->
            </a>
          </li>
          <li *ngIf="permission.PanelPayerDistribution" routerLink="/RCM/PanelPayerDistribution"
            routerLinkActive="active">
            <a>
              <!-- <i class="mdi mdi-rhombus-medium"></i> -->
              Panel Payer Distribution
            </a>
          </li>
          <li *ngIf="permission.paneldistribution" routerLink="/RCM/paneldistribution" routerLinkActive="active">
            <a>
              <!-- <i class="mdi mdi-rhombus-medium"></i> -->
              Panel Distribution
            </a>
          </li>
          <li *ngIf="permission.LocationFinancialPayment" routerLink="/RCM/LocationFinancialPayment"
            routerLinkActive="active">
            <a>
              <!-- <i class="mdi mdi-rhombus-medium"></i> -->
              Location Wise Financial Payment
            </a>
          </li>
          <li *ngIf="permission.RCMDayWisePayerPayment">
            <a routerLink="/RCM/RCMDayWisePayerPayment">
              <!-- <i class="mdi mdi-rhombus-medium"></i> -->
              <!-- Day Wise Payer Payment -->
              RCM Day Wise Payer Payment
            </a>
          </li>

          <li *ngIf="permission.ClaimNotWorkedUpon" routerLink="/RCM/ClaimNotWorkedUpon" routerLinkActive="active">
            <a>
              <!-- <i class="mdi mdi-rhombus-medium"></i> -->
              Claim Not Worked Upon
              <!-- <div class="badge badge-pill badge-primary ml-1">Beta</div> -->
            </a>
          </li>
          <li *ngIf="permission.PatientPayment" routerLink="/RCM/PatientPayment" routerLinkActive="active">
            <a>
              <!-- <i class="mdi mdi-rhombus-medium"></i> -->
              Patient Payment
              <!-- <div class="badge bg-green-lt float-right">New</div> -->
            </a>
          </li>

          <li *ngIf="permission.PracticeCompensation" routerLink="/RCM/PracticeCompensation" routerLinkActive="active">
            <a>
              <!-- <i class="mdi mdi-rhombus-medium"></i> -->
              Practice Compensation
              <!-- <div class="badge bg-blue-lt float-right">Beta</div> -->
            </a>
          </li>
          <li *ngIf="permission.ProviderCompensation" routerLink="/RCM/ProviderCompensation" routerLinkActive="active">
            <a>
              <!-- <i class="mdi mdi-rhombus-medium"></i> -->
              Provider Compensation
              <!-- <div class="badge bg-blue-lt float-right">Beta</div> -->
            </a>
          </li>
          <li *ngIf="permission.ProviderCompensationbyPOS" routerLink="/RCM/ProviderCompensationbyPOS"
            routerLinkActive="active">
            <a>
              <!-- <i class="mdi mdi-rhombus-medium"></i> -->
              Provider Comp by CPT, Facility
              <!-- <div class="badge bg-green-lt float-right">New</div> -->
            </a>
          </li>

          <li *ngIf="permission.AcountrecivableinDash" routerLink="/RCM/AcountrecivableinDash"
            routerLinkActive="active">
            <a>
              <!-- <i class="mdi mdi-rhombus-medium"></i> -->
              Account Receivable Dash
            </a>
          </li>
          <li *ngIf="permission.DailyChargeDetails">
            <a routerLink="/RCM/DailyChargeDetails">
              <!-- <i class="mdi mdi-rhombus-medium"></i> -->
              Daily Charge Details
              <!-- <div class="badge badge-pill badge-primary">Beta</div> -->
            </a>
          </li>
          <li *ngIf="permission.chargebatchLag">
            <a routerLink="/RCM/chargebatchLag">
              <!-- <i class="mdi mdi-rhombus-medium"></i> -->
              Charge Lag And Batch Lag
              <!-- <div class="badge badge-pill badge-primary">Beta</div> -->
            </a>
          </li>
          <li *ngIf="permission.ExamFinishLag">
            <a routerLink="/RCM/ExamFinishLag">
              <!-- <i class="mdi mdi-rhombus-medium"></i> -->
              Charge Lag And Exam Finished Lag
              <!-- <div class="badge badge-pill badge-primary">Beta</div> -->
            </a>
          </li>
          <!--  <li *ngIf="permission.ExamFinishLag" >
            <a routerLink="/RCM/KeyPerformance">
              <i class="mdi mdi-rhombus-medium"></i> 
           Key Performance
              <div class="badge badge-pill badge-primary">Beta</div>
            </a>
          </li> -->
          <li *ngIf="permission.Metric">
            <a routerLink="/RCM/Metric">
              <!-- <i class="mdi mdi-rhombus-medium"></i> -->
              Metrics
              <!-- <div class="badge badge-pill badge-primary ml-1">Beta</div> -->
            </a>
          </li>

          <li *ngIf="permission.AgedPayments">
            <a routerLink="/RCM/AgedPayments">
              <!-- <i class="mdi mdi-rhombus-medium"></i> -->
              Aged Payments
              <!-- <div class="badge badge-pill badge-primary ml-1">Beta</div> -->
            </a>
          </li>
          <li *ngIf="permission.rcmanalytics">
            <a routerLink="/RCM/rcmanalytics">
              RCM Analytics
              <!-- <div class="badge bg-green-lt float-right">New</div> -->
            </a>
          </li>
          <li *ngIf="permission.chargeliquidation">
            <a routerLink="/RCM/chargeliquidation">
              Charge Liquidation
              <!-- <div class="badge bg-green-lt float-right">New</div> -->
            </a>
          </li>
          <li *ngIf="permission.availablereserve">
            <a routerLink="/RCM/availablereserve">
              Available Reserve
              <!-- <div class="badge bg-green-lt float-right">New</div> -->
            </a>
          </li>
          <!-- <li *ngIf="permission.availablereserve">
            <a routerLink="/RCM/patienttracking">
              Patient Status Tracking 
              <div class="badge bg-green-lt float-right">New</div>
            </a>
          </li> -->
          <li *ngIf="permission.appointcensusreport">
            <a routerLink="/RCM/appointcensusreport">
              Appointment Census Report
              <div class="badge bg-green-lt float-right">New</div>
            </a>
          </li>

          <!-- <li>
            <a routerLink="/RCM/denial">
            <i class="mdi mdi-rhombus-medium"></i> Denial
              <div class="badge badge-pill badge-primary ml-1">Beta</div></a>
          </li> -->
        </ul>

      </li>
      <li class="nav-item dropdown" [hidden]="!Biotechmainmenu">
        <a class="dropdown-toggle">
          <span class="icon-holder">
            <i class="mdi mdi-dna"></i>
          </span>
          <span class="title">BIOTECH</span>
          <span class="arrow">
            <i class="fa fa-angle-right"></i>
          </span>
        </a>
        <ul class="dropdown-menu">
          <li *ngIf="permission.BatchDistribution">
            <a routerLink="/qdashboards/BatchDistribution">
              <!-- <i class="mdi mdi-rhombus-medium"></i> -->
              Batch Distribution
            </a>
          </li>
          <li *ngIf="permission.BiotechConfiguration">
            <a routerLink="/qdashboards/BiotechConfiguration">
              <!-- <i class="mdi mdi-rhombus-medium"></i> -->
              BIOTECH Configuration
            </a>
          </li>
          <li *ngIf="permission.IncompleteOrder">
            <a routerLink="/qdashboards/IncompleteOrder">
              <!-- <i class="mdi mdi-rhombus-medium"></i> -->
              Incomplete Order
            </a>
          </li>
          <!-- <li *ngIf="permission.Insurancefollowup">
            <a routerLink="/qdashboards/Insurancefollowup">
              Insurance follow-up
            </a>
          </li> -->
          <li *ngIf="permission.Trizetto">
            <a routerLink="/qdashboards/Trizetto">
              <!-- <i class="mdi mdi-rhombus-medium"></i> -->
              Batch - TRIZETTO
            </a>
          </li>

          <!-- <li *ngIf="permission.BiotechDayWisePayerPayment">
            <a routerLink="/qdashboards/BiotechDayWisePayerPayment">
              <i class="mdi mdi-rhombus-medium"></i>
              Day Wise Payer Payment</a
            >
          </li> -->

          <li *ngIf="permission.RejectionRate">
            <a routerLink="/qdashboards/RejectionRate">
              <!-- <i class="mdi mdi-rhombus-medium"></i> -->
              Rejection Rate
            </a>
          </li>


          <!-- Not in Used -->
          <!-- <li *ngIf="permission.Biotechproductivity">
            <a routerLink="/qdashboards/BiotechProdutivity">
             Biotech Productivity
             <div class="badge bg-green-lt float-right">New</div>
            </a>
          </li> -->
        </ul>
      </li>
      <li class="nav-item dropdown" [hidden]="!BanqMainMenu">
        <a class="dropdown-toggle">
          <span class="icon-holder">
            <i class="ri-organization-chart"></i>
            <!-- <span class="mask-icons hub-icon"></span> -->
          </span>
          <span class="title">HUB</span>
          <span class="arrow">
            <i class="fa fa-angle-right"></i>
          </span>
        </a>
        <ul class="dropdown-menu">
          <li *ngIf="permission.ERAClientDistribution" routerLinkActive="active">
            <a routerLink="/qdashboards/ERAClientDistribution">
              <!-- <i class="mdi mdi-rhombus-medium"></i> -->
              ERA Client Distribution
            </a>
          </li>
          <li *ngIf="permission.ERADaywiseDistribution">
            <a routerLink="/qdashboards/ERADaywiseDistribution">
              <!-- <i class="mdi mdi-rhombus-medium"></i> -->
              ERA Daywise Distribution
            </a>
          </li>
          <li *ngIf="permission.ERADivisionDistribution">
            <a routerLink="/qdashboards/ERADivisionDistribution">
              <!-- <i class="mdi mdi-rhombus-medium"></i> -->
              ERA Division Distribution
            </a>
          </li>
          <li *ngIf="permission.checkdistribution">
            <a routerLink="/qdashboards/checkdistribution">
              <!-- <i class="mdi mdi-rhombus-medium"></i> -->
              Check Distribution
            </a>
          </li>
          <!--       <li *ngIf="permission.filedistribution" >
            <a routerLink="/qdashboards/FileDistribution">
              <i class="mdi mdi-rhombus-medium"></i>
              File Distribution
            </a>
          </li> -->
          <li *ngIf="permission.filedistribution">
            <a routerLink="/qdashboards/filedistribution">
              <!-- <i class="mdi mdi-rhombus-medium"></i> -->
              File Distribution
            </a>
          </li>
          <li *ngIf="permission.practicesummary">
            <a routerLink="/qdashboards/practicesummary">
              <!-- <i class="mdi mdi-rhombus-medium"></i> -->
              EDI HUB Summary
            </a>
          </li>
          <li *ngIf="permission.DayWisePayerPayment">
            <a routerLink="/qdashboards/DayWisePayerPayment">
              <!-- <i class="mdi mdi-rhombus-medium"></i> -->
              <!-- Day Wise Payer Payment -->
              HUB Day Wise Payer Payment
            </a>
          </li>
          <li *ngIf="permission.honestreport">
            <a routerLink="/qdashboards/honestreport">
              Honest-Medicare Reconciliation
              <!-- <div class="badge bg-green-lt float-right">New</div> -->
            </a>
          </li>
          <li *ngIf="permission.newfiledistribution">
            <a routerLink="/qdashboards/newfiledistribution">
              New File Distribution
              <div class="badge bg-green-lt float-right">New</div>
            </a>
          </li>
          <li *ngIf="permission.mhphubreport">
            <a routerLink="/qdashboards/mhphubreport">
              MHP QSuite-HUB Payment Reconciliation
              <div class="badge bg-green-lt float-right">New</div>
            </a>
          </li>
        </ul>
      </li>

      <li class="nav-item dropdown" [hidden]="!MIUMainMenu">
        <a class="dropdown-toggle">
          <span class="icon-holder">
            <i class="fa fa-hand-holding-medical"></i>
            <!-- <span class="mask-icons value-icon"></span> -->
          </span>
          <span class="title">MIU</span>
          <span class="arrow">
            <i class="fa fa-angle-right"></i>
          </span>
        </a>
        <ul class="dropdown-menu">
          <li *ngIf="permission.keyMetricsMIU">
            <a routerLink="/miu/keyMetricsMIU">
              Key Metrics
              <!-- <div class="badge bg-green-lt float-right">New</div> -->
            </a>
          </li>
          <li *ngIf="permission.MIUproductivity">
            <a routerLink="/miu/MIUproductivity">
              MIU Productivity
              <!-- <div class="badge bg-green-lt float-right">New</div> -->
            </a>
          </li>
          <li *ngIf="permission.chargesAnalysisMIU">
            <a routerLink="/miu/chargesAnalysisMIU">
              Charges Analysis
              <!-- <div class="badge bg-green-lt float-right">New</div> -->
            </a>
          </li>
          <li *ngIf="permission.paymentAnalysisMIU">
            <a routerLink="/miu/paymentAnalysisMIU">
              Payment Analysis
              <!-- <div class="badge bg-green-lt float-right">New</div> -->
            </a>
          </li>
          <li *ngIf="permission.ExpectedConfigMIU">
            <a routerLink="/miu/ExpectedConfigMIU">
              Expected Value MIU
              <!-- <div class="badge bg-green-lt float-right">New</div> -->
            </a>
          </li>
          <li *ngIf="permission.MIUdailyTracker">
            <a routerLink="/miu/MIUdailyTracker">
              MIU Daily Tracker
              <!-- <div class="badge bg-green-lt float-right">New</div> -->
            </a>
          </li>
          <li *ngIf="permission.ARMIUanalysis">
            <a routerLink="/miu/ARMIUanalysis">
              MIU Account Receivable
            </a>
          </li>
          <li *ngIf="permission.miufinancialprod">
            <a routerLink="/miu/miufinancialprod">
              MIU Financial Productivity
            </a>
          </li>
          <li *ngIf="permission.MiuDenialManagement">
            <a routerLink="/miu/MiuDenialManagement">
              MIU Denial Management
            </a>
          </li>
        </ul>
      </li>

      <li class="nav-item dropdown" [hidden]="!mainMonitor">
        <a class="dropdown-toggle">
          <span class="icon-holder">
            <!-- <i class="fas fa-magic"></i> -->
            <i class="fab fa-battle-net"></i>
            <!-- <i class="mdi mdi-monitor-multiple"></i> -->
          </span>
          <span class="title">Monitor</span>
          <span class="arrow">
            <i class="fa fa-angle-right"></i>
          </span>
        </a>
        <ul class="dropdown-menu">
          <!-- <li *ngIf="permission.Services">
            <a routerLink="/qdashboards/Services">
              Data Services
            </a>
          </li> -->
          <li *ngIf="permission.productusage">
            <a routerLink="/qdashboards/productusage">
              Product Usage Analytics
              <!-- <div class="badge bg-blue-lt float-right">Beta</div> -->
            </a>
          </li>

        </ul>
      </li>
      <li class="nav-item dropdown" [hidden]="!AdhocMainMenu">
        <a class="dropdown-toggle">
          <span class="icon-holder">
            <i class="mdi mdi-chart-scatter-plot-hexbin"></i>
          </span>
          <span class="title">Adhoc Reports</span>
          <span class="arrow">
            <i class="fa fa-angle-right"></i>
          </span>
        </a>
        <ul class="dropdown-menu">
          <li *ngIf="permission.CPTAllowedvsASPPricing">
            <a routerLink="/qdashboards/CPTAllowedvsASPPricing">
              <!-- <i class="mdi mdi-rhombus-medium"></i> -->
              CPT Allowed vs ASP Pricing
            </a>
          </li>
          <li *ngIf="permission.MRIDetails">
            <a routerLink="/qdashboards/MRIDetails">
              MRI Details
            </a>
          </li>
        </ul>
      </li>

      <!-- <li class="nav-item dropdown" *ngIf="IsHrsa">
        <a class="dropdown-toggle">
          <span class="icon-holder">
            <i class="ti ti-clipboard"></i>
          </span>
          <span class="title">COVID Ins HRSA Roster</span>
          <span class="arrow">
            <i class="fa fa-angle-right"></i>
          </span>
        </a>
        <ul class="dropdown-menu">

          <li>
            <a routerLink="/qdashboards/hrsa">
              <i class="mdi mdi-rhombus-medium"></i>
              COVID Ins HRSA Roster
            </a>
          </li>
        </ul>
      </li> -->

      <li class="nav-item dropdown" [hidden]="!ClinicalMain">
        <a class="dropdown-toggle">
          <span class="icon-holder">
            <i class="ri-hospital-line"></i>
          </span>
          <span class="title">Clinical</span>
          <span class="arrow">
            <i class="fa fa-angle-right"></i>
          </span>
        </a>
        <ul class="dropdown-menu">
          <li *ngIf="mipsSeconMenu">
            <a class="dropdown-toggle">
              <span class="title">MIPS Dashboard</span>
              <span class="arrow">
                <i class="fa fa-angle-right"></i>
              </span>
            </a>
            <ul class="dropdown-menu">
              <li *ngIf="permission.QualityDashboard2021">
                <a routerLink="/qdashboards/QualityDashboard2021">
                  Quality Dashboard 2021
                  <div class="badge bg-blue-lt float-right">Beta</div>
                </a>
              </li>
              <li *ngIf="permission.QualityDashboard2021PatientSummary">
                <a routerLink="/qdashboards/QualityDashboard2021PatientSummary">
                  <i class="mdi mdi-rhombus-medium"></i>
                  Quality Dashboard 2021 Patient Summary
                  <div class="badge bg-blue-lt float-right">Beta</div>
                </a>
              </li>
            </ul>
          </li>
          <!-- <li *ngIf="permission.Covid">
            <a routerLink="/qdashboards/Covid">

              Covid-19
            </a>
          </li> -->
            <li *ngIf="permission.patienttracking">
            <a routerLink="/qdashboards/patienttracking">
              Patient Status Tracking 
              <div class="badge bg-green-lt float-right">New</div>
            </a>
          </li>
          <li *ngIf="permission.surgicalordertracking">
            <a routerLink="/qdashboards/surgicalordertracking">
              Surgical Order Tracking
              <div class="badge bg-green-lt float-right">New</div>
            </a>
          </li>
        </ul>
      </li>

      <li class="nav-item dropdown" [hidden]="!QBOTMainMenu">
        <a class="dropdown-toggle">
          <span class="icon-holder">
            <i class="ti ti-support"></i>
          </span>
          <span class="title">QBots Monitoring</span>
          <span class="arrow">
            <i class="fa fa-angle-right"></i>
          </span>
        </a>
        <ul class="dropdown-menu">
          <li *ngIf="permission.qbotdashboard">
            <a class="dropdown-toggle" routerLink="/qdashboards/qbotdashboard">
              <span class="icon-holder">
                <!-- <i class="ti-layout"></i> -->
              </span>
              <span class="title"> QBots Dashboard</span>
            </a>
          </li>
          <li *ngIf="permission.dashboardloadingtime">
            <a class="dropdown-toggle" routerLink="/qdashboards/dashboardloadingtime">
              <span class="icon-holder">
                <!-- <i class="ti-layout"></i> -->
              </span>
              <span class="title"> Dashboard Loading Time</span>
            </a>
          </li>
          <li *ngIf="permission.dashboardupdatedtime">
            <a class="dropdown-toggle" routerLink="/qdashboards/dashboardupdatedtime">
              <span class="icon-holder">
                <!-- <i class="ti-layout"></i> -->
              </span>
              <span class="title">Practice Updated Time</span>
            </a>
          </li>
        </ul>
      </li>

      <!-- <li *ngIf="permission.serviceautomation">
            <a routerLink="/qdashboards/ServiceAutomation">
                Service Automation
              <div class="badge bg-blue-lt float-right">Beta</div>
            </a>
          </li> -->


      <li class="nav-item dropdown" [hidden]="!QAdminMainmenu">
        <a class="dropdown-toggle">
          <span class="icon-holder">
            <i class="ri-settings-5-line"></i>
          </span>
          <span class="title">Configurations</span>
          <span class="arrow">
            <i class="fa fa-angle-right"></i>
          </span>
        </a>
        <ul class="dropdown-menu">
          <!-- <li *ngIf="permission.QAdminPracticeConfiguration">
            <a routerLink="/qdashboards/Alertconfig">            
              Alert Configuration            
            </a>
          </li> -->


          <li *ngIf="permission.QAdminPracticeConfiguration">
            <a routerLink="/qdashboards/QAdminPracticeConfiguration">
              Practice Configuration
            </a>
          </li>

          <li *ngIf="permission.ModelConfiguration">
            <a routerLink="/qdashboards/ModelConfiguration">
              Model Configuration
            </a>
          </li>
          <li *ngIf="permission.ProviderConfiguration">
            <a routerLink="/qdashboards/ProviderConfiguration">
              <!-- <i class="mdi mdi-rhombus-medium"></i> -->
              Provider Configuration
              <!-- <div class="badge badge-pill badge-primary">Beta</div> -->
            </a>
          </li>
          <li *ngIf="permission.ProviderConfigurationbyPOS">
            <a routerLink="/qdashboards/ProviderConfigurationbyPOS">
              <!-- <i class="mdi mdi-rhombus-medium"></i> -->
              Provider Config by CPT, Facility
              <!-- <div class="badge bg-green-lt float-right">New</div> -->
            </a>
          </li>

          <li *ngIf="permission.QAdminBillingConfiguration">
            <a routerLink="/qdashboards/QAdminBillingConfiguration">
              <!-- <i class="mdi mdi-rhombus-medium"></i> -->
              Billing Configuration
              <!-- <div class="badge badge-pill badge-primary">Beta</div> -->
            </a>
          </li>
          <li *ngIf="permission.QAdminDayWisePayerPayment">
            <a routerLink="/qdashboards/QAdminDayWisePayerPayment">
              <!-- <i class="mdi mdi-rhombus-medium"></i> -->
              Day wise Payer Payment
              <!-- <div class="badge badge-pill badge-primary">Beta</div> -->
            </a>
          </li>
          <li *ngIf="permission.CustomizedDashboard">
            <a routerLink="/qdashboards/CustomizedDashboard">
              <!-- <i class="mdi mdi-rhombus-medium"></i> -->
              Customized Dashboard
              <div class="badge bg-blue-lt float-right">Beta</div>
            </a>
          </li>
          <li *ngIf="permission.ExpectedValueConfig">
            <a routerLink="/qdashboards/ExpectedValueConfig">
              Practice Expected Value
            </a>
          </li>
          <li *ngIf="permission.DenialConfig">
            <a routerLink="/qdashboards/DenialConfig">
              Denial Config
            </a>
          </li>
          <li *ngIf="permission.RCMUserConfig">
            <a routerLink="/qdashboards/RCMUserConfig">
              User Configuration
              <!-- <div class="badge bg-green-lt float-right">New</div> -->
            </a>
          </li>
          <li *ngIf="permission.TFLUserConfig">
            <a routerLink="/qdashboards/TFLUserConfig">
              TFL/DFL Configuration
              <!-- <div class="badge bg-green-lt float-right">New</div> -->
            </a>
          </li>
          <li *ngIf="permission.automailconfig">
            <a routerLink="/qdashboards/automailconfig">
              Automail Configuration
              <!-- <div class="badge bg-blue-lt float-right">New</div> -->
            </a>
          </li>
          <!-- <li *ngIf="permission.serviceautomation">
            <a routerLink="/qdashboards/ServiceAutomation">
                Service Automation
              <div class="badge bg-blue-lt float-right">Beta</div>
            </a>
          </li> -->
        </ul>
      </li>


      <!-- ML Dashboards -->
      <li class="nav-item dropdown" [hidden]="!MLMainmenu">
        <a class="dropdown-toggle">
          <span class="icon-holder">
            <i class="mdi mdi-robot"></i>
          </span>
          <span class="title">Machine Learning</span>
          <span class="arrow">
            <i class="fa fa-angle-right"></i>
          </span>
        </a>
        <ul class="dropdown-menu">
          <!-- <li *ngIf="permission.denialepayment">
            <a routerLink="/mldashboards/denialepayment">
              Denial Prediction
            </a>
          </li> -->

          <li *ngIf="permission.denialepayment" routerLink="/mldashboards/denialepayment"
          routerLinkActive="active">
          <a>
            <!-- <i class="mdi mdi-rhombus-medium"></i> -->
            Denial Prediction
            <!-- <div class="badge bg-green-lt float-right">New</div> -->
          </a>
        </li>
        <li *ngIf="permission.forecastalowedpayment" routerLink="/mldashboards/forecastalowedpayment"
        routerLinkActive="active">
        <a>
          <!-- <i class="mdi mdi-rhombus-medium"></i> -->
          Forecast Allowed Amount
          <!-- <div class="badge bg-green-lt float-right">New</div> -->
        </a>
      </li>


          <!-- <li *ngIf="permission.forecastalowedpayment">
            <a routerLink="/mldashboards/forecastalowedpayment">
              Forecast Allowed Amount
            </a>
          </li> -->

      
        </ul>
      </li>
      <div class="dropdown-divider"></div>
      <li class="nav-item dropdown" [hidden]="!valueMainMenu">
        <a class="dropdown-toggle">
          <span class="icon-holder">
            <i class="ri-line-chart-fill"></i>
            <!-- <span class="mask-icons value-icon"></span> -->
          </span>
          <span class="title">Value</span>
          <span class="arrow">
            <i class="fa fa-angle-right"></i>
          </span>
        </a>
        <ul class="dropdown-menu">
          <li *ngIf="permission.alliancesummaryanalytics">
            <a routerLink="/value/alliancesummaryanalytics">
              <!-- <i class="mdi mdi-rhombus-medium"></i> -->
              Alliance Summary Analytics
              <!-- <div class="badge bg-green-lt float-right">New</div> -->
            </a>
          </li>
          <li *ngIf="permission.practicesummaryanalytics">
            <a routerLink="/value/practicesummaryanalytics">
              <!-- <i class="mdi mdi-rhombus-medium"></i> -->
              Practice Summary Analytics
              <!-- <div class="badge bg-green-lt float-right">New</div> -->
            </a>
          </li>
          <li *ngIf="permission.facilityanalytics">
            <a routerLink="/value/facilityanalytics">
              Facility Analytics
            </a>
          </li>
          <li *ngIf="permission.hhaanalytics">
            <a routerLink="/value/hhaanalytics">
              BPCI-HHA Analytics
            </a>
          </li>
          <li *ngIf="permission.snfanalytics">
            <a routerLink="/value/snfanalytics">
              BPCI-SNF Analytics
            </a>
          </li>
          <li *ngIf="permission.ptanalytics">
            <a routerLink="/value/ptanalytics">
              BPCI-PT Analytics
            </a>
          </li>
          <li *ngIf="permission.orderanalytics">
            <a routerLink="/value/orderanalytics">
              Orders Analytics
              <div class="badge bg-green-lt float-right">New</div>
            </a>
          </li>
        </ul>

        <!-- <ul class="dropdown-menu">
          <li class="nav-item dropdown" [hidden]="!OrthomainMenu">
            <a>
              <span>Ortho</span>
              <span class="arrow">
                <i class="fa fa-angle-right"></i>
              </span>
            </a>
            <ul class="dropdown-menu">
              <li *ngIf="permission.ReconciliationPerformance">
                <a routerLink="/qdashboards/ReconciliationPerformance">
                  Reconciliation Performance</a>
              </li>
              <li *ngIf="permission.ActualReconciliation">
                <a routerLink="/qdashboards/ActualReconciliation">
                  Actual Reconciliations</a>
              </li>
              <li *ngIf="permission.UtilizationComparison">
                <a routerLink="/qdashboards/UtilizationComparison">
                  Utilization Comparison</a>
              </li>
              <li class="dropdown">
                <a>
                  <span *ngIf="LowerExtremity">Performance Dashboards</span>
                  <span class="arrow" *ngIf="LowerExtremity">
                    <i class="fa fa-angle-right"></i>
                  </span>
                </a>
                <ul class="dropdown-menu">
                  <li *ngIf="permission.Ortholowersummary">
                    <a routerLink="/qdashboards/orthoLowerSummary"> Summary</a>
                  </li>
                  <li *ngIf="permission.OrtholowerIPRiskStratified">
                    <a routerLink="/qdashboards/orthoLowerIPRisk">IP-Risk Stratified</a>
                  </li>
                  <li *ngIf="permission.OrtholowerFractures">
                    <a routerLink="/qdashboards/fractures">Fractures</a>
                  </li>
                  <li *ngIf="permission.OrtholowerReadmissions">
                    <a routerLink="/qdashboards/orthoLowerReadmissions">Readmissions</a>
                  </li>
                </ul>
              </li>
              <li *ngIf="permission.BCBSCommercialActual">
                <a routerLink="/qdashboards/BCBSCommercialActual">
                  BCBS Commercial Actuals</a>
              </li>
              <li *ngIf="permission.BCBSUtilizations">
                <a routerLink="/qdashboards/BCBSUtilizations">
                  BCBS Utilizations</a>
              </li>
            </ul>
          </li>
        </ul> -->
      </li>



      <!-- <li class="nav-item dropdown" [hidden]="!QAdminMainmenu">
        <a class="dropdown-toggle">
          <span class="icon-holder">
            <i class="ri-settings-5-line"></i>
          </span>
          <span class="title">Practice Configurations</span>
          <span class="arrow">
            <i class="fa fa-angle-right"></i>
          </span>
        </a>
        <ul class="dropdown-menu">
          <li *ngIf="permission.AddPractice ">
            <a routerLink="/qdashboards/QAdminPracticeConfigurationNew">         
             Add      
            </a>
          </li>
          <li *ngIf="permission.UpdatePractice ">
            <a routerLink="/qdashboards/QAdminPracticeConfigurationNew">           
           Update          
            </a>
          </li>
          <li *ngIf="permission.DeletePractice">
            <a routerLink="/qdashboards/QAdminPracticeConfigurationNew">    
             Delete
            </a>
          </li>  
           <li *ngIf="permission.ServiceGroup">
            <a routerLink="/qdashboards/QAdminPracticeConfigurationNew">    
             Delete
            </a>
          </li>  
        </ul>
      </li> -->


      <!--  Customized Dashboard List  -->

      <!--    <li class="nav-item dropdown" >
          <a class="dropdown-toggle">
            <span class="icon-holder">
              <i class="ti ti-clipboard"></i>
            </span>
            <span class="title">Customized Dashboards</span>
            <span class="arrow">
              <i class="fa fa-angle-right"></i>
            </span>
          </a>
          <ul class="dropdown-menu">
            <li *ngFor="let dash of ListOfDashboards">
              <a (click)="customizedDashClick(dash)">
                <i class="mdi mdi-rhombus-medium"></i>
                {{ dash.dashboard_name }}
              </a>
            </li>
          </ul>
        </li> -->
    </ul>
  </div>
</div>
<!-- Side Nav END -->



<!-- SVG Code -->