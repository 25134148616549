import { Injectable } from "@angular/core";
declare var ga: Function;
@Injectable({
  providedIn: "root"
})
export class GoogleAnGoogleAnalyticsServiceService {
  constructor() {}

  public eventEmitter(
    eventName: string,
    eventCategory: string,
    eventAction: string,
    eventLabel: string = null,
    eventValue: number = null
  ) {
    ga("event", eventCategory, {
      event_category: eventName,
      event_label: eventLabel,
      value: eventValue
    });

    // gtag("event", eventName, {
    //   eventCategory: eventCategory,
    //   eventLabel: eventLabel,
    //   eventAction: eventAction,
    //   eventValue: eventValue
    // });
  }
}
