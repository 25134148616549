// import { OutputEmitter } from '@angular/compiler/src/output/abstract_emitter';
import { AfterContentInit, AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ChartSelectEvent } from 'ng2-google-charts';


@Component({
  selector: 'app-stackchart',
  templateUrl: './stackchart.component.html',
  styleUrls: ['./stackchart.component.css']
})
export class StackchartComponent implements OnInit {
 
    @Input() dataSource: any;
    @Output() categorySelect  = new EventEmitter<any>();
      categoriesTrendData: any;
  isData: boolean;
      constructor() { }
    
      ngOnInit(): void {
    
      }
      ngOnChanges(){
        const categoriesnameschart = new Array();
        const categoriesvaluechart = new Array();
        if(!isNullOrUndefined(this.dataSource)){
        if(this.dataSource.length>0){
          this.isData = false;
       this.dataSource = this.dataSource.slice(0, 10);
        for (let i = -1; i < this.dataSource.length; i++) {
          if (i == -1) {
            categoriesnameschart.push('');
            categoriesvaluechart.push('');
          } 
          // else if (i == userdatalenght - 1) {
          //   // categoriesnameschart.push({ role: 'annotation' });
          //   // categoriesvaluechart.push(100);
          // }
          else {
            categoriesnameschart.push(this.dataSource[i].text);
            categoriesnameschart.push({ role: 'annotation' });
            categoriesnameschart.push({ type: "string", role: "tooltip", p: { html: true } }),
            categoriesvaluechart.push(this.dataSource[i].Adjustment);
            if(i<4){
            categoriesvaluechart.push("$"+ this.numberWithCommas(this.dataSource[i].Adjustment));
          }else{
            categoriesvaluechart.push("");
          }
          categoriesvaluechart.push('<div style="padding:5px 5px 5px 5px;text-align:left;border-style: solid;border-width: thin;border-color: #0c59e8;font-size: 12px;">Balance: ' +
         "$" + this.numberWithCommas( this.dataSource[i].Adjustment) +
              "<br>Claim Count: " +
              this.numberWithCommas(this.dataSource[i].claim) +
              "</div>")
          }
        }
   // debugger;
        this.categoriesTrendData = new Array();
        this.categoriesTrendData = {
          chartType: "BarChart",
          dataTable: [categoriesnameschart,categoriesvaluechart],
          options: {
            title: "",
            titleTextStyle: { 
              fontSize: 14,
              margin : 25,
          },
            isStacked: true,
            bar: { groupWidth: '100%' },
            display: false,
            vAxis: {
              title: '',
              format: "short",
              display: false,
              textPosition: 'none',
              gridlines: {
                count: 0
              },
              baselineColor: 'none',
              ticks: []
            },
            legend: {position: 'top', textStyle: {fontSize: 11}},
            hAxis: {
              title: '',
              display: false,
              textPosition: 'none',
    
              gridlines: {
                count: 0
              },
              baselineColor: 'none',
              ticks: []
            },
            annotations: {
              textStyle: {
                  color: 'blue',
                  fontSize: 11,
              },
  
         },
            // hAxis: {title: 'Month'},
            // seriesType: 'bars',
            // series: {2: {type: 'line'}},
            //vAxis: { format:"short"} ,
    
            //height: 300,
            animation: {
              startup: true,
              duration: 1000,
              easing: "inAndOut",
            },
            chartArea: {
              height : "30%",
              width : "100%",
           },
          height:100,
          //  width : 550,
            // chartArea: { left: 100 },
    
            // isStacked: true
            tooltip: { isHtml: true },
          },
        };
      }else{
        this.isData = true;
      }}else{
        this.isData = true;
      }
      }
    
    
      CategorySelect(event: ChartSelectEvent){
        this.categorySelect.emit(event);
      }
    
    
      numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    }
    
  function isNullOrUndefined(value: any): boolean { return value === null || value === undefined; }
    
