// All Filter Input JSON
export class Filter {
    group: boolean;
    practice: boolean;
    monthinterval: boolean;
    year: boolean;
    oprationtype:boolean;
    provider: boolean;
    proceduretype:boolean; //provider prod
    procedure:boolean; //type
    procedurevalue:boolean; //value
    singlepractice: boolean;
    month: boolean;
    singlemonth: boolean;
    startdate: boolean;
    enddate: boolean;
    MinDays:boolean;
    MaxDays:boolean;
    ProviderName:boolean;
    Status:boolean;
    onHold:boolean; 
    bisProviderdash:boolean;
    bisProceduredash:boolean;
    bIsProceduretype:boolean; //type filter
    MonthOrYearWise: boolean;
    yearNumber: boolean;
    monthintervalKM: boolean;
    procedureValue: boolean;
    monthyear: boolean;
  }
  
  // All Filter Output JSON
  export class OutputFilter {
    "displaypractice": any;
    "group": any;
    "practice": any;
    "practicename":any;
    "monthinterval": string;
    "oprationtype":string
    "year": any;
    "provider": any;
    "proceduretype":any; //provider prod cpt type
    "procedurevalue":any;
    "month": any;
    "startdate": any;
    "enddate": any;
    "ProviderName":any;
    "sNpi":any;
    "nproviderid":any;
    "MinDays":any;
    "MaxDays":any;
    "Status":any;
    "onHold":any;
    "bIsOnHold":any;
    "OnholddataChecked":any;
    "monthyear":any;
    yearNumber: any;
    MonthOrYearWise: any;
    singlemonth: any;
    monthintervalKM : any;
  }
  
  // InsertInput
  export class PreserveFilterInput {
    "tran_id": string;
    "userid": string;
    "parentGroupID": string;
    "parentGroupName": string;
    "groupcode": [];
    "groupname": [];
    "monthInterval": string;
    "StartDate": string;
    "endDate": string;
    "providerType": string;
    "procedureType": string;
   // "procedurevalue":string;
    "month": [];
    "year": string;
    "envname": string;
    "PracticeSelectionType": string;
  }