import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[arToggle]'
})
export class ToggleArPanelDirective {

    constructor() {
    }

    @HostListener('click', ['$event.target'])

    onClick(btn) {

        $(".ar-panel").toggleClass("ar-panel-open")

    }
}
