import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;text/plain;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
import * as _ from 'lodash';
// import * as Excel from "exceljs/dist/exceljs.min.js";
import { ColumnsContainer } from '@progress/kendo-angular-grid/dist/es2015/columns/columns-container';
import { ElementSchemaRegistry } from '@angular/compiler';

@Injectable()
export class ExcelService {
  public data: any;
  public sheetName: string = "Sheet1";
  public workbook: XLSX.WorkBook = {
    Sheets: {},
    SheetNames: [],
    Props: {}
  }
  public ws: any;
  public wbout: any;
  constructor() { }



  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }
  

//Reason Code
  public exportAsExcelFileDenialMgmt(json: any[], excelFileName: string): void {
    let header = ["DENIAL REASONS","CLAIM#","REASON AMOUNT","DENIAL CATEGORY","REASON DESCRIPTION"]
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    XLSX.utils.sheet_add_json(ws, json, { skipHeader: false });
    XLSX.utils.sheet_add_aoa(ws, [header]);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    var columns = ["C"];
    for (let i = 0; i < columns.length; i++) {
      var C = XLSX.utils.decode_col(columns[i]); // 1
      this.currencyformat(ws, C);
    }
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFilemgmt(excelBuffer, excelFileName);
  }

  private saveAsExcelFilemgmt(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }
  
  //Remark code
  public exportAsExcelFileDenialMgmt1(json: any[], excelFileName: string): void {
    let header =  ["REMARK CODE","CLAIM#","ADJUSTMENT","REMARK DESCRIPTION"]
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    XLSX.utils.sheet_add_json(ws, json, { skipHeader: false });
    XLSX.utils.sheet_add_aoa(ws, [header]);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    var columns = ["C"];
    for (let i = 0; i < columns.length; i++) {
      var C = XLSX.utils.decode_col(columns[i]); // 1
      this.currencyformat(ws, C);
    }
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFilemgmt1(excelBuffer, excelFileName);
  }

  private saveAsExcelFilemgmt1(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }
  


  //Denial management
public exportAsExcelFileDenialDetails(json: any[], excelFileName: string, formattedDate: string): void {
  let header = ["DOS","CLAIM#","CPT","PATIENT CODE","PLAN","ORDERING PROVIDER","RENDERING PROVIDER","BILLING PROVIDER","REMARK CODE","REASON AMOUNT","REASON DESCRIPTION"];
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    XLSX.utils.sheet_add_json(ws, json, { skipHeader: false });
    XLSX.utils.sheet_add_aoa(ws, [header]);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    var columns = ["J"];
    for (let i = 0; i < columns.length; i++) {
      var C = XLSX.utils.decode_col(columns[i]); // 1
      this.currencyformat(ws, C);
    }
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFileDenialDetails(excelBuffer, excelFileName, formattedDate);
  }
  
  private saveAsExcelFileDenialDetails(buffer: any, fileName: string, formattedDate: any): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_Export_' + new Date().getTime() + EXCEL_EXTENSION);
  }
  


//Denial History DB Excel function
public exportAsExcelFileDenial(json: any[], excelFileName: string, formattedDate: string): void {
let header = ["PATIENT CODE","DOS","CLAIM#","CPT","CHARGES","COMPANY","PLAN", "DENIAL REASONS","REASON AMOUNT","REMARK CODE",
"BILLING PROVIDER","RENDERING PROVIDER","ORDERING PROVIDER","REASON DESCRIPTION"];
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
  XLSX.utils.sheet_add_json(ws, json, { skipHeader: false });
  XLSX.utils.sheet_add_aoa(ws, [header]);
  const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
  var columns = ["E","I"];
  for (let i = 0; i < columns.length; i++) {
    var C = XLSX.utils.decode_col(columns[i]); // 1
    this.currencyformat(ws, C);
  }
  const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  this.saveAsExcelFileDenial(excelBuffer, excelFileName, formattedDate);
}

private saveAsExcelFileDenial(buffer: any, fileName: string, formattedDate: any): void {
  const data: Blob = new Blob([buffer], {
    type: EXCEL_TYPE
  });
  FileSaver.saveAs(data, fileName + '_Export_' + new Date().getTime() + EXCEL_EXTENSION);
}



  //Summary -FP
  //if needed headers/filter selected in Excel -ony sheet -(Data and filters)
  public exportAsExcelFileSummary(json: any[], user: any[], excelFileName: string, formattedDate: string): void {

    let objectMaxLength = [];
    const sortedJsonData = this.reorderJsonKeys(json);
    for (let i = 0; i < sortedJsonData.length; i++) {
      let value = <any>Object.values(sortedJsonData[i]);
      for (let j = 0; j < value.length; j++) {
        if (typeof value[j] == "number") {
          objectMaxLength[j] = 20;
        } else {
          objectMaxLength[j] =
            objectMaxLength[j] >= value[j].length
              ? objectMaxLength[j]
              : value[j].length;
        }
      }
    }
    var wscols = [
      { width: objectMaxLength[0] },  // first column
      { width: objectMaxLength[1] }, // second column
      { width: objectMaxLength[2] }, //...
      { width: objectMaxLength[3] },
      { width: objectMaxLength[4] },
      { width: objectMaxLength[5] },
      { width: objectMaxLength[6] },
      { width: objectMaxLength[7] },
      { width: objectMaxLength[8] },
      { width: objectMaxLength[9] },
      { width: objectMaxLength[10] },
      { width: objectMaxLength[11] },
      { width: objectMaxLength[12] },
      { width: objectMaxLength[13] },
      { width: objectMaxLength[14] },
      { width: objectMaxLength[15] },
      { width: objectMaxLength[16] },
      { width: objectMaxLength[17] },
      { width: objectMaxLength[18] },
      { width: objectMaxLength[19] },
    ];

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let header = ["MONTH", "PATENT_ID", "VISITS_ID", "CLAIMS#", "NET_CHARGES", "INS PAYMENTS", "PAT PAYMENTS", "NET PAYMENTS", "NET REFUND", "NET PAYMENT-REF", "ADJUSTMENTS", "NET A/R"];
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(user, { skipHeader: true, });
    ws["!cols"] = wscols;
    XLSX.utils.sheet_add_json(ws, sortedJsonData, { skipHeader: false, origin: `A${user.length + 6}` });
     //console.log('json', json);
    //XLSX.utils.sheet_add_aoa(ws, [header],{ origin: `A${user.length + 5}`},);
    //XLSX.read(excelFileName, {cellNF:true, cellText:false});
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    //console.log("WB", wb);
     //if all are selected
    // var columns = ["E", "F", "G", "H", "I", "J", "K", "L", "M"];
    // for (let i = 0; i < columns.length; i++) {
    //   var C = XLSX.utils.decode_col(columns[i]); // 1
    //   this.currencyformat(ws, C);
    // }
    var count= Object.keys(sortedJsonData[0]).length;
    if (count == 19) {
      for (let col of [7,8, 9, 10, 11, 12, 13 ,14 ,15 ,16 ,17,18,19,20,21]) {
        this.currencyformat(ws, col);
      }
    }
    else if (count == 20) {
      for (let col of [ 8, 9, 10, 11, 12, 13, 14, 15, 16 ,17, 18,19,20,21]) {
        this.currencyformat(ws, col);
      }
    }
    else if (count == 21) {
      for (let col of [ 9, 10, 11, 12, 13, 14,15, 16 ,17, 18,19,20,21]) {
        this.currencyformat(ws, col);
      }
    }
    else if (count == 22) {
      for (let col of [ 10, 11, 12, 13,14, 15, 16 ,17, 18,19,20,21]) {
        this.currencyformat(ws, col);
      }
    }
   else if (count == 23) {
      for (let col of [11, 12, 13,14, 15,16,17, 18,19,20,21]) {
        this.currencyformat(ws, col);
      }
    }
    else if (count == 24) {
        for (let col of [  12, 13,14, 15,16,17,18,19,20,21]) {
          this.currencyformat(ws, col);
        }
    } 
    else if (count == 25) {
      for (let col of [ 13,14, 15,16,17,18,19,20,21]) {
        this.currencyformat(ws, col);
      }
  } 
  else if (count == 26) {
    for (let col of [14, 15,16,17,18,19,20,21]) {
      this.currencyformat(ws, col);
    }   
} 
else if (count == 27) {
  for (let col of [15,16,17,18,19,20,21]) {
    this.currencyformat(ws, col);
  }   
} 
    //console.log("WB", wb)
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array', cellDates: true },);
    const finalData = new Blob([excelBuffer], { type: fileType });
    this.saveAsExcelFile1(finalData, excelFileName, formattedDate);
  }

   reorderJsonKeys(jsonArray: any[]): any[] { //Used this sequence for Fixed sequence of columns Export 
    return jsonArray.map((item) => {
        let reorderedItem: any = {};
    
        // Copy keys in the desired order
        const keysOrder = ["Month_Number", "Month_Name","Year", "Month", "CCSR", "CCSR_Description", ...Object.keys(item).filter(key => key !== "CCSR" && key !== "Description")];

        keysOrder.forEach((key) => {
            if (item.hasOwnProperty(key)) {
                reorderedItem[key] = item[key];
            }
        });

        return reorderedItem;
    });
}

  
  private saveAsExcelFile1(buffer: any, fileName: string, formattedDate: any): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(data, fileName + '_export_' + formattedDate + EXCEL_EXTENSION);
  }

  //Details -FP
  //if needed headers/filter selected in Excel -ony sheet -(Data and filters)
  public exportAsExcelFileDetails(json1: any[], user: any[], excelFileName: string, formattedDate1: string): void {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let header = ["CLOSEDATE", "TRAY", "PATIENT CODE", "PATIENT NAME", "CPT", "CLAIMS#", "DOS", "CHARGES", "WRITEOFF", "WITHHOLD", "PATIENTADJ", "REFUNDS", "RECEIPTS", 'CHECK#', 'PAYER', 'USER', 'CREATED'];
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(user, { skipHeader: true });
    XLSX.utils.sheet_add_json(ws, json1, { skipHeader: true, origin: `A${user.length + 6}` });
    XLSX.utils.sheet_add_aoa(ws, [header], { origin: `A${user.length + 5}` },);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    var columns = ["H", "I", "J", "K", "L", "M"];
    for (let i = 0; i < columns.length; i++) {
      var C = XLSX.utils.decode_col(columns[i]); // 1
      this.currencyformat(ws, C);
    }

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array', cellDates: true },);
    const finalData = new Blob([excelBuffer], { type: fileType });
    this.saveAsExcelFile2(finalData, excelFileName, formattedDate1);
  }

  private saveAsExcelFile2(buffer: any, fileName: string, formattedDate1: any): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(data, fileName + '_export_' + formattedDate1 + EXCEL_EXTENSION);
  }


  currencyformat(ws, C) {

    var fmt = '"$"#,##0'; // or '"$"#,##0.00_);[Red]\\("$"#,##0.00\\)' or any Excel number format
    /* get worksheet range */
    var range = XLSX.utils.decode_range(ws['!ref']);
    for (var i = range.s.r + 1; i <= range.e.r; ++i) {
      /* find the data cell (range.s.r + 1 skips the header row of the worksheet) */
      var ref = XLSX.utils.encode_cell({ r: i, c: C });
      /* if the particular row did not contain data for the column, the cell will not be generated */
      if (!ws[ref]) continue;
      /* `.t == "n"` for number cells */
      if (ws[ref].t != 'n') continue;
      /* assign the `.z` number format */
      ws[ref].z = fmt;
    }

  }

  // //if needed headers/filter selected in Excel -data
  //     public exportAsExcelFile1(json: any[], user: any[], excelFileName: string): void {
  //       const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  //       let  header = ["MONTH", "PID", "VID", "CLAIMS#","NET_CHARGES","INS_PAYMENTS","PAT_PAYMENT","NET_PAYMENT","NET REFUND","NET PAYMENT REFUND", "ADJ","NET AR"];
  //       /* create a new blank workbook */
  //       var wb = XLSX.utils.book_new();
  //       /* create a worksheet for books */ //Result -Grid
  //       var wsBooks =  XLSX.utils.json_to_sheet(json,{ skipHeader: false} );
  //       XLSX.utils.sheet_add_aoa(wsBooks, [header]);
  //       /* Add the worksheet to the workbook */
  //       XLSX.utils.book_append_sheet(wb, wsBooks, "Data");
  //       /* create a worksheet for person details */
  //       var wsPersonDetails = XLSX.utils.json_to_sheet(user,{ skipHeader: true} );
  //       /* Add the worksheet to the workbook */
  //       XLSX.utils.book_append_sheet(wb, wsPersonDetails, "PersonDetails");

  //       const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  //       const data1 = new Blob([excelBuffer], { type: fileType });
  //       this.saveAsExcelFile1(data1, excelFileName);
  //     }

  //     private saveAsExcelFile1(buffer: any, fileName: string): void {
  //             const data: Blob = new Blob([buffer], { type: EXCEL_TYPE,   
  //             });
  //             FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  //           }


  // //Backup
  // public exportAsExcelFile2(json: any[], user: any[], excelFileName: string): void {
  //   const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(user,{ skipHeader: true} );
  //   console.log('worksheet',worksheet);
  //   XLSX.utils.sheet_add_json(worksheet, json, { origin: `A${user.length + 2}`});
  //   const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'], Props: {  }  };
  //   const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array',  bookSST:true,   });
  //   this.saveAsExcelFile2(excelBuffer, excelFileName);
  // }

  // private saveAsExcelFile2(buffer: any, fileName: string): void {
  //   const data: Blob = new Blob([buffer], { type: EXCEL_TYPE,   
  //   });
  //   FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  // }








}