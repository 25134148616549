import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  sidebarSub = new BehaviorSubject<boolean>(null);

  constructor() { }

  _userActionOccured: Subject<void> = new Subject();
  get userActionOccured(): Observable<void> { return this._userActionOccured.asObservable() };
 
  notifyUserAction() {
    this._userActionOccured.next();
  }
}
