<div *ngIf="!this.bIsHideHeaderSidebar" class="app">
    <!-- is-collapsed class Minimize sidebar(add class) Maximize sidebar (Remove Class) -->
    <div class="layout">
        <app-sidebar></app-sidebar>
        <!-- Page Container START -->
        <div class="page-container">
            <app-header></app-header>
            <div class="main-content">
                <router-outlet></router-outlet>
            </div>
        </div>
        <!-- Page Container END -->
    </div>
</div>


<div *ngIf="this.bIsHideHeaderSidebar" [ngClass]="iscontainer">
    <router-outlet></router-outlet>
</div>