<!-- <div class="card border border-dark rounded ml-2 mr-2">
    <div class="element py-3 ml-4 mr-3">
      <P>{{Text}}</P>
      <kendo-dropdownlist class="w-200" defaultItem="select item..." [data]="listItems"></kendo-dropdownlist>
    </div>
  </div>
   -->

<!-- <div class="card border border-dark rounded ml-2 mr-2"> -->
  <!-- <div class="element py-3 ml-4 mr-3"> -->
    <div class="form-group">
        <label class="form-label">{{textName}}</label>
        <angular2-multiselect 
        [data]="listItemsData"
        [(ngModel)]="apptTypeSelectedValues" 
        (ngModelChange)="ngmodel($event,check)"
        [settings]="common"
        (onSelect)="OnapptTypeSelect($event,check)" 
        (onDeSelect)="OnapptTypeDeSelect($event,check)"
        (onSelectAll)="OnapptTypeSelectAll($event,check)" 
        (onDeSelectAll)="OnapptTypeDeSelectAll($event,check)">
        </angular2-multiselect>
      </div>
    <!-- </div> -->
  <!-- </div> -->
