import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[leftToggle]'
})
export class ToggleLeftPanelDirective {

    constructor() {
    }

    @HostListener('click', ['$event.target'])

    onClick(btn) {
  
        $(".leftside-panel").toggleClass("leftside-panel-open")

    }
}
