import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { CommonLoaderComponent } from 'src/app/ModelFinancialMetrics/CommonService/common-loader/common-loader.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MetricsDocumentComponentComponent } from 'src/app/ModelFinancialMetrics/CommonService/metrics-document-component/metrics-document-component.component';
import { SummarytableComponent } from '../Modelqdashboards/DashboardComponents/summarytable/summarytable.component';

import { ToggleSidePanelDirective } from 'src/app/ModelFinancialMetrics/CommonService/toggle-side-panel.directive';
import { ToggleLeftPanelDirective } from 'src/app/ModelFinancialMetrics/CommonService/toggle-left-panel.directive';
import { ToggleArPanelDirective } from 'src/app/ModelFinancialMetrics/CommonService/toggle-ar-panel.directive';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { GridModule, ExcelModule, PDFModule } from '@progress/kendo-angular-grid';
import { InputsModule, TextBoxModule } from '@progress/kendo-angular-inputs';
import { ListViewModule } from '@progress/kendo-angular-listview';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { FiltersComponent } from '../ModelFinancialMetrics/CommonService/filters/filters.component';
import { BarVsLineComponent } from '../Modelqdashboards/CommonQdasboard/bar-vs-line/bar-vs-line.component';
import { BasicColorTableComponent } from '../Modelqdashboards/CommonQdasboard/basic-color-table/basic-color-table.component';
import { ClaimbatchcreateprojectComponent } from '../Modelqdashboards/CommonQdasboard/claimbatchcreateproject/claimbatchcreateproject.component';
import { HorizontalBarGraphComponent } from '../Modelqdashboards/CommonQdasboard/horizontal-bar-graph/horizontal-bar-graph.component';
import { Safeurl } from '../Modelqdashboards/CommonQdasboard/safeurl';
import { SpecialclaimcreaeprojectComponent } from '../Modelqdashboards/CommonQdasboard/specialclaimcreaeproject/specialclaimcreaeproject.component';
import { StackchartComponent } from '../Modelqdashboards/CommonQdasboard/stackchart/stackchart.component';
import { SearchFilterPipe } from 'src/app/ModelFinancialMetrics/CommonService/search-filter.pipe';
import { MinusSignToParens } from 'src/app/ModelFinancialMetrics/CommonService/minus-sign-to-parens.pipe';
import { DropdownComponent } from 'src/app/ModelFinancialMetrics/CommonService/dropdown/dropdown.component';
@NgModule({
  declarations: [
    SearchFilterPipe,
    Safeurl,
    MinusSignToParens,
    DropdownComponent,
    CommonLoaderComponent,
    MetricsDocumentComponentComponent,
    FiltersComponent,
    SummarytableComponent,
    SpecialclaimcreaeprojectComponent,
    ClaimbatchcreateprojectComponent,
    ToggleSidePanelDirective,
    ToggleLeftPanelDirective,
    ToggleArPanelDirective,
    BasicColorTableComponent,
    BarVsLineComponent,
    StackchartComponent,
    HorizontalBarGraphComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    AngularMultiSelectModule,
    ReactiveFormsModule,
    PDFExportModule,
    ButtonsModule,
    GridModule,
    InputsModule,
    DropDownsModule,
    DateInputsModule,
    ExcelModule, PDFModule,
    TextBoxModule,
    ChartsModule,
    NavigationModule,
    ListViewModule,
    TooltipModule,
    NgbModule
  ],
  exports: [
    SearchFilterPipe,
    MinusSignToParens,
    DropdownComponent,
    CommonLoaderComponent,
    AngularMultiSelectModule,
    MetricsDocumentComponentComponent,
    SummarytableComponent,
    SpecialclaimcreaeprojectComponent,
    ClaimbatchcreateprojectComponent,
    FiltersComponent,
    ToggleSidePanelDirective,
    ToggleLeftPanelDirective,
    ToggleArPanelDirective,
    BarVsLineComponent,
    BasicColorTableComponent,
    StackchartComponent,
    HorizontalBarGraphComponent,


  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule { }
