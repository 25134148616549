import { Injectable } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { LogdatatransferService } from "./logdatatransfer.service";
import { CallserviceService } from "../CallServices/callservice.service";
import { environment } from "./../../environments/environment";
import { from } from 'rxjs';
import { GoogleAnGoogleAnalyticsServiceService } from "../CallServices/google-an-google-analytics-service.service";
import {
  Http,
  RequestOptions,
  URLSearchParams,
  HttpModule,
  Headers
} from "@angular/http";
import { TokenEncryptionService } from './token-encryption.service';
@Injectable({
  providedIn: 'root'
})
export class AuditLogService {
  constructor(private router: Router, private http: Http, private api: CallserviceService,
    private tokenEncryptDecrypt:TokenEncryptionService) { }
  public logData: LogdatatransferService;
  public googleAnalyticsService: GoogleAnGoogleAnalyticsServiceService
  logDataParam: {
    userid: string,
    clientip: string,
    clientbrowser: string,
    continent: string,
    country: string,
    countrycode: string,
    city: string,
    region: string,
    userfullname: string,
    message: string,
    module: string,
    useraction: string,
    transactionid: string,
    organizationid: string,
    outcome: string,
    screen: string,
    application: string,
    loginuser: string,
    groupid: string,

  } = {
      userid: "",
      clientip: "",
      clientbrowser: "",
      continent: "",
      country: "",
      countrycode: "",
      city: "",
      region: "",
      userfullname: "",
      message: "",
      module: "",
      useraction: "",
      transactionid: "",
      organizationid: "0",
      outcome: "string",
      screen: "",
      application: "",
      loginuser: "",
      groupid: ""

    };

  insertActivityLog(message: string, module: string, useraction: string, transactionid: string = "0") {
    try {
      let useremail = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("username"))  //Decryption   sessionStorage.getItem("username");
      let screen: string = this.router.url;
      let application = "QINSIGHTS";
      let groupId = "0";
      let organizationid = "0";
      let outcome = "0";

      if (this.router.url.length > 0) {

        if (this.router.url == "/") {
          screen = "Login".toString();
        } else {
          screen = this.router.url
            .toString()
            .substr(1, this.router.url.toString().length);
        }

      }



     
      const LogData = JSON.parse(this.tokenEncryptDecrypt.decryptToken(localStorage.getItem("currentUser")));
      this.logDataParam.message = message.toString();
      this.logDataParam.module = module.toString();
      this.logDataParam.useraction = useraction.toString();
      this.logDataParam.transactionid = transactionid.toString();
      this.logDataParam.screen = screen.toString();
      this.logDataParam.application = application.toString();
      this.logDataParam.groupid = groupId.toString();
      this.logDataParam.groupid = organizationid.toString();
      this.logDataParam.outcome = outcome.toString();

      this.logDataParam.userid = LogData.userid.toString();
      this.logDataParam.userfullname = LogData.userFullName.toString();
      this.logDataParam.city = LogData.city.toString();
      this.logDataParam.country = LogData.country.toString();
      this.logDataParam.countrycode = LogData.countryCode.toString();
      this.logDataParam.clientbrowser = LogData.visitorbrowser.toString();
      this.logDataParam.clientip = LogData.visitorip.toString();
      this.logDataParam.region = LogData.region.toString();
      // this.logDataParam.loginuser=LogData.loginuser.toString();
      //audit log changes login user replace with user email
      this.logDataParam.loginuser = useremail.toString();
      this.logDataParam.continent = LogData.continent.toString();
      this.logDataParam.continent = LogData.continent.toString();

      const seq = this.api.logServiceCall(this.logDataParam).share();
      seq
        .map(res => res.json())
        .subscribe(
          res => {
            /*    try {

                 this.googleAnalyticsService.eventEmitter(
                   module,
                   useraction,
                   message,
                   useremail,
                   1
                 );
               } catch (ex) {

               } */
          }, err => {

          });
    }
    catch (error) {
    }
  }
}
