import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DatatransaferService {

  constructor() { }

  ClaimBatchCreateProject: BehaviorSubject<string> = new BehaviorSubject<string>("");
  Obj_CreateProject: BehaviorSubject<any> = new BehaviorSubject<any>([]);

  bIsCQMEMRDBName: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  QpmObject: BehaviorSubject<any> = new BehaviorSubject<any>([]);
}

