import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { TokenEncryptionService } from '../CallServices/token-encryption.service';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private myRoute: Router, private tokenEncryptDecrypt:TokenEncryptionService) { }
  sendToken(token: string) {

   // localStorage.setItem("LoggedInUser", token)
   localStorage.setItem('LoggedInUser',this.tokenEncryptDecrypt.encryptToken(token));
  }
  getToken() {
  let decrypttoken=localStorage.getItem("LoggedInUser");
  decrypttoken =this.tokenEncryptDecrypt.decryptToken(decrypttoken);
  return localStorage.getItem(decrypttoken);
  }
  isLoggedIn() {
    return this.getToken() !== null;
  }
  logout() {
   // debugger
    localStorage.removeItem("LoggedInUser");

    window.location.assign(
      environment.Logout_url
    );
  }
}
