import { Component, OnInit, Input, AfterViewInit} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-metrics-document-component",
  templateUrl: "./metrics-document-component.component.html",
  styleUrls: ["./metrics-document-component.component.css"],
})
export class MetricsDocumentComponentComponent implements OnInit,AfterViewInit {
  videosrc: any;
  @Input() documenturl: any;
  @Input() docName: any;

  constructor(private sanitizer: DomSanitizer) {
  }
  ngAfterViewInit(){
    this.sanitizer.bypassSecurityTrustResourceUrl(this.documenturl);
   $("#PDFframe").attr("src",this.documenturl) 
  }
 
  ngOnInit() {
    // debugger
    // console.log(this.documenturl);
    this.sanitizer.bypassSecurityTrustResourceUrl(this.documenturl);
    $("#PDFframe").attr("src", this.documenturl);
  }

  /*   playvideo() {
    this.videosrc =
      "assets/2020-01-09 16.37 QInsight - Expected Collection Demonstration.mp4";
    $("#iFrameBlog").attr("src", this.videosrc);
  }
  stopvideo() {
    this.videosrc = "";
    $("#iFrameBlog").attr("src", this.videosrc);
  } */
}
