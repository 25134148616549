import { NgModule } from "@angular/core";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { GridModule,ExcelModule,PDFModule  } from "@progress/kendo-angular-grid";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { ScrollViewModule } from '@progress/kendo-angular-scrollview';
import {
  process,
  GroupDescriptor,
  State,
  aggregateBy,
  } from "@progress/kendo-data-query";
  import { TextBoxModule } from '@progress/kendo-angular-inputs';

import { ChartsModule } from '@progress/kendo-angular-charts';
import { NavigationModule } from "@progress/kendo-angular-navigation";

import { ListViewModule } from "@progress/kendo-angular-listview";
import { TooltipModule } from "@progress/kendo-angular-tooltip";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import 'hammerjs';
const KendoComponents = [
  
  
  PDFExportModule,
  ButtonsModule,
  GridModule,
  InputsModule,
  DropDownsModule,
  DateInputsModule,
  ExcelModule,PDFModule,
  TextBoxModule,
  ChartsModule,
  NavigationModule,
  ListViewModule,
  TooltipModule,
  NgbModule
];

@NgModule({
  imports: [KendoComponents,ScrollViewModule],
  exports: [KendoComponents]
})
export class KendoModule { }
