
<div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="calinfoLabel">
              {{docName}}
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <iframe class="e2e-iframe-trusted-src" id="PDFframe"  [src]="documenturl|safeurl" width="100%" height="700px"></iframe>
        </div>
    </div>
</div>