import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class TokenEncryptionService {
  private secretKey: string; // Replace with your own secret key
  constructor() { }


  encryptToken(token: string): string {
    if (this.secretKey == undefined) {
      this.secretKey = sessionStorage.getItem("KEY")
      this.secretKey = this.decryptrandom(this.secretKey);
    }
    const encryptedToken = CryptoJS.AES.encrypt(token, this.secretKey);
    return encryptedToken.toString();
  }

  decryptToken(encryptedToken: string): string {
    try {
      if (this.secretKey == undefined) {
        this.secretKey = sessionStorage.getItem("KEY")
        this.secretKey = this.decryptrandom(this.secretKey);
      }
      const decryptedBytes = CryptoJS.AES.decrypt(encryptedToken, this.secretKey);
      return decryptedBytes.toString(CryptoJS.enc.Utf8);
    }
    catch (error) {
      //console.log('error',error)
    }
  }

  getkey(key: any) {
    key = this.decryptrandom(key);
    this.secretKey = key;
  }

  decryptrandom(key: any) {
    // Remove random characters from specific spaces
    let keys = '';
    for (let i = 0; i < key.length; i += 2) {
      keys += key[i];
    }
    return keys;
  }

}
