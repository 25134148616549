import { Component, OnInit } from '@angular/core';
import { DatatransaferService } from 'src/app/ModelFinancialMetrics/CommonService/datatransafer.service';
import { CookieService } from "ngx-cookie-service";
@Component({
  selector: 'app-home-layout',
  templateUrl: './home-layout.component.html',
  styleUrls: ['./home-layout.component.css']
})
export class HomeLayoutComponent implements OnInit {
  iscontainer: string = "container-fluid";

  constructor(private datatransfer: DatatransaferService, private cookieService: CookieService) { }
  public bIsHideHeaderSidebar: boolean = true;
  ngOnInit() {
  

    // $.getScript('./assets/js/app.min.js');
    var script = document.createElement('script');
    script.src = './assets/js/app.min.js';
    document.head.appendChild(script);

    this.iscontainer = "container-fluid";
    this.datatransfer.bIsCQMEMRDBName.subscribe(data => {
      this.bIsHideHeaderSidebar = data
    })

    if (sessionStorage.getItem("issidebar")) {
      this.bIsHideHeaderSidebar = true;
      this.iscontainer = "container-fluid-pm";
    }
  }

}
