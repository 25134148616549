import { Injectable } from "@angular/core";
import {
  Http,
  RequestOptions,
  URLSearchParams,
  HttpModule,
  Headers,
  ResponseContentType,
} from "@angular/http";

import { CookieService } from "ngx-cookie-service";
/* import {environment} from ".././enviorments/environment"; */
import { environment } from "../../environments/environment";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { TokenEncryptionService } from "./token-encryption.service";
import { HttpClient } from '@angular/common/http';
/* import * as CryptoJS from "crypto-js"; */

@Injectable({
  providedIn: "root",
})
export class CallserviceService {
  // key = CryptoJS.enc.Utf8.parse("Qin$ight@Encrypt");
  // algokey = "glotest123456789";

  token = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("token"))  //Decryption

  usertoken = this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("usertoken"))  //Decryption sessionStorage.getItem("usertoken");
  currentUser = this.tokenEncryptDecrypt.decryptToken(localStorage.getItem("currentUser"));//localStorage.getItem('currentUser');
  functionnm: any;
  tknheaders: any;
  userheaders: any;
  public SelectDashboardRefresh = new Subject<boolean>();
  public KeymetricsSelectedPractice: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public projectDetails$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public SelectedPracticeMyPractice: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  // public isselected = new Subject<boolean>();
  public QdashPatientSummary: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public Clientdata: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public Dbname: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public MeasureType: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public idEmr: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public Providerdata: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public provider: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public checkData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public smsstringcheck: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public SelectedCQMTitle: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public endbdate: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public startdate: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public summaryData: BehaviorSubject<any> = new BehaviorSubject<any>(null);


  ///session 
  algokey: string = "glotest123456789";
  idleTimeout: number = 1 * 60;
  idleTime: number = 15 * 60;
  public isuserloggedin: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public username: BehaviorSubject<string> = new BehaviorSubject<string>("");
  public email: BehaviorSubject<string> = new BehaviorSubject<string>("");
  public userid: BehaviorSubject<string> = new BehaviorSubject<string>("0");
  public firstname: BehaviorSubject<string> = new BehaviorSubject<string>("");
  public userFullName: BehaviorSubject<string> = new BehaviorSubject<string>("");
  public lastname: BehaviorSubject<string> = new BehaviorSubject<string>("");
  public isclientadmin: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public issuperadmin: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public city: BehaviorSubject<string> = new BehaviorSubject<string>("");
  public continent: BehaviorSubject<string> = new BehaviorSubject<string>("");
  public country: BehaviorSubject<string> = new BehaviorSubject<string>("");
  public loginuser: BehaviorSubject<string> = new BehaviorSubject<string>("");
  public region: BehaviorSubject<string> = new BehaviorSubject<string>("");
  public visitorbrowser: BehaviorSubject<string> = new BehaviorSubject<string>("");
  public visitorip: BehaviorSubject<string> = new BehaviorSubject<string>("");



  constructor(public http: Http, private cookieService: CookieService, private tokenEncryptDecrypt: TokenEncryptionService
  ) {
    this.getloginstatus();
  }
  //private headers = { headers: new Headers({ Authorization: this.usertoken }) };    //old used here 
  private headers = { headers: new Headers({ Authorization: this.tokenEncryptDecrypt.decryptToken(this.usertoken) }) };


  //sessoion method
  getloginstatus(): boolean {


    var objtemp2 = this.tokenEncryptDecrypt.decryptToken(localStorage.getItem("currentUser"));//localStorage.getItem('currentUser');

    if (typeof (objtemp2) !== "undefined" && objtemp2 !== null) {

      var objtemp = JSON.parse(this.tokenEncryptDecrypt.decryptToken(localStorage.getItem("currentUser"))) // JSON.parse(localStorage.getItem('currentUser'));   //old

      this.isuserloggedin.next(true);
      this.userFullName = objtemp.userFullName;
      this.city = objtemp.city;
      this.continent = objtemp.continent;
      this.country = objtemp.country;
      this.loginuser = objtemp.loginuser;
      this.region = objtemp.region;
      this.userid = objtemp.userid;
      this.visitorbrowser = objtemp.visitorbrowser;
      this.visitorip = objtemp.visitorip;
      return true;
    }
    else {
      this.isuserloggedin.next(false);
      return false;
      // this.username.next("");
      // this.userid.next("");
      // this.firstname.next("");
      // this.lastname.next("");
      // this.isclientadmin.next(false);
      // this.issuperadmin.next(false);
    }

  }

  setuserparameter(set: boolean) {
    if (set) {
      //var objtemp2 = localStorage.getItem('currentUser');   //old
      var objtemp2 = this.tokenEncryptDecrypt.decryptToken(this.currentUser) //Decryption
      if (typeof (objtemp2) !== "undefined" && objtemp2 !== null) {
        // var objtemp = JSON.parse(localStorage.getItem('currentUser'));
        var objtemp = JSON.parse(this.tokenEncryptDecrypt.decryptToken(this.currentUser))  ////Decryption
        this.isuserloggedin.next(true);
        this.userFullName = objtemp.userFullName;
        this.city = objtemp.city;
        this.continent = objtemp.continent;
        this.country = objtemp.country;
        this.loginuser = objtemp.loginuser;
        this.region = objtemp.region;
        this.userid = objtemp.userid;
        this.visitorbrowser = objtemp.visitorbrowser;
        this.visitorip = objtemp.visitorip;
      }
    }
    else {
      this.isuserloggedin.next(false);
      this.userFullName;
      return false;
      // this.username.next("");
      // this.userid.next("");
      // this.firstname.next("");
      // this.lastname.next("");
      // //this.usertoken.next("");
      // this.issuperadmin.next(false);
      // this.isclientadmin.next(false);
    }
  }

  setLoginStatus(isLoggedIn: boolean) {
    this.setuserparameter(isLoggedIn);
  }

  setusername(usernm: string) {
    this.username.next(usernm);
  }

  getIdleTime(): Observable<any> {
    const headers1 = {
      headers: new Headers({
        Authorization: "Bearer " + this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("usertoken"))  //Decryption  //sessionStorage.getItem("usertoken"),
      }),
    };
    return this.http.get(environment.Auth_url + 'applications/getApplicationSetting/IDLE_SESSION_TIMEOUT', headers1); // python call
  }



  sessiontimeout(userid, idletime): Observable<any> {
    const headers1 = {
      headers: new Headers({
        Authorization: "Bearer " + this.usertoken,
      }),
    };
    return this.http.get(environment.auditUrl + 'audit/auditanalysis/checkSessionTimeout/' + userid + '/' + idletime);
  }

  setusernamenew(): Observable<any> {
    const headers1 = {
      headers: new Headers({
        Authorization: "Bearer " + this.usertoken,
      }),
    };
    return this.http.get(this.tokenEncryptDecrypt.decryptToken(localStorage.getItem("currentUser"))); // python call
  }

  //session End


  getFunctionName(functionname: any) {
    this.functionnm = functionname;
  }

  Sendmailwithfile(filedata: any, options?: RequestOptions) {
    return this.http.post(
      "https://qore-dev.myqone.com/auth/" + this.functionnm,
      filedata
      // "http://localhost:80/" + this.functionnm,
    );
  }
  BindUtilDtl(body: any, options?: RequestOptions) {
    return this.http.post(
      environment.ValueCall_url + this.functionnm,
      // "http://localhost:80/" + this.functionnm,
      body
    ); // python utilization details call
  }
  MQLogin(tkn: any, options?: RequestOptions) {
    this.tknheaders = { headers: new Headers({ Authorization: tkn }) };
    // for all aplication
    return this.http.get(
      //sessionStorage.getItem("UID") -UID taken from cookie service
      environment.AuthorizeUser_url + this.tokenEncryptDecrypt.decryptToken(this.cookieService.get("UID")) + "/5",
      this.tknheaders
    );
  }

  getPDF(body: any, options?: RequestOptions) {
    const headers1 = {
      headers: new Headers({
        Authorization: "Bearer " + this.tokenEncryptDecrypt.decryptToken(this.cookieService.get("AID")),//this.cookieService.get("AID"),
      }),
    };
    const headerpdf = {
      headers: new Headers({ responseType: "blob", location: "report.pdf" }),
    };
    return this.http.get(environment.RestService_url + this.functionnm + body, headers1); // python call
  }


  //get call convert into POST  - this call used for HUB -dahsboards RestService_hub
  getPDF1(body: any) {
    const headers1 = new Headers({ Authorization: "Bearer " + this.tokenEncryptDecrypt.decryptToken(this.cookieService.get("AID")) });  //this.cookieService.get("AID")
    const options1 = new RequestOptions({ headers: headers1, responseType: ResponseContentType.Blob, });
    const url = environment.RestService_hub + this.functionnm;
    return this.http.post(url, body, options1);
  }

  gettestPDF1(body: any) {
    const headers1 = new Headers({ Authorization: "Bearer " + this.tokenEncryptDecrypt.decryptToken(this.cookieService.get("AID")) });  //this.cookieService.get("AID")
    const options1 = new RequestOptions({ headers: headers1, responseType: ResponseContentType.Blob, });
    const url = environment.RestService_url + this.functionnm;
    return this.http.post(url, body, options1);
  }

  BindData(body: any, options?: RequestOptions) {
    const headers1 = {
      headers: new Headers({
        Authorization: "Bearer " + this.tokenEncryptDecrypt.decryptToken(this.cookieService.get("AID")), //this.cookieService.get("AID"),
      }),
    };
    // return this.http.post('http://dev54:5000/' + this.functionnm, body);  // python call
    return this.http.post(environment.RestService_url + this.functionnm, body, headers1); // python call
  }


  //MIU
  BindDataMiu(body: any, options?: RequestOptions) {
    const headers1 = {
      headers: new Headers({
        Authorization: "Bearer " + this.tokenEncryptDecrypt.decryptToken(this.cookieService.get("AID")), //this.cookieService.get("AID"),
      }),
    };
    // return this.http.post('http://dev54:5000/' + this.functionnm, body);  // python call
    return this.http.post(environment.RestService_miu + this.functionnm, body, headers1); // python call
  }

  //MIU
  BindDataHub(body: any, options?: RequestOptions) {
    const headers1 = {
      headers: new Headers({
        Authorization: "Bearer " + this.tokenEncryptDecrypt.decryptToken(this.cookieService.get("AID")), //this.cookieService.get("AID"),
      }),
    };
    // return this.http.post('http://dev54:5000/' + this.functionnm, body);  // python call
    return this.http.post(environment.RestService_hub + this.functionnm, body, headers1); // python call
  }


  //Value
  BindDataValue(body: any, options?: RequestOptions) {
    const headers1 = {
      headers: new Headers({
        Authorization: "Bearer " + this.tokenEncryptDecrypt.decryptToken(this.cookieService.get("AID")), //this.cookieService.get("AID"),
      }),
    };
    return this.http.post(environment.RestService_value + this.functionnm, body, headers1); // python call
  }


  BindDataFMetrics(body: any, options?: RequestOptions) {
    const headers1 = {
      headers: new Headers({
        Authorization: "Bearer " + this.tokenEncryptDecrypt.decryptToken(this.cookieService.get("AID")), //this.cookieService.get("AID"),
      }),
    };
    // return this.http.post('http://dev54:5000/' + this.functionnm, body);  // python call
    return this.http.post(environment.RestService_Financial + this.functionnm, body, headers1); // python call
  }


  BindDataRCM(body: any, options?: RequestOptions) {
    const headers1 = {
      headers: new Headers({
        Authorization: "Bearer " + this.tokenEncryptDecrypt.decryptToken(this.cookieService.get("AID")), //this.cookieService.get("AID"),
      }),
    };
    // return this.http.post('http://dev54:5000/' + this.functionnm, body);  // python call
    return this.http.post(environment.RestService_rcm + this.functionnm, body, headers1); // python call
  }



  //ML url testing
  getMLData(body: any, options?: RequestOptions) {
  
    const headers1 = {
      headers: new Headers({
        Authorization: "Bearer " + this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("usertoken"))  //Decryption  //sessionStorage.getItem("usertoken"),
      }),
    };
    return this.http.post(environment.RestService_ML + this.functionnm, body, headers1); // python call
  }


  private configMapUrl = 'configmap'; // Replace this with your actual API endpoint
  configMapData: any;

  fetchDataFromConfigMap() {
    // debugger
    const namespace = 'default';
    const configMapName = 'angular-config';
    const headers1 = {
      headers: new Headers({
        Authorization: "Bearer " + this.tokenEncryptDecrypt.decryptToken(this.cookieService.get("AID")), //this.cookieService.get("AID"),
      }),

    };
    let url = environment.RestService_url + this.configMapUrl;
    const postData = {
      namespace: namespace,
      configmap_name: configMapName
    };

    this.http.post(url, postData, headers1).subscribe(
      data => {
        this.configMapData = data;
        console.log('configMapData', this.configMapData);
      },
      error => {
        console.error('Error fetching ConfigMap data:', error);
      }
    );
  }


  //--------------------------------------------Excel Calls----------------------------------------//

  getExcel(body: any) {
    const headers1 = new Headers({ Authorization: "Bearer " + this.tokenEncryptDecrypt.decryptToken(this.cookieService.get("AID")), });
    const options = new RequestOptions({ headers: headers1, responseType: ResponseContentType.Blob, });
    const url = environment.RestService_url + this.functionnm;
    return this.http.post(url, body, options);
  }
  //this Excel Call for RCM Dashboards
  getExcelRCM(body: any) {
    const headers1 = new Headers({ Authorization: "Bearer " + this.tokenEncryptDecrypt.decryptToken(this.cookieService.get("AID")), });
    const options = new RequestOptions({ headers: headers1, responseType: ResponseContentType.Blob, });
    const url = environment.RestService_rcm + this.functionnm;
    return this.http.post(url, body, options);
  }
    //this Excel Call for HUB Dashboards
    getExcelHUB(body: any) {
      const headers1 = new Headers({ Authorization: "Bearer " + this.tokenEncryptDecrypt.decryptToken(this.cookieService.get("AID")), });
      const options = new RequestOptions({ headers: headers1, responseType: ResponseContentType.Blob, });
      const url = environment.RestService_hub + this.functionnm;
      return this.http.post(url, body, options);
    }

  //this Excel Call for Financial Dashboards
  getExcelFmetrics(body: any) {
    const headers1 = new Headers({ Authorization: "Bearer " + this.tokenEncryptDecrypt.decryptToken(this.cookieService.get("AID")), });
    const options = new RequestOptions({ headers: headers1, responseType: ResponseContentType.Blob, });
    const url = environment.RestService_Financial + this.functionnm;
    return this.http.post(url, body, options);
  }

  //this Excel Call for MIU Dashboards
  getExcelMIU(body: any) {
    const headers1 = new Headers({ Authorization: "Bearer " + this.tokenEncryptDecrypt.decryptToken(this.cookieService.get("AID")), });
    const options = new RequestOptions({ headers: headers1, responseType: ResponseContentType.Blob, });
    const url = environment.RestService_miu + this.functionnm;
    return this.http.post(url, body, options);
  }

  //this Excel Call for Value Dashboards
  getExcelValue(body: any) {
    const headers1 = new Headers({ Authorization: "Bearer " + this.tokenEncryptDecrypt.decryptToken(this.cookieService.get("AID")), });
    const options = new RequestOptions({ headers: headers1, responseType: ResponseContentType.Blob, });
    const url = environment.RestService_value + this.functionnm;
    return this.http.post(url, body, options);
  }

  //--------------------------------------------*----------------------------------------//

  // MRI details service call
  getMRIData(body: any, options?: RequestOptions) {
    const headers1 = {
      headers: new Headers({
        Authorization: "Bearer " + this.tokenEncryptDecrypt.decryptToken(this.cookieService.get("AID")),
      }),
    };
    // return this.http.post('http://dev54:5000/' + this.functionnm, body);  // python call
    return this.http.post(environment.MRIServiceURL + this.functionnm, body, headers1); // python call
  }

  // honest data service call

  getHonestDataData(body: any, options?: RequestOptions) {
    const headers1 = {
      headers: new Headers({
        Authorization: "Bearer " + this.tokenEncryptDecrypt.decryptToken(this.cookieService.get("AID")),
      }),
    };
    // return this.http.post('http://dev54:5000/' + this.functionnm, body);  // python call
    return this.http.post(environment.HonestServiceURL + this.functionnm, body, headers1); // python call
  }


  SaveData(body: any, options?: RequestOptions) {
    return this.http.post(
      "https://qonductor-dev.myqone.com/QConstrue/ClaimAction/" +
      this.functionnm,
      body
    ); // python call
  }

  logServiceCall(body: any, options?: RequestOptions) {
    const headers1 = {
      headers: new Headers({
        Authorization: "Bearer " + this.tokenEncryptDecrypt.decryptToken(this.cookieService.get("AID")),
      }),
    };
    return this.http.post(environment.AuditLog, body, headers1);
  }


  //user list call
  getUser() {
    //debugger;
    if (this.cookieService.check("AID")) {
      const headersuser = {
        headers: new Headers({
          Authorization: "Bearer " + this.tokenEncryptDecrypt.decryptToken(this.cookieService.get("AID")),
        }),
      };
      return this.http.get(environment.getUserList, headersuser);
    } else {
      return this.http.get(
        environment.getUserList,
        this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("token"))  //Decryption  //sessionStorage.getItem("token")
      );
    }
  }
  // user management service

  javaget(options?: RequestOptions) {
    if (this.cookieService.check("AID")) {
      const headers1 = {
        headers: new Headers({
          Authorization: "Bearer " + this.tokenEncryptDecrypt.decryptToken(this.cookieService.get("AID")), //this.cookieService.get("AID"),
        }),
      };

      return this.http.get(environment.Roleid_url + this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("userid")) + "/5", headers1);
    } else {

      return this.http.get(environment.Roleid_url + this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("userid")) + "/5",
        this.headers
      );
    }
  }
  /* get parent group assinge in MYQone */
  getParentGroup(options?: RequestOptions) {
    const headers1 = {
      headers: new Headers({
        Authorization: "Bearer " + this.tokenEncryptDecrypt.decryptToken(this.cookieService.get("AID")),
      }),
    };
    return this.http.get(
      environment.PGroupURL + "/" + this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("userid")) + "/false",
      headers1
    );
  }

  getGroup(options?: RequestOptions) {
    const headers1 = {
      headers: new Headers({
        Authorization: "Bearer " + this.tokenEncryptDecrypt.decryptToken(this.cookieService.get("AID")),
      }),
    };
    return this.http.get(
      environment.PGroupURL + "/" + this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("userid")) + "/true",
      headers1
    );
  }

  getGroupWithMappedPractice(options?: RequestOptions) {
    const headers1 = {
      headers: new Headers({
        Authorization: "Bearer " + this.tokenEncryptDecrypt.decryptToken(this.cookieService.get("AID")),
      }),
    };
    return this.http.get(
      environment.MyQone_GroupURL +
      "getgroupswithchildbyuserid/" +
      this.tokenEncryptDecrypt.decryptToken(sessionStorage.getItem("userid")),
      headers1
    );
  }
  /* get parent group assinge in MYQone */
  getChieldGroup(body: any, options?: RequestOptions) {
    const headers1 = {
      headers: new Headers({
        Authorization: "Bearer " + this.tokenEncryptDecrypt.decryptToken(this.cookieService.get("AID")),
      }),
    };
    return this.http.get(environment.CGroupURL + body, headers1);
  }

  detailscall(p: any, m: any, options?: RequestOptions) {
    return this.http.post("http://dev54:5000/" + this.functionnm, p, m); // python call
  }

  GetTokenUsingEMRMappingId(mappingid: any) {
    return this.http.get(environment.GetToken_UsingEMRMappingId + mappingid);
  }

  /*  encrypt(msgString) {
    // msgString is expected to be Utf8 encoded
    const iv = CryptoJS.lib.WordArray.random(16);
    const encrypted = CryptoJS.AES.encrypt(msgString, this.key, {
      iv: iv
    });
    return iv.concat(encrypted.ciphertext).toString(CryptoJS.enc.Base64);
  } */
  /*  decrypt(ciphertextStr) {
    const ciphertext = CryptoJS.enc.Base64.parse(ciphertextStr);
    // split IV and ciphertext
    const iv = ciphertext.clone();
    iv.sigBytes = 16;
    iv.clamp();
    ciphertext.words.splice(0, 4); // delete 4 words = 16 bytes
    ciphertext.sigBytes -= 16;
    // decryption
    const decrypted = CryptoJS.AES.decrypt(
      { ciphertext: ciphertext },
      this.key,
      {
        iv: iv
      }
    );
    return decrypted.toString(CryptoJS.enc.Utf8);
  } */

  /*  encrypt(msgString) {
    // msgString is expected to be Utf8 encoded
    const iv = CryptoJS.lib.WordArray.random(16);
    const encrypted = CryptoJS.AES.encrypt(msgString, this.key, {
      iv: iv
    });
    return iv.concat(encrypted.ciphertext).toString(CryptoJS.enc.Base64);
  } */
  /*   decrypt(ciphertextStr) {
    const ciphertext = CryptoJS.enc.Base64.parse(ciphertextStr);
    // split IV and ciphertext
    const iv = ciphertext.clone();
    iv.sigBytes = 16;
    iv.clamp();
    ciphertext.words.splice(0, 4); // delete 4 words = 16 bytes
    ciphertext.sigBytes -= 16;
    // decryption
    const decrypted = CryptoJS.AES.decrypt(
      { ciphertext: ciphertext },
      this.key,
      {
        iv: iv
      }
    );
    return decrypted.toString(CryptoJS.enc.Utf8);
  } */


  getAverage() {
    return this.http.get(`${environment.document_url}restservice/average`);
  }

  getDetails(id) {
    return this.http.get(`${environment.document_url}restservice/dashdetails/` + id);
  }


  retriveTimeOld() {
    return this.http.get(`${environment.document_url}restservice/retriveTimeOld`);
  }

  retriveTimeNew() {
    return this.http.get(`${environment.document_url}restservice/retriveTimeNew`);
  }

  getAllRecords() {
    return this.http.get(`${environment.document_url}restservice/retriveTimeNew`);
  }

  getBotRecords() {
    return this.http.get('https://qbotcontroller-dev.myqone.com/Controller/BotInsightData');
  }

  getBotData() {
    return this.http.get('https://qbotcontroller-dev.myqone.com/Controller/BotSumData');
  }

  getPrediction() {
    const headers1 = {
      headers: new Headers({
        Authorization: "Bearer " + this.tokenEncryptDecrypt.decryptToken(this.cookieService.get("AID")),
      }),
    };
    return this.http.get(environment.RestService_url + this.functionnm, headers1);
  }

  getJsonData() {
    return this.http.get('http://localhost:3000/data');
  }

  /* get users by roleid from MYQone */
  getRCMUserByRoleId() {
    const headers1 = {
      headers: new Headers({
        Authorization: "Bearer " + this.tokenEncryptDecrypt.decryptToken(this.cookieService.get("AID")),
      }),
    };
    return this.http.get(
      environment.UserByroleID,
      headers1
    );
  }

  getsecretkey(aid: any) {
    const headers1 = {
      headers: new Headers({
        Authorization: "Bearer " + aid,
      }),
    };
    return this.http.get(environment.RestService_rcm + 'DynamicCallsGetKey', headers1);
  }

}
