import { ToastrService } from "ngx-toastr";

export enum enumFilterCallingpage {
  KeyMetric = "keymetric",
}

export class Utility {
  public pagesize: number = 10;
  constructor(private toastr?: ToastrService) { }
  ApplicationName: "QInsight";

  public LogError(error: any): any {
    this.showError(error);
  }

  showSuccess(message: string) {
    this.toastr.success(message, this.ApplicationName, {
      positionClass: 'toast-bottom-right',
    });
  }

  showError(message: string) {
    this.toastr.error(message, this.ApplicationName);
  }

  showWarning(message: string) {
    this.toastr.warning(message, this.ApplicationName, {
      positionClass: 'toast-bottom-right',
    });
  }

  showInfo(message: string) {
    this.toastr.info(message, this.ApplicationName, {
      positionClass: 'toast-bottom-right',
    });
  }

  CheckEmptyString(strValue: string): boolean {
    const validateString: string = strValue.trim();
    if (this.isBlankString(validateString)) {
      return true;
    }
    if (validateString.length === 0) {
      return true;
    }
    return false;
  }

  private isBlankString(strValue: string): boolean {
    if (strValue === null || strValue === undefined) {
      return true;
    }
    return false;
  }
}
