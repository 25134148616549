<div class="d-flex card-header p-1 pl-2">
  <h4 class="card-title mb-1">{{cardName}}</h4>
  <div class="ml-auto">
    <kendo-dropdownlist *ngIf="this.InsurancedDropdown =='true'" [data]="selectbucketList" [(ngModel)]="selectedBucket"
      [valuePrimitive]="true" (valueChange)="bucketValueChange()" class="ml-auto mr-1">
    </kendo-dropdownlist>
    <kendo-dropdownlist *ngIf="this.Limit =='true'" [data]="Selectedrecordlimit" [(ngModel)]="SelectedRecord"
      (valueChange)="PayerRecordsby()" [valuePrimitive]="true" class="ml-auto mr-1" style="width: 100px">
    </kendo-dropdownlist>

    <!-- <div class="ml-auto" *ngIf="this.Limit =='false'">  Showing top <kendo-textbox style="width: 55px;"
      (keyup.enter)="onEnterChange($event)" (valueChange)="onRecordChange($event)" [(ngModel)]="this.Limitvalue"
      width="10px;">
     </kendo-textbox> records</div> -->
    <button kendoButton *ngIf="this.exportButton =='API'" class="k-button" look="outline bg-primary"
      (click)="ExportDataClick()" icon="file-excel" [disabled]="exportdisabled"></button>
    <button kendoButton *ngIf="this.exportButton =='Normal'" class="k-button" look="outline bg-primary"
      (click)="excelexport.save()" icon="file-excel"></button>
    <kendo-excelexport [data]="exportData" [collapsible]="true" fileName="{{Exportfilename}}.xlsx" #excelexport>
      <kendo-excelexport-column field="column1" [locked]="false" [title]="title1" [width]="200">
      </kendo-excelexport-column>
      <kendo-excelexport-column field="column2" [locked]="false" [title]="title2" [width]="350">
      </kendo-excelexport-column>
      <kendo-excelexport-column field="column3" [hidden]="column3" [locked]="false" [title]="title3" [width]="350">
      </kendo-excelexport-column>
    </kendo-excelexport>
  </div>
</div>
<kendo-grid [selectable]="isSelectedRow" [height]="Height" 
  [ngClass]="{ 'whole-cell':colorcode==='true','header-hide': Headershide=='true'}" (edit)="explorer('Explore')" [data]="gridData"  [loading]="bIsLoader">
  <kendo-grid-column [ngClass]="{ 'pointer':pointer =='true'}" field="column1" width="150" [title]="title1"
  [headerStyle]="{ 'text-align': 'left' }"  [style]="{ 'text-align': 'left' }">
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="d-flex">
        <div class="flex-fill" [ngClass]="{ 'pointer':pointer =='true'}" (click)="CellClick(dataItem)">
          <span>{{ dataItem.column1}}</span>
        </div>
        <button kendoGridEditCommand class="ml-auto" *ngIf="isExploreHidden=='true' && dataItem?.column1==column1value">
          <span class="fas fa-stream text-dark"></span>
        </button>
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="column2" width="50"  [headerStyle]="{ 'text-align': 'right' }" [style]="{ 'text-align': 'right' }" [title]="title2">
    <!-- [style.backgroundColor]="colorCode(dataItem.column2)" -->
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <span *ngIf="!checkNegative(dataItem.column2)" class="text-danger d-block"
        [style.backgroundColor]="colorCode(rowIndex)" (click)="CellClick(dataItem)">
        {{ dataItem.column2 | currency : "USD":true:"0.0"|minusSignToParens }}
      </span>
      <span *ngIf="checkNegative(dataItem.column2)" class="d-block" [style.backgroundColor]="colorCode(rowIndex)"
        (click)="CellClick(dataItem)">
        {{ dataItem.column2 | currency : "USD":true:"0.0"|minusSignToParens }}
      </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column [hidden]="column3" field="column3" width="50" [headerStyle]="{ 'text-align': 'right' }" [style]="{ 'text-align': 'right' }" [title]="title3">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span title="{{ dataItem.column3 | number:'2.0-5'  }}">
        {{ dataItem.column3 | number:'2.0-5' }}
      </span>
    </ng-template>
  </kendo-grid-column>
  <!-- <kendo-grid-command-column  title="" width="20" [headerStyle]="{'background-color': ' #076bb2','color': '#fff'}">
  <ng-template kendoGridCellTemplate let-dataItem>
    <div class="d-flex"  *ngIf="dataItem?.column1==column1value">
      <button kendoGridEditCommand>
        <span class="fas fa-stream text-dark"></span>
      </button>
    </div>
  </ng-template>
</kendo-grid-command-column> -->
  <kendo-grid-excel fileName="{{Exportfilename}}.xlsx" [fetchData]="ByPayerExportData"></kendo-grid-excel>
</kendo-grid>